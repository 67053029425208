import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Link, NavLink, useHistory } from "react-router-dom";
import { Fragment, useEffect, useRef, useState, useCallback } from "react";
import { NavCar } from "./hover/navCar";
import { NavMaintenance } from "./hover/navMaintenance";
import { NavParts } from "./hover/navParts";
import { NavAbout } from "./hover/navAbout";
import { NavContact } from "./hover/navContact";
import { useDispatch, useSelector } from "react-redux";
import { list, total, onChange } from "cart-localstorage";
import Popup from '../../User/popup';
import axios from "axios";
import { changeCount } from "../../_redux/slices/cartSlice";
import PopupHeader from "../../User/components/popupHeader";
import SignIn from "../../User/signIn";
import SignUp from "../../User/signUp";
import ResetPassword from "../../User/resetPassword";
import LoginValidation from "../../User/loginValidation";
import AdvancedSearch from "../_input/advancedSearch";
import CallCenter from "../__simple/callCenter";
import _, { concat, sum } from "lodash";
import { numberFormat } from "../../utils/currencyFormatter";
import config from "../../configuration/config.json";
import { Price } from "../../components/__simple/_price";
import { Base64 } from 'js-base64';
import Joi from "joi";
import { GetMessages } from '../../utils/validation';
import { setReduxVehicles, setReduxBrands } from '../../_redux/slices/dataSlice';
function Navbar({ popupShow }) {

    const { t: trans, i18n } = useTranslation(["common", "form", "language", "info", "home", "cartFile"]);
    let lang = (i18n.language || 'ar');
    let languageDir = "language-" + lang;

    const dispatch = useDispatch();
    const reduxBrands = useSelector((state) => state.apiData.data.reduxBrands); // Access Redux state
    const navRef = useRef(null);
    const popRef = useRef(null);
    const [navTop, setNavTop] = useState(-16);
    const [navFlex, setNavFlex] = useState(false);
    const [lastScrollY, setLastScrollY] = useState(0);
    const [onhamburger, setOnhamburger] = useState(false);

    const [showNav, setShowNav] = useState(null);
    const history = useHistory();
    const headerFixed = useSelector(state => state.entities.nav.isFixed);
    const headerIcons = useSelector(state => state.entities.nav.iconsBlack);
    const cartCount = useSelector(state => state.entities.cart.count);

    const [brands, setBrands] = useState([]);
    const [searchPopup, setSearchPopup] = useState(false);
    const [showCartNotification, setShowCartNotification] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const timerRef = useRef(null);
    const [activePopUpModel, setActivePopUpModel] = useState({
        tab: 1,
        title: 'sign-in'
    });

    const handleTabChange = (tab) => {

        setActivePopUpModel(tab);
    };

    const handlePopupStatus = (status) => {
        setShowPopup(status);
    };

    // useEffect(() => {
    //     console.log('showNav>>>>>>>>>>>>>>>>>>>>>>>', showNav);

    // }, [showNav])

    useEffect(() => {

        function handleClickOutside(event) {
            if (popRef.current && !popRef.current.contains(event.target) && showCartNotification === true) {

                setShowCartNotification(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showCartNotification]);

    const schemaNavContact = Joi.object({
        fullName: Joi.string().required().messages(GetMessages()),
        phone: Joi.string().min(12).required().messages(GetMessages()),
        email: Joi.string().email({ minDomainSegments: 2, tlds: { allow: false } }).required().messages(GetMessages()),
        subject: Joi.string().required().messages(GetMessages()),
        messageContent: Joi.string().required().messages(GetMessages())
    });

    const countlist = list().length;
    const [addEvent, setaddEvent] = useState(false);
    useEffect(() => {

        window.addEventListener('storage', listenForStorage)

        if (addEvent == true) {
            try {
                window.dispatchEvent(new Event("storage"));
            } catch (error) {

            }
        }

        setaddEvent(true);

        return () => {
            window.removeEventListener('storage', listenForStorage)
        }

    }, [addEvent])


    const listenForStorage = (v) => {



        if (countlist != list().length && showCartNotification != true && list().length > 0) {

            setShowCartNotification(true);

        }

        if (list().length <= 0) {

            setShowCartNotification(false);

        }
        Account();
        dispatch(
            changeCount({ count: list().length })
        )
        return () => {
            dispatch(
                changeCount({ count: list().length })
            )
        };
    };



    useEffect(() => {

        window.addEventListener('storage', listenForStorageToCount)

        try {
            window.dispatchEvent(new Event("storage"));
        } catch (error) {

        }

        return () => {
            window.removeEventListener('storage', listenForStorageToCount)
        }



    }, [])
    const listenForStorageToCount = () => {
        Account();
        dispatch(
            changeCount({ count: list().length })
        )
        return () => {
            dispatch(
                changeCount({ count: list().length })
            )
        };
    };

    useEffect(() => {
        if (window.innerWidth < 577) {
            setNavTop(-1);
        }
    }, [window.innerWidth]);

    const [TotalTax, SetTotalTax] = useState(0)
    const [TotalDiscount, SetTotalDiscount] = useState(0);
    const [SumItemTotal, setSumItemTotal] = useState(0);
    const [SubTotal, setSubTotal] = useState(0);
    const [fullTotal, setfullTotal] = useState(0);
    const [MiniDownPayment, setMiniDownPayment] = useState(0);
    //Accounting for all items 
    function Account() {

        let currentTotalTax = _.sum(list().map((p) => {
            return ((100 / 115) * ((p?.Type?.toLowerCase() == 'car' ? (p.price) : p.price) * p.quantity)) * 0.15;
        }));
        SetTotalTax(currentTotalTax);

        // let currentTotalDiscount = _.sum(list().map((p) => {
        //     return calculateDiscount(p);
        // }));
        // SetTotalDiscount(currentTotalDiscount);

        let currentSumItemTotal = _.sum(list().map((p) => {
            return (p?.Type?.toLowerCase() == 'car' ? (p.MiniDownPayment ? p.MiniDownPayment : p.price) : p.price) * p.quantity;
        }));
        setSumItemTotal(currentSumItemTotal);
        let currentSumfullTotal = _.sum(list().map((p) => {
            return (p.price) * p.quantity;
        }));

        setfullTotal(currentSumfullTotal);
        let currentSubTotal = _.sum(list().map((p) => {
            return (p?.Type?.toLowerCase() == 'car' ? (p.MiniDownPayment ? p.MiniDownPayment : p.price) : p.price) * p.quantity;
        }));
        setSubTotal(currentSubTotal);


        let currentMiniDownPayment = _.sum(list().map((p) => {
            return (p.MiniDownPayment && p.MiniDownPayment != 0) ? p.MiniDownPayment * p.quantity : (p?.Type?.toLowerCase() == 'car' ? (p.MiniDownPayment ? p.MiniDownPayment : p.price) : p.price) * p.quantity;
        }));
        setMiniDownPayment(currentMiniDownPayment);

    };


    // useEffect(() => {


    //     console.log("list().length>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", list().length)
    //     console.log("getlistCart>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", getlistCart)
    //     console.log("showCartNotification>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", showCartNotification)
    //     if (getlistCart == true && showCartNotification != true) {

    //         // setShowCartNotification(true);

    //     }
    //     Account();
    //     setgetlistCart(true);
    //     dispatch(
    //         changeCount({ count: list().length })
    //     )
    //     return () => {
    //         dispatch(
    //             changeCount({ count: list().length })
    //         )
    //     };





    // }, [cartCount]);


    useEffect(async () => {
        if (!(reduxBrands?.length > 0)) {
            try {

                if ("caches" in window) {
                    let url = "brands-" + lang;

                    // Opening that particular cache
                    const cacheStorage = await caches.open("HJWeb");
                    // Fetching that particular cache data
                    const cachedResponse = await cacheStorage.match(
                        url
                    );
                    let data = await cachedResponse?.json();
                    if (data && data?.brands && data?.brands?.length > 0)
                        setBrands(data.brands);

                }

            } catch (error) {
            }

            try {
                const res = await axios.request({
                    baseURL: config.controllURL,
                    url: lang + "/api/brands/brands",
                    method: "get",

                })

                setBrands(res.data.brands);
                const data22 = new Response(JSON.stringify(res.data));

                if ("caches" in window) {
                    // Opening given cache and putting our data into it
                    caches.open("HJWeb").then((cache) => {
                        cache.put("brands-" + lang, data22);

                    });
                }
            } catch (error) {
            }
        }
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);

    }, []);

    const handleScroll = () => {
        if (headerFixed) {
            const currentScrollY = window.scrollY;
            if (currentScrollY - 300 > lastScrollY) {
                setNavFlex(true)

                setNavTop(-1);
            } else {
                if (window.innerWidth < 577) {
                    setNavTop(-1);
                } else {
                    setNavTop(-18);
                }
                if (currentScrollY <= lastScrollY + 200) {
                    setNavFlex(false);
                }
            }
            setLastScrollY(currentScrollY);
        } else {
            setNavFlex(false)
        }
    };
    const [showedListNav, setShowedListNavShowNav] = useState([]);

    function show(section) {

        setShowNav(null);
        if (section && !showedListNav.includes(section)) {
            timerRef.current = setTimeout(() => {
                handleMouseEnter(section);
                setShowNav(section);
            }, 700); // 2000 milliseconds = 2 
        } else {

            setShowNav(section);
        }
    }

    function onMouseOut() {
        setShowNav(null);
        handleMouseLeave();

    }

    const handleMouseEnter = (section) => {

        if (section && !showedListNav.includes(section)) {
            showedListNav.push(section);
        }

    };

    const handleMouseLeave = () => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
    };


    useEffect(async () => {


        if (reduxBrands?.length > 0 && brands?.length <= 0) {

            console.log("reduxBrands>>>>>>>Navbar>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>:");
            console.log("reduxBrands>>>>>>>>>>>Navbar>>>>>>>>>>>>>>>>>>>>>>>>>>:", reduxBrands);
            setBrands(reduxBrands);
        }
        if (brands?.length > 0 && reduxBrands?.length <= 0) {
            console.log("brands>>>>>>>Navbar>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>:");
            console.log("brands>>>>>>>>>>>Navbar>>>>>>>>>>>>>>>>>>>>>>>>>>:", brands);
            dispatch(setReduxBrands(brands));
        }
    }, [reduxBrands, brands]);
    return (
        <Fragment>


            <header className={"z-50 mx-10 mb-8 " + languageDir + ' ' + (headerFixed ? " " : " header-absolute ")}>
                <CallCenter />
                <div className={"hide-xs nav-height " + (navFlex ? navFlex : "hidden")}>
                    <span style={{ opacity: "0" }}>navbar holder</span>
                </div>
                <nav ref={navRef}
                    onMouseLeave={() => onMouseOut()}
                    className={"p-2 border-radius nav nav-height flex justify-between items-center mt-4 nav-default " + (headerFixed ? (navFlex ? " nav-fixed " : " ") : " ") + " " + (onhamburger ? " nav-active" : " ")}
                    style={{ top: navTop + "rem" }}>

                    <div className="nav-first flex justify-center items-center ">
                        <div className="nav-icon ">
                            {
                                lang == "ar" ?

                                    <NavLink
                                        exact={true}
                                        to="/">
                                        <img data-src="/assets/logo/logo_AR.png"
                                            alt="hassan jameel" className="lazyload hidden d-md-inline" />
                                        <img data-src="/assets/logo/logo_AR.png"
                                            alt="hassan jameel" className="lazyload d-inline d-md-hidden" />
                                    </NavLink>


                                    :

                                    <NavLink
                                        exact={true}
                                        to="/">
                                        <img data-src="/assets/logo/logo_EN.png"
                                            alt="hassan jameel" className="lazyload hidden d-md-inline" />
                                        <img data-src="/assets/logo/logo_EN.png"
                                            alt="hassan jameel" className="lazyload d-inline d-md-hidden" />
                                    </NavLink>
                            }

                        </div>
                        <div
                            onClick={() => setOnhamburger(false)}
                            className={"nav-links text-white flex  space-x-4  space-x-reverse mx-20 " + languageDir + (onhamburger ? ' active' : ' ')}>
                            <div className="mx-3">
                                <NavLink onMouseEnter={() => show(null)}
                                    onClick={() => show(null)}
                                    className="text-16 capitalize"
                                    to="/"
                                    exact>
                                    {trans('home')}
                                </NavLink>
                            </div>
                            <div className={"nav-hide-sm "}>
                                <a
                                    onMouseEnter={() => show('vehicle')}
                                    onClick={() => show('vehicle')}

                                    className="text-16 capitalize cursor-pointer ">
                                    {trans('vehicle')}
                                </a>
                            </div>
                            {
                                brands &&
                                brands?.map((brand, index) => {
                                    return (
                                        <div className={" nav-show-sm"} key={index}>
                                            <NavLink to={"/vehicles/" + brand.brand}
                                                className="text-16 capitalize cursor-pointer">
                                                {brand.brand}
                                            </NavLink>
                                        </div>
                                    );
                                })
                            }

                            <div>
                                <div className={"onlinelinke"} >
                                    <div className={"onlinelinke-heading-box " + languageDir} ></div>
                                    <NavLink
                                        onMouseEnter={() => show('Online_Cars_Store')}
                                        onClick={() => show(null)}
                                        className="text-16 capitalize" to="/VehicleOnline">
                                        {trans('info:Online_Cars_Store')}
                                    </NavLink>
                                </div>
                            </div>

                            <div>

                                <NavLink className="text-16 capitalize cursor-pointer"
                                    onMouseEnter={() => show('maintenance')}
                                    onClick={() => show(null)}
                                    to="/maintenance"
                                >
                                    {trans('maintenance-services')}
                                </NavLink>

                            </div>
                            {/* <div>
                                <NavLink
                                    onMouseEnter={() => show('epart')}
                                    onClick={() => show(null)}
                                    className="text-16 capitalize" to="/eparts">{trans("info:eparts")}
                                </NavLink>
                            </div> */}
                            <div>
                                <NavLink
                                    onMouseEnter={() => show('offers')}
                                    onClick={() => show(null)}
                                    className="text-16 capitalize" to="/offers">
                                    {trans('offers')}
                                </NavLink>
                            </div>

                            <div>
                                <NavLink
                                    onMouseEnter={() => show('finance')}
                                    onClick={() => show(null)}
                                    className="text-16 capitalize" to="/finance">
                                    {trans('home:finance')}
                                </NavLink>
                            </div>
                            {/* <div>
                                <NavLink onMouseEnter={() => show(null)}
                                    onClick={() => show(null)}
                                    className="text-16 capitalize"
                                    to="/recruitment"4
                                >{trans('employment')}</NavLink>
                            </div> */}
                            <div>
                                <NavLink
                                    onMouseEnter={() => show('about')}
                                    onClick={() => show(null)}
                                    className="text-16 capitalize" to="/about">
                                    {trans('about-us')}
                                </NavLink>
                            </div>
                            <div>
                                <NavLink
                                    onMouseEnter={() => show('contact')}
                                    onClick={() => show(null)}
                                    className="text-16 capitalize" to="/contact">
                                    {trans('contact-us')}
                                </NavLink>
                            </div>
                            <div className={"nav-show-sm"}>
                                <Link to={""} onClick={(e) => {
                                    e.preventDefault();
                                    if (!localStorage.getItem("isAuthenticated")) {
                                        handlePopupStatus(true);
                                    } else {
                                        history.push(localStorage.getItem("isAuthenticated") ? "/user" : "/");
                                    }
                                }}
                                >{!localStorage.getItem("isAuthenticated") ? trans("info:login") : trans("info:profile")}<img src={localStorage.getItem("isAuthenticated") ? "/assets/icons/user.svg" : "/assets/icons/login.svg"} alt="user profile" /></Link>
                            </div>
                            <div className={"nav-show-sm"}>

                                {/* <a href="..">مواقعنا<img src="/assets/icons/location.svg" alt="location"/></a> */}
                            </div>
                            <div className={"nav-show-sm"}>

                                <Link to={'/search'} onClick={() => {
                                    //  setSearchPopup(true);
                                }}>{trans("info:search")}<img className={"lazyload"} data-src="/assets/icons/search.svg" alt="search" /></Link>
                            </div>
                            <div className={"nav-show-sm"}>
                                <Link to={""} onClick={() => {
                                    // i18next.changeLanguage(trans("changeLangCode")).then((r) => {

                                    //     //     // let html = document.getElementById("html_tag");
                                    //     //     // html.setAttribute("lang", trans("changeLangCode"));
                                    //     //     // let dir = trans("changeLangCode").toLowerCase() === "ar" ? "rtl" : "ltr";
                                    //     //     // console.log(trans("changeLangCode"))
                                    //     //     // console.log(r)
                                    //     //     // html.setAttribute("dir", dir);
                                    // });


                                    if (window.location.href.includes("/ar") || window.location.href.includes("/en")) {

                                        if (window.location.href.includes("/en"))
                                            window.location = window.location.href.replace("/en", "/ar");
                                        if (window.location.href.includes("/ar"))
                                            window.location = window.location.href.replace("/ar", "/en");

                                    } else {

                                        window.location = window.location.origin + "/en" + window.location.pathname + window.location.search;
                                    }


                                    // window.location = window.location.href.replace(/en|ar/, trans("changeLangCode"));
                                }}>{trans("changeLang")}<img className={"lazyload"} data-src="/assets/icons/globle.svg" alt="change language" /></Link>
                            </div>

                        </div>
                    </div>

                    <div className="flex h-full space-x-8  space-x-reverse">
                        <div className="nav-btns flex flex-row justify-center items-center space-x-4  space-x-reverse ">


                            <div className={"relative btn-cart flex flex-col"}
                                onMouseEnter={() => {


                                    if (list().length > 0)
                                        setShowCartNotification(true);
                                }}



                            >
                                <div
                                    className={"bg-danger border-radius-full text-white w-11 h-11 absolute -top-7 -right-3 text-20 flex item-center justify-center"}
                                    style={{ lineHeight: 1.5, zIndex: 1 }}>{cartCount}</div>
                                <Link
                                    className={(!navFlex && onhamburger) ? "open" : ""}
                                    to="/cart">
                                    <img
                                        data-src="/assets/icons/cart.svg"
                                        className="lazyload"
                                        alt="cart" /></Link>


                                <div ref={popRef} className={"cart-notification p-5 nice-shadow-2 border-radius-sm " + (languageDir) + (showCartNotification ? " show " : " hidden ")}

                                    onMouseLeave={() => {

                                        setShowCartNotification(false);
                                    }}
                                >

                                    <div className="flex flex-row py-5">
                                        <div className={"text-16 font-bold text-black"}>{trans("cartFile:Cart_content")}:</div>
                                        <div className={"text-16 text-black font-bold"}>{cartCount}<span className={"text-16 text-black font-bold mx-2"}>{trans("new:pieces")}</span></div>
                                    </div>

                                    <div className="cart-notification-products">
                                        {
                                            list()?.map((data, index) => {
                                                return (
                                                    <div key={index} className={"flex flex-row border-b mb-2 "}>

                                                        <div className={" mb-8 flex flex-row gap-2 "} >

                                                            <div className={"bg-secondary border-radius mx-2 w-20  h-30"}>

                                                                {
                                                                    (Base64.isValid(data?.image) == 1 ?

                                                                        <img className={"lazyload object-full  object-contain"} data-src={data?.image ? "data:image/png;base64," + data?.image : (data.Type == "service" ? "/assets/images/toolMaintenance.gif" : "/assets/images/default.png")}
                                                                            alt={""} /> :
                                                                        <img className={"lazyload object-full  object-contain"} data-src={data?.image ? config.mainURL + data?.image : (data.Type == "service" ? "/assets/images/toolMaintenance.gif" : "/assets/images/default.png")}
                                                                            alt={""} />)

                                                                }

                                                            </div>



                                                            <div className=" w-80">
                                                                <div className=" nice-flex nice-flex-column">
                                                                    <div className="nice-flex-1 nice-gap-1rem nice-flex nice-flex-column">

                                                                        {
                                                                            data?.brand &&
                                                                            <div className="cart-detail-container">
                                                                                <h3 className={"text-10 text-gray"}>{trans("cartFile:trade")}:</h3>
                                                                                <p className={"text-12 "}>{lang == "ar" ? data?.brand : data?.brandEn}</p>
                                                                            </div>

                                                                        }

                                                                        <div className="cart-detail-container">
                                                                            {
                                                                                data.Type == "car" ?
                                                                                    <h3 className={"text-10 text-gray"}>{trans("cartFile:vehicle_type")}:</h3>
                                                                                    :
                                                                                    (data.Type == "service" ?
                                                                                        <div className={"text-10 text-gray"}>{trans("new:service")}:</div>
                                                                                        :
                                                                                        <h3 className={"text-10 text-gray"}>{trans("new:package")}:</h3>
                                                                                    )



                                                                            }

                                                                            <p className={"text-12 "}>{data.Type == "car" ? (lang == "ar" ? data?.modelTypes : data?.modelTypesEn) : (lang == "ar" ? data?.name : data?.nameEn)}</p>
                                                                        </div>

                                                                        {
                                                                            data?.Type != "car" &&

                                                                            <div className="cart-detail-container">
                                                                                <h3 className={"text-10 text-gray"}>{trans("cartFile:vehicle_type")}:</h3>
                                                                                <p className={"text-12 "}>{lang == "ar" ? data?.modelTypes : data?.modelTypesEn}</p>
                                                                            </div>
                                                                        }

                                                                        {
                                                                            data?.year &&
                                                                            <div className="cart-detail-container">
                                                                                <h3 className={"text-10 text-gray"}>{trans("info:vehicle_year")}:</h3>
                                                                                <p className={"text-12 "}>{data?.year}</p>
                                                                            </div>

                                                                        }


                                                                        {
                                                                            data?.colorName &&
                                                                            <div className="cart-detail-container ">
                                                                                <h3 className={"text-10 text-gray"}>{trans("cartFile:color")}</h3>
                                                                                <p className={"text-12  flex items-center "}>
                                                                                    <span className={"p-8 bg-toyota box-shadow-sm border-radius ml-4 hidden"}></span>{lang == "ar" ? data?.colorName : data?.colorNameEn}
                                                                                </p>
                                                                            </div>
                                                                        }

                                                                    </div>
                                                                    <div className="nice-flex nice-flex-column nice-gap-2rem my-5">

                                                                        <div style={{ display: "flex", flexFlow: "column nowrap" }}>

                                                                            <div className={"flex items-center"}>
                                                                                <div className={"text-10 text-gray"}>{trans("cartFile:product_price")}</div>
                                                                                <div className={"text-12 mx-3 font-bold text-primary"}><Price price={data?.price} /></div>
                                                                            </div>

                                                                        </div>

                                                                    </div>
                                                                </div>



                                                            </div>


                                                        </div>


                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                    {

                                        fullTotal &&

                                        <div className="cart-grid-info-div border-b">
                                            <div className={"text-12 font-bold text-black"}>{trans("new:total")}&nbsp;<span className="text-14 text-gray mx-1">{trans("new:total_include_tax")}</span></div>
                                            <div className={"text-12 text-black font-bold"}>{numberFormat(fullTotal)}</div>
                                        </div>
                                    }

                                    <div className="flex flex-center flex-col  mt-5 pt-5 ">
                                        <div className=" mb-5 w-100">
                                            <div className="nice-sm-btn cart-notification-go  bg-primary text-white text-16" onClick={() => {

                                                setShowCartNotification(false);

                                            }}>
                                                {trans("cartFile:Continue_browsing")}
                                            </div>
                                        </div>

                                        <div className="mb-5 w-100">

                                            <Link
                                                className={(!navFlex && onhamburger) ? "open" : ""}
                                                to="/cart">
                                                <div className="nice-sm-btn bg-black text-white text-16" onClick={() => {
                                                    setShowCartNotification(false);

                                                }}>
                                                    {trans("cartFile:View_cart")} <img data-src="/assets/icons/cart.svg" className=" lazyload filtter-primary" alt="hassan jameel cart" />
                                                </div>
                                            </Link>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <Link to={""} onClick={(e) => {
                                e.preventDefault();
                                if (!localStorage.getItem("isAuthenticated")) {
                                    handlePopupStatus(true);
                                } else {
                                    history.push(localStorage.getItem("isAuthenticated") ? "/user" : "/");
                                }
                            }}
                            ><img src={localStorage.getItem("isAuthenticated") ? "/assets/icons/user.svg" : "/assets/icons/login.svg"} alt="user profile" /></Link>

                            {/* <a href=".."><img src="/assets/icons/location.svg" alt="location"/></a> */}

                            <Link to='/search' >
                                <img className={"lazyload"} data-src="/assets/icons/search.svg" alt="search" /></Link>
                            <div
                                className={"hamburger " + (onhamburger ? "open" : "")}
                                onClick={() => setOnhamburger(!onhamburger)}>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>

                        <button
                            onClick={() => {
                                // i18next.changeLanguage(trans("changeLangCode")).then((r) => {
                                //     //     // let html = document.getElementById("html_tag");
                                //     //     // html.setAttribute("lang", trans("changeLangCode"));
                                //     //     // let dir = trans("changeLangCode").toLowerCase() === "ar" ? "rtl" : "ltr";
                                //     //     // console.log(trans("changeLangCode"))
                                //     //     // console.log(r)
                                //     //     // html.setAttribute("dir", dir);
                                // });


                                if (window.location.href.includes("/ar") || window.location.href.includes("/en")) {

                                    if (window.location.href.includes("/en"))
                                        window.location = window.location.href.replace("/en", "/ar");
                                    if (window.location.href.includes("/ar"))
                                        window.location = window.location.href.replace("/ar", "/en");

                                } else {

                                    window.location = window.location.origin + "/en" + window.location.pathname + window.location.search;
                                }

                                // window.location = window.location.href.replace(/en|ar/,  trans("changeLangCode"));
                            }}
                            className="nav-lang h-full w-48 border-radius bg-primary flex justify-center items-center">

                            <span className='text-white text-16 font-bold pl-2 pr-2 capitalize'>{trans('changeLang')}</span>
                            <img className={"lazyload"} data-src="/assets/icons/globle.svg" alt="change language" />
                        </button>
                    </div>
                    <div className={"nav-mega " + languageDir}
                    // onMouseLeave={() => {
                    //     onMouseOut();
                    // }}
                    >
                        {showedListNav.includes("vehicle") &&
                            <NavCar className={(showNav === "vehicle" || "hidden")} onClick={() => show(null)} />
                        }
                        {showedListNav.includes("maintenance") &&
                            <NavMaintenance className={(showNav === "maintenance" || "hidden")} />
                        }

                        {/* <NavParts className={(showNav === "epart" || "hidden")} /> */}

                        {showedListNav.includes("about") &&
                            <NavAbout className={(showNav === "about" || "hidden")} />
                        }
                        {showedListNav.includes("contact") &&

                            <NavContact className={(showNav === "contact" || "hidden")} schema={schemaNavContact} />
                        }
                    </div>

                </nav>
            </header>
            <Popup show={showPopup}>
                <div className='popup-model flex flex-row w-70 bg-primary'>
                    {
                        activePopUpModel.tab == 1 ?
                            <SignIn onTabChange={(tab) => { handleTabChange(tab) }} onLogin={() => handlePopupStatus(false)} />
                            : activePopUpModel.tab == 2 ?
                                <SignUp onTabChange={(tab) => { handleTabChange(tab) }} onLogin={() => handlePopupStatus(false)} />
                                : activePopUpModel.tab == 3 ?
                                    <ResetPassword onTabChange={(tab) => { handleTabChange(tab) }} />
                                    : <LoginValidation onTabChange={(tab) => { handleTabChange(tab) }} onLogin={() => handlePopupStatus(false)} />

                    }
                    <div className='popup-close'>

                        <span className='popup-close-icon' onClick={() => handlePopupStatus(false)}>
                            {/* <span className='text-30 '>&times;</span> */}
                            <svg
                                viewBox="0 0 512 512"
                                fill="currentColor"
                                height="4rem"
                                width="4rem"
                            >
                                <path
                                    fill="none"
                                    stroke="currentColor"
                                    strokeMiterlimit={10}
                                    strokeWidth={32}
                                    d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"
                                />
                                <path
                                    fill="none"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={32}
                                    d="M320 320L192 192M192 320l128-128"
                                />
                            </svg>
                        </span>
                        <span className='text-20 mx-2'>{lang == "ar" ? "اغلاق" : "close"}</span>
                    </div>
                    <PopupHeader welcomeTitle={trans("common:say-hello")} subTitle={trans("common:" + activePopUpModel.title)} closePopUp={() => handlePopupStatus(false)} />

                </div>
            </Popup>
            <AdvancedSearch show={searchPopup} onSearchClick={(value) => { setSearchPopup(value) }} />
        </Fragment >
    );
}


export default Navbar;






