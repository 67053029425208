import { DropDownInput } from "../../components/_input/_dropdown";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import config from '../../configuration/config.json';
import Popup from "../../User/popup";
import ConfirmContext from "../../utils/confirmContext";
import { useHistory } from "react-router";
import BranchesInfo from "../cart/component/BranchInfo";
import CouponControl from "../cart/component/CouponControl";
import { getData, User_Data_Key, saveData } from "../../utils/localStorageManager";
import _, { concat, sum, includes } from "lodash";
import { GetMessages, formValidate, } from '../../utils/validation';
import { RadioInputDynamic } from "../../components/_input/_radioInputDynamic";
import Joi from "joi";
import { toast } from 'react-toastify';
import SectionHeading from "../../components/sectionHeading";
import SMSCheck from "./components/smsCheck";
import PhoneDropDown from "../../components/_input/_phoneDropDown";
import { InputField } from "../../components/_input/_input";
import { CheckInput } from "../../components/_input/_checkInput";
import { Price } from "../../components/__simple/_price";
import { numberFormat } from "../../utils/currencyFormatter";

export function CarCheckoutCashPage({ vehicle, isToyota, isLexus, selectedColor, finance, financeSettings, filterCarImages }) {

    const thisConfirmContext = useContext(ConfirmContext);
    const location = useLocation();
    const history = useHistory();
    const [showFinalPopupMessage, setShowFinalPopupMessage] = useState();
    const { slug, sn } = useParams();
    const [isLoadingSendOrder, setLoadingSendOrder] = useState(false);
    const [carInfo, setCarInfo] = useState({});
    const { t: trans, i18n } = useTranslation(["new", "finance", "inputs", "info", "new", "extra"]);
    const [dataFullNum, setDataFullNum] = useState(0);
    const [stepNum, setStepNum] = useState(1);
    const [dataFinalPopup, setDataFinalPopup] = useState({});
    const [showFinalPopup, setShowFinalPopup] = useState(false);
    const [requestForm, setRequestForm] = useState({ selectedNationality: "SAU" });
    const [scrollSnackUp, setScrollSnackUp] = useState({
        show: false,
        height: '16rem'
    });

    const query = new URLSearchParams(location.search);
    const [paymentType, setPaymentType] = useState(null);
    const [OTP, setOTP] = useState("");
    const [error, setError] = useState({});
    const schema_1 = Joi.object({
        name: Joi.string().required().messages(GetMessages()),
        phoneNumber: Joi.string().min(12).required().messages(GetMessages()),
        City: Joi.string().required().messages(GetMessages()),
        accountType: Joi.string().required().messages(GetMessages()),
        identity: Joi.string().min(10).required().messages(GetMessages()),
        commercialIdentity: Joi.string().min(10).required().messages(GetMessages()),
    });
    const schema_2 = Joi.object({
        OTP: Joi.string().min(4).required().messages(GetMessages()),
    });
    const schema_3 = Joi.object({
        paymentType: Joi.string().min(1).required().messages(GetMessages()),

    });
    const [propsData, setPropsData] = useState({});
    const [checkoutType, setCheckoutType] = useState(null);

    const [TotalTax, SetTotalTax] = useState(0)
    const [TotalDiscount, SetTotalDiscount] = useState(0);
    const [SumItemTotal, setSumItemTotal] = useState(0);
    const [SubTotal, setSubTotal] = useState(0);
    const [fullTotal, setfullTotal] = useState(0);
    const [MiniDownPayment, setMiniDownPayment] = useState(0);
    const [accountTypes, setAccountTypes] = useState([]);

    const [isClientAccount, setIsClientAccount] = useState(null);
    useEffect(() => {
        axios.post(
            config.baseURL + 'func',
            {
                "name": "SiteGetUserAccountTypes"
            }
        ).then(response => {
            // console.log("accountType", response.data);
            setAccountTypes(response.data);

        }).catch(err => {
            console.log(err);
        })
    }, []);
    const [platformTypes, setPlatformTypes] = useState([]);

    useEffect(() => {
        // تحميل الملف من مجلد public
        const loadManifest = async () => {
            try {
                const response = await fetch(`${process.env.PUBLIC_URL}/config.json`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                // Filter and map to extract keys with isActive = true
                var platformsTypes = data.platformsTypes || [];
                const activeKeys = platformsTypes
                    .filter((platform) => platform.isActive)
                    .map((platform) => platform.key);

                console.log("Active keys:", activeKeys);

                setPlatformTypes(activeKeys);

            } catch (error) {
                console.error("Error loading manifest.json:", error);
            }
        };

        loadManifest();
    }, []);

    const [subFunctionRef, setSubFunctionRef] = useState(null);
    // Function to call the CheckSMS-component's function
    const handleRunSubFunction = () => {

        setOTP("");
        if (subFunctionRef) {
            subFunctionRef();
        }
    };

    useEffect(() => {
        //اجراء التاكد من حالة الدفع وتحديث حقل isPayment
        //Site_Carts_PaymentStatus
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var id = query.get("orderId")?.trim();

        if (id) {
            axios.request({
                url: config.paymentURL + "/api/Payment/CarPaymentStatus", headers: myHeaders,
                params: {
                    ID: id
                },
                method: "POST", responseType: "json"
            }).then(function (response) {

                console.log('PaymentStatus response>>>>>>>>>:', response.data);
                if (response.data && response.data.length > 0) {

                    var data = response.data.filter(p => p.Status == "success")[0];
                    console.log('PaymentPaytabsStatusFully data:', data);
                    if (data.Status == "success") {
                        setShowFinalPopup(true);
                        setDataFinalPopup(response?.data);
                    }
                } else if (response.data && response.data.OrderId) {

                    var data = response.data;
                    console.log('PaymentPaytabsStatusFully data:', data);
                    if (data.Status == "success") {
                        setShowFinalPopup(true);
                        setDataFinalPopup(response?.data);
                    }

                } else if (response.data && response.data.ErrorMessage) {

                    alert(response.data.ErrorMessage + " / " + response.data?.ErrorProcedure);
                }



            }
            ).then((response2) => {
                console.log('response2', response2);
            })
                .catch(error => console.log('error', error));

        }

    }, []);
    var currentSize = 0;
    const CategoryCar = (Category) => {

        if (Size != currentSize) {
            currentSize = Category;
            SetSize(Category);
        }
    }
    const refrechDateCartItems = async (carInfo) => {

        Account(carInfo);


    }
    const [personalDataRequest, setPersonalDataRequest] = useState({
        arFirstName: '',
        arMiddleName: '',
        arLastName: '',
        enFirstName: '',
        enMiddleName: '',
        enLastName: '',
        genderID: '',
        address: '',
        cityID: '',
        countryID: '',
        logo: ''
    });
    useEffect(async () => {
        const isLogin = getData('isAuthenticated');
        if (isLogin) {
            const userData = JSON.parse(getData(User_Data_Key, true));

            if (userData) {
                setPersonalDataRequest(userData);
                var name = lang == "ar" ? userData?.arFirstName + " " + userData?.arMiddleName + " " + userData?.arLastName
                    : userData?.enFirstName + " " + userData?.enMiddleName + " " + userData?.enLastName;
                userData["name"] = name;

                const val = { ...requestForm };
                val.name = name;

                const match = userData?.phone.match(/^(\+\d{1,4})(\d+)$/);

                const mainNumber = userData?.phone?.slice(-9);     // Last 9 digits, e.g., 530005261
                // Get everything before the last 9 digits as the country code
                const countryCode = userData?.phone?.slice(0, -9);
                val.phoneNumberCode = countryCode.replace("+", "");
                val.phoneNumberOnly = mainNumber;
                val.phoneNumber = userData?.phone ? userData?.phone : "";
                // val.City = userData?.cityID ? userData?.cityID :"";
                setRequestForm(val);
            }
        }
    }, []);
    useEffect(async () => {

        if (carInfo) {
            CategoryCar(carInfo?.groupID);
            await refrechDateCartItems(carInfo);

        }


    }, [carInfo]);
    //Accounting for all items 
    function Account(p) {
        p.quantity = 1;
        let currentTotalTax = ((100 / 115) * ((p.MiniDownPayment ? p.MiniDownPayment : p.NetSalePrice) * p.quantity)) * 0.15;

        SetTotalTax(currentTotalTax);

        let currentTotalDiscount = calculateDiscount(p)
        SetTotalDiscount(currentTotalDiscount);

        let currentSumItemTotal = ((p.MiniDownPayment ? p.MiniDownPayment : p.NetSalePrice)) * p.quantity;

        setSumItemTotal(currentSumItemTotal);
        let currentSumfullTotal = (p.NetSalePrice) * p.quantity;

        setfullTotal(currentSumfullTotal);
        let currentSubTotal = ((p.MiniDownPayment ? p.MiniDownPayment : p.NetSalePrice)) * p.quantity;
        setSubTotal(currentSubTotal);


        let currentMiniDownPayment = (p.MiniDownPayment && p.MiniDownPayment != 0) ? p.MiniDownPayment * p.quantity : ((p.MiniDownPayment ? p.MiniDownPayment : p.NetSalePrice)) * p.quantity;
        setMiniDownPayment(currentMiniDownPayment);

    };
    // calculate discount
    const calculateDiscount = (itemInfo) => {
        let discountResult = [];
        try {
            if (couponDetail?.items) {
                //console.log(itemInfo);
                discountResult = couponDetail?.items.map((item) => {
                    let result = 0;
                    const { BrandID: brand, groupID, modelTypes, miniDownPayment, minPrice, quantity } = itemInfo;
                    if (brand == item?.brandID && groupID === item?.productGroupID && modelTypes === item?.productModelTypeID) {
                        result = getDiscountValueByType(item, minPrice, quantity);
                    }


                    return result;
                });
            }
        } catch (error) {
            //console.log(error);
        }
        return sum(discountResult) ?? 0;
    };

    //-----------------------------------------------
    const [couponDetail, setCouponDetail] = useState(null);
    const [clearCoupon, setClearCoupont] = useState(false);
    //to apply the coupon discount on the cart
    const applyCoupon = (code) => {
        const _id = getData("secretKey");
        if (code) {
            if (_id) {
                axios.request({
                    baseURL: config.controllURL,
                    url: lang + "/api/cart/CouponDetails",
                    method: "post",
                    data: {
                        "couponCode": code,
                        "id": _id
                    }
                }).then(response => {
                    const responseData = response.data;
                    if (responseData?.items?.length > 0) {
                        setCouponDetail(responseData);
                        setClearCoupont(true);
                    } else {
                        setCouponDetail(null);
                        setClearCoupont(false);
                    }
                }).catch(error => {
                    //console.log(error);
                })
            } else {
                // toast.error(trans("new:login_first"));
                thisConfirmContext.onShowRegistrationPup(true);
            }
        } else {
            SetTotalDiscount(0);
        }
    };

    // apply discount by type
    const getDiscountValueByType = (item, price, quantity) => {
        let result = 0;
        const { discountType, discountAmount, discountRate } = item;
        if (discountType?.toLowerCase() === 'amount') {
            const discountValue = discountAmount * quantity;
            result = discountValue;
        } else if (discountType?.toLowerCase() === 'rate') {
            const priceRateValue = ((price * quantity) * (discountRate / 100));
            result = priceRateValue;
        }
        return result;
    };
    useEffect(() => {
        handleFetchCountries();
        GetBranch();
    }, []);
    useEffect(() => {
        //console.log("props:", propsData);
        var mm = {
            ...propsData,
            vehicle, isToyota, isLexus, selectedColor, finance, financeSettings, filterCarImages
        }
        setPropsData(mm);
        setCarInfo({ ...selectedColor });
    }, [vehicle, isToyota, isLexus, selectedColor, finance, financeSettings, filterCarImages]);
    let lang = (i18n.language || 'ar');
    const [Shipping, setShipping] = useState({ UnitTime: "", CountTime: 0, Cost: 0, DateTimeDelevry: "" });
    const [dataShippingInfo, setDataShippingInfo] = useState({ "": [] });
    const [selectcity, setSelectcity] = useState(null);
    const [develviryType, setDeleviryType] = useState(2);
    const [ValueCitie, setValueCitie] = useState("");
    const [Size, SetSize] = useState(0);
    const [currentDeliveryMethod, setcurrentDeliveryMethod] = useState({
        branchMethod: true,
        locationMethod: false
    });
    const [ValueBranch, setValueBranch] = useState("");
    function getValueBranch(option) {
        setValueBranch(option.value);
    }
    const [Branches, setBranches] = useState([]);
    const [branchesDetails, setBranchesDetails] = useState([]);
    const GetUrlfunc = config.baseURL + 'func';
    async function GetBranch() {
        const Body = {
            "name": "site_FetchBranches"
        };
        axios.request({
            url: GetUrlfunc,
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                const result = response.data;
                setBranches(result);
                const formatResult = result.map(item => {
                    return {
                        title: concat(item[`${trans("model:description")}`], item.Address ? concat('-', item.Address) : ''),
                        details: item.WorkingHoursFormat,
                        lat: item.Latitude,
                        long: item.Longitude
                    }
                });
                setBranchesDetails(formatResult);
            })
            .catch(function (error) {
                //console.log(error);
            });
    };

    async function CarShippingInfo(Citey, mSize) {
        setValueCitie(Citey.value)
        const Body = {
            "name": "Site_CarShippingInfo",
            "values": {
                "City": `${Citey.value}`,
                "categore": `${mSize}`
            }
        }
        await axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                // //console.log(response.data.ApiObjectData);
                var currentvalue = { ...Shipping };
                currentvalue.Cost = response.data[0].CostShipping
                currentvalue.UnitTime = response.data[0].Type
                currentvalue.CountTime = response.data[0].TimeDelivary
                setShipping(currentvalue);
                //console.log(currentvalue);
            })
            .catch(function (error) {
                //console.log(error);
                var currentvalue = { ...Shipping };
                currentvalue.Cost = 0
                currentvalue.UnitTime = trans("new:not_avilable");
                currentvalue.CountTime = null
                setShipping(currentvalue);
                //console.log(currentvalue);
            });

    }

    useEffect(async () => {

        await checkStepsErrorsDataFullNum();
    }, [requestForm]);
    const cheangeSteps = async (step) => {

        if (step > 0 && step <= 3 && step != stepNum) {

            const statusStep = await checkStepsErrors();
            if (statusStep >= step) {
                setStepNum(step);

            }

        }

    }

    //----------------------
    const [filteredCities, setFilteredCities] = useState([]);
    useEffect(async () => {
        try {
            const settingsResponse = await axios.request({
                baseURL: config.controllURL,
                url: lang + "/api/user/getSettings",
                method: "get",
            });

            if (settingsResponse.status === 200) {
                var mm = settingsResponse?.data?.genders.map(e => {
                    return { value: String(e.id), text: e.description }
                });


                setFilteredCities(settingsResponse?.data?.cities?.filter(item => {
                    return item.code == "SAU"
                }));

            }


        } catch (err) {
            console.log(err);
        }
    }, []);
    //-------------------------------------------
    const [cities, setCities] = useState([]);
    // to fetch all SAU countries
    const handleFetchCountries = async () => {
        let countries = [];
        try {
            const response = await axios.post(
                config.baseURL + 'func',
                {
                    "Name": "site_ShippingCities"
                }
            );
            if (response.status == 200) {
                countries = response.data.map(item => {
                    return { value: item.ID, text: item[`${trans("model:description")}`] }
                });
            }
        } catch (error) {
            //console.log('error in fetching the countries of SAU', error);
        }
        setCities(countries);
    };

    const checkStepsErrorsDataFullNum = async () => {
        var result = formValidate(schema_1, requestForm) ?? {};
        let otherProperities = [];
        let newErrors = {};
        const val = { ...requestForm };
        if (isClientAccount) {

            otherProperities = ['commercialIdentity'];
        } else {
            otherProperities = ['identity'];
        }
        newErrors = otherProperities ? removeUnspecificError(result, otherProperities) : result;
        var srempNumber = 0
        if (Object.keys(newErrors).length === 0) {
            srempNumber = 1;
        } else {
            setDataFullNum(srempNumber);
            return srempNumber;
        }
        result = formValidate(schema_2, requestForm) ?? {};


        if (Object.keys(result).length === 0) {
            srempNumber = 2;
        } else {
            setDataFullNum(srempNumber);
            return srempNumber;
        }
        result = formValidate(schema_3, requestForm) ?? {};

        if (Object.keys(result).length === 0) {
            srempNumber = 3;
        } else {
            setDataFullNum(srempNumber);
            return srempNumber;
        }
        if (Object.keys(result).length === 0) {
            srempNumber = 3;
        } else {
            setDataFullNum(srempNumber);
            return srempNumber;
        }

        setDataFullNum(srempNumber);
        return srempNumber;
    }
    // to remove the unneeded props
    const removeUnspecificError = (errorList, values = []) => {
        let errorResult = {};
        if (values?.length > 0) {
            const lowerValues = values.map(item => {
                return item.toLowerCase()
            });
            Object.assign(errorResult, errorList);
            if (errorList) {
                for (let index = 0; index < Object.keys(errorList).length; index++) {
                    const key = Object.keys(errorList)[index];
                    if (includes(lowerValues, key.toLowerCase())) {
                        delete errorResult[key];
                    } else {
                        continue;
                    }
                }
            }
        }
        return errorResult;
    };
    const checkStepsErrors = async () => {
        var result = formValidate(schema_1, requestForm) ?? {};


        let otherProperities = [];
        let newErrors = {};
        const val = { ...requestForm };
        if (isClientAccount) {

            otherProperities = ['commercialIdentity'];
        } else {
            otherProperities = ['identity'];
        }
        newErrors = otherProperities ? removeUnspecificError(result, otherProperities) : result;
        if (stepNum == 1)
            setError(newErrors);


        // console.log("requestForm>>>>>>>>>>>>>>:", requestForm);

        var srempNumber = 0
        if (Object.keys(newErrors).length === 0) {
            srempNumber = 1;
        } else {
            setDataFullNum(srempNumber);
            setStepNum(srempNumber + 1);
            return srempNumber;
        }
        result = formValidate(schema_2, requestForm) ?? {};
        if (stepNum == 2)
            setError(result);

        if (Object.keys(result).length === 0) {
            srempNumber = 2;
        } else {
            setDataFullNum(srempNumber);
            setStepNum(srempNumber + 1);
            return srempNumber;
        }
        result = formValidate(schema_3, requestForm) ?? {};
        if (stepNum == 3)
            setError(result);

        if (Object.keys(result).length === 0) {
            srempNumber = 3;
        } else {
            setDataFullNum(srempNumber);
            setStepNum(srempNumber + 1);
            return srempNumber;
        }

        if (stepNum == 3)
            setError(result);
        //console.log("result", result);
        if (Object.keys(result).length === 0) {
            srempNumber = 3;
        } else {
            setDataFullNum(srempNumber);
            setStepNum(srempNumber + 1);
            return srempNumber;
        }

        setDataFullNum(srempNumber);
        return srempNumber;
    }

    const handlePayment = async () => {

        const isLogin = getData('isAuthenticated');
        if (isLogin) {

            console.log("handlePayment:");
            const cleanData = JSON.parse(getData(User_Data_Key, true));
            console.log("handlePayment:", cleanData);
            const user_id = cleanData.id.toString().split('_')[1];
            if (user_id) {
                if ((!currentDeliveryMethod?.locationMethod) && (!currentDeliveryMethod?.branchMethod)) {
                    toast.warn(trans("new:not_delivery_selected"));
                } else if (currentDeliveryMethod?.locationMethod && (ValueCitie == '' || ValueCitie == null)) {
                    toast.warn(trans("new:not_delivery_selected_loc"));
                } else if (currentDeliveryMethod?.branchMethod && (ValueBranch == '' || ValueBranch == null)) {
                    toast.warn(trans("new:not_delivery_selected_branch"));
                } else {
                    await PaymentMAin(
                        1,

                        (currentDeliveryMethod?.locationMethod ?? false) ? ValueCitie : null,
                        (currentDeliveryMethod?.branchMethod) ?? false ? ValueBranch : null,
                        currentDeliveryMethod?.locationMethod ?? false,
                        currentDeliveryMethod?.branchMethod ?? false,
                        true,
                        false,
                        MiniDownPayment, SumItemTotal, Shipping.Cost, SubTotal, TotalTax, TotalDiscount,
                        //--------------
                        [{
                            id: carInfo.ProductID,
                            quantity: 1,
                            colorID: carInfo.ColorID,
                            Type: 1,
                            price: carInfo.MiniDownPayment,
                            CartID: null,
                            VIN: carInfo.SN,
                            MiniDownPayment: carInfo.MiniDownPayment ? carInfo.MiniDownPayment : 5000
                        }]
                        //--------------
                        , parseInt(user_id), MiniDownPayment, paymentType);
                }
            } else {
                toast.error(trans("new:login_first"), {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

        } else {
            thisConfirmContext.onShowRegistrationPup(true);

        }
    };

    //PaymentMAin
    async function PaymentMAin(status, InfoLocation, InfoBranch, ByLocation, ByBranch,
        IsReservation, IsPayment, PaymentAmount, FinalTotal, AmountShipping,
        SubAmountTotal, TotalTaxAmount, TotalDiscount, Product, UserID, MiniDownPayment, MethodPayment) {



        if (!isLoadingSendOrder) {
            //////console.log("handleSubmit:", requestForm);
            setLoadingSendOrder(true);

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            const { ipv4 } = await getUserIpAddress();


            var raw = {
                "URL": `${window.location.origin}/${lang}/car/checkoutCash/${sn}/${slug}`,
                "Lang": lang,
                "Product": Product,
                "UserID": UserID,
                "City": requestForm.City,
                "NotificationWhatsApp": requestForm.NotificationWhatsApp == true ? 1 : 0,
                "customerDetails": {
                    "IP": ipv4
                },
                "DataAmount": {
                    "FinalTotal": FinalTotal,
                    "AmountShipping": AmountShipping,
                    "SubAmountTotal": SubAmountTotal,
                    "TotalTaxAmount": TotalTaxAmount,
                    "TotalDiscount": TotalDiscount,
                    "MiniDownPayment": MiniDownPayment
                },
                "PaymentAmount": PaymentAmount,
                "TypePaymnet": {
                    "IsReservation": IsReservation,
                    "IsPayment": IsPayment,
                    "PaymnetTypeID": MethodPayment == "Financing" ? "Credit" : "Cash",//Credit - Cash
                    "MethodPayment": MethodPayment
                },
                "DeliveryType": {
                    "ByBranch": ByBranch,
                    "ByLocation": ByLocation
                },
                "DeliveryData": {
                    "InfoBranch": InfoBranch,
                    "InfoLocation": InfoLocation
                },
                "CobonData": {
                    "Code": couponDetail?.coupon?.code ?? null,
                    "ID": couponDetail?.coupon?.id ?? null,
                    "Amount": couponDetail?.coupon?.CAmount
                },
                "RefrancePayment": null,
                "status": status
            };
            console.log("Payment", raw);
            axios.request({
                url: config.paymentURL + "/api/Payment/CarPayment", headers: myHeaders,
                data: raw, method: "POST", responseType: "json"
            }).then(function (response) {
                console.log('response1', response);
                // console.log(response.data.URL_Paytabs);
                console.log('MethodPayment', MethodPayment);
                if (MethodPayment == "Online") {

                    if (response?.data?.CartID) {
                        saveData('carCheckoutCashID', response?.data?.CartID, false);
                    }
                    if (response?.data?.URL_Paytabs) {

                        window.location.href = response.data.URL_Paytabs;
                    } else if (response?.data?.MessageError && response?.data?.MessageError == "Duplicate Request") {
                        window.location.href = `${window.location.origin}/${lang}/car/checkoutCash/${sn}/${slug}`;

                    } else if (response?.data?.OrderId && response?.data?.ResponsMessage == "Paid") {

                        var data = response.data;
                        setShowFinalPopupMessage("تم الدفع مسبقا على هذا الطلب");
                        setShowFinalPopup(true);
                        setDataFinalPopup(data);

                    } else {
                        toast.error(trans("error:unknown_failure"));
                    }

                }

                setLoadingSendOrder(false);
            }
            ).then((response2) => {
                console.log('response2', response2);

                setLoadingSendOrder(false);
            }).catch(error => {

                console.log('error', error.messages)

                // alert(error.messages)

                setLoadingSendOrder(false);
            });
        }
    }
    // to get info of current ipv4
    const getUserIpAddress = async () => {
        let currentIpv4Info = {};
        try {
            const response = await axios.get('https://geolocation-db.com/json/');
            if (response.status === 200) {
                const { IPv4, country_code, country_name } = response.data;
                currentIpv4Info.ipv4 = IPv4;
                currentIpv4Info.countryCode = country_code;
                currentIpv4Info.countryName = country_name;
            }
        } catch (error) {
            toast.error(trans("extra:not_connection"))
            console.log(error);
        }
        return currentIpv4Info;
    };
    return (
        <>
            <section className="carOverview-page carCheckout-page flex flex-col   ">

                <div className="nice-sticky mb-40 show-xs">

                    <div className=" flex flex-row justify-between nice-align-item-center py-10 px-10 nice-fixed flex-item-center nice-shadow-3 bg-white w-100">

                        <img className=" rotate-180" src="/assets/icons/arrow-right.svg" width="7" height="7"
                            alt="arrow" onClick={() => {
                                history.goBack();
                            }} />
                        <span className="text-30">شراء سيارة كاش</span>
                        <span className=" text-24 text-gray"
                            onClick={() => {
                                history.push({
                                    pathname: `/car/overview/${sn}/${slug}`,
                                    search: ""
                                });
                            }}
                        >الغاء  </span>
                    </div>
                </div>
                <div className="carOverview-page-content grid grid-cols-12 gap-5
                          justify-items-auto mx-30 mt-20">



                    <div className="carOverview-page-main  h-vh border-radius col-span-12 md:col-span-8 sm:col-span-8 lg:col-span-7 xl:col-span-7">

                        <div className="flex mb-10 hide-xs">
                            <div className={"btn nice-flex-row border-radius nice-shadow-1 nice-border-checkout-gray  bg-white px-10  py-5  text-24 "}
                                onClick={() => {
                                    history.push({
                                        pathname: `/car/overview/${sn}/${slug}`,
                                        search: ""
                                    });
                                }} >{trans("info:الغاء")} </div>
                        </div>
                        <div className=" px-20 carOverview-page-main nice-shadow-3 bg-white  h-vh border-radius col-span-12">


                            <div className="flex flex-col py-20  px-10 ">


                                <div className="checkoutStepper">
                                    <div className={"checkoutStepper-part " + (dataFullNum > 0 ? "active" : " ")}
                                        onClick={() => cheangeSteps(1)}
                                    >
                                        <div className={`checkoutStepper-part-titel ${stepNum == 1 ? " active" : null}`}>
                                            <span>{trans("employment:أضف معلوماتك")}</span>
                                        </div>
                                        <div className={"checkoutStepper-part-connector  "}></div>
                                    </div>
                                    <div className={"checkoutStepper-part " + (dataFullNum > 1 ? "active" : " ")}
                                        onClick={() => cheangeSteps(2)}
                                    >
                                        <div className={`checkoutStepper-part-titel ${stepNum == 2 ? " active" : null}`}>
                                            <span> {trans("employment:التحقق من الرمز")}</span>
                                        </div>
                                        <div className={"checkoutStepper-part-connector  "}></div>
                                    </div>

                                    <div className={"checkoutStepper-part " + (dataFullNum > 2 ? "active" : " ")}
                                        onClick={() => cheangeSteps(3)}
                                    >
                                        <div className={`checkoutStepper-part-titel ${stepNum == 3 ? " active " : null}`}>
                                            <span>{trans("employment:الدفع")}</span></div>
                                        <div className={"checkoutStepper-part-connector  "}></div>
                                    </div>


                                </div>
                                <div className="ruler-2 mb-20"></div>

                                <form className="flex flex-col  my-5 " onSubmit={(e) => {
                                    // submitRequest(e);
                                }}>

                                    {
                                        stepNum == 1 &&
                                        <>

                                            <h3 className="text-30 text-bold mb-10  ">{"أضف معلوماتك"}</h3>
                                            <div className="flex flex-row grid-1-cols-importent nice-gap-2rem mt-20 ">
                                                <DropDownInput
                                                    title={trans("info:account_type")}
                                                    placeholder={trans("info:account_type")}
                                                    extraClasses={"dropdown-Input-module"}
                                                    selectedValue={requestForm.accountType}
                                                    errorLabel={error?.accountType}
                                                    options={[
                                                        ...accountTypes.map(item => {
                                                            if (lang === 'ar') {
                                                                return { text: item.DescriptionAr, value: item.ID, isClient: item.NeedIdentity }
                                                            } else {
                                                                return { text: item.DescriptionEn, value: item.ID, isClient: item.NeedIdentity }
                                                            }
                                                        })
                                                    ]}
                                                    onfilter={(item) => {

                                                        const val = { ...requestForm };
                                                        val.accountType = item.value;
                                                        val.isClientAccount = item.isClient;
                                                        setRequestForm(val);
                                                        if (item.isClient == undefined) {
                                                            setIsClientAccount(null);
                                                            return;
                                                        }
                                                        setIsClientAccount(item.isClient);
                                                    }}
                                                />
                                            </div>
                                            <div className="flex flex-row grid-1-cols-importent nice-gap-2rem mt-20 ">
                                                {isClientAccount != null && isClientAccount == true ?
                                                    <>
                                                        <InputField
                                                            key={"identity"}
                                                            length={10}
                                                            moduleLabel={trans("form:identity-no")}
                                                            name='identity'
                                                            errorLabel={error?.identity}
                                                            extraClasses={"Input-module"}
                                                            value={requestForm?.identity}
                                                            onChangeValue={(e) => {
                                                                const val = { ...requestForm };
                                                                val.identity = e.target.value;
                                                                setRequestForm(val);
                                                            }}
                                                            placeholder={trans("form:identity-no")}
                                                        />

                                                    </>
                                                    : isClientAccount != null && isClientAccount == false ? <>
                                                        <InputField
                                                            key={"commercialIdentity"}
                                                            length={14}
                                                            name='commercialIdentity'
                                                            errorLabel={error?.commercialIdentity}
                                                            moduleLabel={trans("info:trade_no")}
                                                            extraClasses={"Input-module"}
                                                            value={requestForm?.commercialIdentity}
                                                            onChangeValue={(e) => {
                                                                const val = { ...requestForm };
                                                                val.commercialIdentity = e.target.value;
                                                                setRequestForm(val);
                                                            }}
                                                            placeholder={trans("info:trade_no")}
                                                        />

                                                    </> : null
                                                }
                                            </div>
                                            <div className="flex flex-row grid-1-cols-importent nice-gap-2rem mt-20 ">
                                                <InputField
                                                    placeholder={""}
                                                    disabled={personalDataRequest?.arFirstName || personalDataRequest?.enFirstName}
                                                    moduleLabel={"الاسم بالكامل"}
                                                    value={requestForm.name}
                                                    errorLabel={error.name}
                                                    extraClasses={"Input-module"}
                                                    onChangeValue={(e) => {
                                                        const val = { ...requestForm };
                                                        val.name = e.target.value;
                                                        setRequestForm(val);
                                                    }}
                                                    name='name'
                                                />
                                            </div>

                                            <div className="flex flex-row grid-1-cols-importent nice-gap-2rem mt-20 ">
                                                {

                                                    < PhoneDropDown
                                                        disabled={personalDataRequest?.phone}
                                                        mandotory={true}
                                                        name='phoneNumber'
                                                        moduleLabel={"رقم الجوال"}
                                                        extraClasses={"phone-Input-module"}
                                                        defaultFlag={requestForm.phoneNumberCode}
                                                        defaultValue={requestForm.phoneNumberOnly}
                                                        errorLabel={error?.phoneNumber}
                                                        onChangeValue={(countryCode, phoneNumber) => {
                                                            const val = { ...requestForm };
                                                            val.phoneNumberOnly = phoneNumber;
                                                            val.phoneNumberCode = countryCode;
                                                            val.phoneNumber = `+${countryCode}${phoneNumber}`;
                                                            setRequestForm(val);
                                                        }}

                                                    />
                                                }
                                            </div>


                                            <div className="flex flex-row grid-1-cols-importent nice-gap-2rem mt-20 px-5 ">
                                                <CheckInput
                                                    className="text-primary cursor-pointer"
                                                    checked={requestForm.NotificationWhatsApp}
                                                    oncheng={async (target) => {
                                                        console.log("NotificationWhatsApp:", target.checked);
                                                        const val = { ...requestForm };
                                                        val.NotificationWhatsApp = target.checked;
                                                        setRequestForm(val);
                                                    }}
                                                    Name={"check-conectWat"}
                                                >

                                                    <span className="whatsapp-icon">
                                                        <img className="object-max-full " src='/assets/icons/social/wpImg.svg' width='25' height='25' />
                                                    </span>
                                                    <span className='text-20 nice-pointer text-inline-block'>
                                                        <span className='text-20 nice-pointer  text-inline-block'>{trans("info:  أود التواصل واستقبال تحديثات طلبي عن طريق الواتساب")}</span>
                                                    </span>
                                                </CheckInput>
                                            </div>
                                            <div className="flex flex-row grid-1-cols-importent nice-gap-2rem mt-20 ">
                                                <DropDownInput
                                                    title={trans("new:city")}
                                                    errorLabel={error.City}
                                                    extraClasses={"dropdown-Input-module"}
                                                    selectedValue={requestForm.City}
                                                    options={[...filteredCities?.map(item => {
                                                        return { text: item?.description, value: item?.id, optional: item?.code }
                                                    })]}
                                                    onfilter={(Citey) => {
                                                        const val = { ...requestForm };
                                                        val.City = Citey.value;
                                                        setRequestForm(val);
                                                        setSelectcity(Citey)

                                                    }}
                                                />
                                            </div>

                                            <div className="ruler-2 mt-20 mb-5"></div>

                                            <h3 className="text-30 text-bold mb-10  ">{"هل يوجد لديك كود؟"}</h3>

                                            <div className="flex flex-row grid-1-cols-importent nice-gap-2rem mt-20 ">
                                                <CouponControl
                                                    onReset={() => {
                                                        setClearCoupont(false);
                                                        SetTotalDiscount(0);
                                                        setCouponDetail(null);
                                                    }}
                                                    onSubmit={(codeValue) => {
                                                        applyCoupon(codeValue);
                                                    }}
                                                    reset={clearCoupon}
                                                    discountPrice={TotalDiscount}
                                                    couponCode={couponDetail?.coupon?.description}
                                                />


                                            </div>


                                            <p className='text-20 text-gray text-bold text-inline-block mt-20' >{"- هذا العربون مسترد بالكامل حتى مرحلة تسجيل السيارة، وسيتم خصم العربون من المبلغ الإجمالي."}</p>


                                            <div className=" grid nice-align-item-center grid-cols-12 gap-5 justify-items-auto p-10   my-20 ">

                                                <div className="border-box-periodic-checkInput col-span-12 md:col-span-7 sm:col-span-7 lg:col-span-7 xl:col-span-7">


                                                </div>

                                                <div className="col-span-12 md:col-span-5 sm:col-span-5 lg:col-span-5 xl:col-span-5">

                                                    <div className={"btn nice-flex-row  py-5 px-5 text-white text-24 " + (dataFullNum > 0 ? " bg-primary " : " bg-gray ")}
                                                        onClick={async () => {
                                                            const num = await checkStepsErrors();

                                                            if (num >= (stepNum)) {

                                                                if (stepNum < 3) {

                                                                    setStepNum(stepNum + 1);
                                                                    handleRunSubFunction();
                                                                }

                                                            } else {
                                                                // checkErrors();
                                                            }
                                                        }}




                                                    >{trans("info:اكمل لدفع العربون")} </div>
                                                </div>

                                            </div>


                                        </>}


                                    {
                                        stepNum == 2 &&
                                        <>

                                            <SMSCheck
                                                mobile={requestForm?.phoneNumber}

                                                setSubFunctionRef={setSubFunctionRef}
                                                SignUpData={requestForm}
                                                onSucess={async (codeOtp) => {
                                                    setOTP(codeOtp);

                                                    const val = { ...requestForm };
                                                    val.OTP = codeOtp;
                                                    setRequestForm(val);
                                                    //console.log("SMS> Click Continue >>>>>>")
                                                    const num = await checkStepsErrors();

                                                    if (num >= (stepNum)) {

                                                        if (stepNum <= 2) {
                                                            setStepNum(stepNum + 1);

                                                        }
                                                    } else {
                                                        // checkErrors();
                                                    }
                                                }}

                                                goBack={async () => {
                                                    setOTP("");
                                                    setStepNum(stepNum - 1);
                                                    const val = { ...requestForm };
                                                    val.OTP = "";
                                                    setRequestForm(val);
                                                }}
                                            />

                                        </>}


                                    {
                                        stepNum == 3 &&
                                        <>

                                            {/* <h3 className="text-30 text-bold mb-10  ">{" الدفع "}</h3> */}
                                            <SectionHeading
                                                hasRuler={false}
                                                title={trans("extra:choose_receive_way") + ":"}
                                            >

                                                {/*    <RadioInputDynamic
                                                    onChangeValue={(value) => {
                                                        const cunrrentvalue = currentDeliveryMethod;
                                                        setDeleviryType(value);
                                                        cunrrentvalue.branchMethod = value === 2 ? true : false;
                                                        cunrrentvalue.locationMethod = value === 1 ? true : false;
                                                        setcurrentDeliveryMethod(cunrrentvalue);

                                                        const val = { ...requestForm };
                                                        val.develviryType = value;
                                                        setRequestForm(val);

                                                    }}
                                                    extraClasses={"Radio-Input-module"}
                                                    selectValue={develviryType}
                                                    name='recieveMethod'
                                                    groupName="recieveMethod"
                                                    title={""}
                                                    ListValues={[{ text: trans("new:delivery"), value: 1 }, {
                                                        text: trans("new:branch"),
                                                        value: 2
                                                    }]}
                                                    errorLabel={error.develviryType}
                                                />*/}
                                                {
                                                    <>
                                                        {develviryType == 2 &&
                                                            <div className="flex flex-row grid-1-cols-importent nice-gap-2rem mt-20 ">
                                                                <DropDownInput
                                                                    title={trans("cartFile:branch")}
                                                                    onSelect={(option) => {
                                                                    }}
                                                                    extraClasses={"dropdown-Input-module"}
                                                                    selectedValue={ValueBranch}
                                                                    options={[
                                                                        ...Branches.map(A => {
                                                                            if (lang === "ar") {
                                                                                return { value: A.SiteID, text: A.DescriptionAr };
                                                                            }
                                                                            else {
                                                                                return { value: A.SiteID, text: A.DescriptionEn };
                                                                            }

                                                                        })]}
                                                                    onfilter={getValueBranch}
                                                                />

                                                                <BranchesInfo
                                                                    values={branchesDetails}
                                                                />

                                                            </div>

                                                        }
                                                        {develviryType == 1 &&
                                                            <div className="flex flex-row grid-1-cols-importent nice-gap-2rem mt-20 ">
                                                                <DropDownInput
                                                                    extraClasses={"dropdown-Input-module"}
                                                                    title={trans("new:city")}
                                                                    selectedValue={ValueCitie}
                                                                    options={[...cities]}
                                                                    onfilter={(Citey) => CarShippingInfo(Citey, Size)}
                                                                />

                                                                <p className='text-20 text-gray text-inline-block' >{"سيتم اضافة تكاليف شحن السيارة لمدينتك على المبلغ الاجمالي"}</p>

                                                            </div>
                                                        }
                                                    </>
                                                }

                                            </SectionHeading>

                                            <>
                                                <div className="ruler-2 mt-20 mb-5"></div>

                                                {
                                                    develviryType == 1 && <>
                                                        {
                                                            Shipping.CountTime ?
                                                                <div className="cart-grid-info-div mb-8">
                                                                    <div className={"text-16 text-gray "}>{trans("cartFile:receive_peroid")}</div>
                                                                    <div className={"text-16 text-gray font-bold"}>{Shipping.CountTime} {Shipping.UnitTime} </div>
                                                                </div>
                                                                : null
                                                        }
                                                        {
                                                            Shipping.Cost ?
                                                                <div className="cart-grid-info-div">
                                                                    <div className={"text-16 text-gray"}>{trans("cartFile:shipping_costs")}</div>
                                                                    <div className={"text-16 text-gray font-bold"}>{numberFormat(Shipping.Cost, lang)}</div>
                                                                </div>
                                                                : null

                                                        }

                                                    </>
                                                }

                                                {
                                                    TotalDiscount ?
                                                        <div className="cart-grid-info-div ">
                                                            <div className={"text-16 text-gray"}>{trans("cartFile:discount_total")}</div>
                                                            <div className={"text-16 text-gray font-bold"}>{numberFormat(TotalDiscount, lang)}</div>
                                                        </div> : null
                                                }


                                                {

                                                    develviryType == 1 ?
                                                        <div className="cart-grid-info-div border-tb">
                                                            <div className={"text-16 font-bold text-black"}>{trans("new:total")}&nbsp;<span className="text-14 text-gray mx-1">{trans("new:total_include_tax")}</span></div>
                                                            <div className={"text-16 text-black font-bold"}>{numberFormat((fullTotal + Shipping.Cost) - TotalDiscount, lang)}</div>
                                                        </div>
                                                        :
                                                        <div className="cart-grid-info-div border-tb">
                                                            <div className={"text-16 font-bold text-black"}>{trans("new:total")}&nbsp;<span className="text-14 text-gray mx-1">{trans("new:total_include_tax")}</span></div>
                                                            <div className={"text-16 text-black font-bold"}>{numberFormat((fullTotal) - TotalDiscount, lang)}</div>
                                                        </div>
                                                }



                                                {
                                                    SubTotal &&
                                                    <div className="cart-grid-info-div">
                                                        <div className={"text-16 text-gray"}>{trans("cartFile:Downpayment")}</div>
                                                        <div className={"text-16 text-gray font-bold"}>{numberFormat(SubTotal, lang)}</div>
                                                    </div>
                                                }


                                                <div className="cart-grid-info-div border-tb">
                                                    <div className={"text-16 font-bold text-black"}>{trans("new:min_down_payment")}</div>
                                                    <div className={"text-16 text-black font-bold"}>{numberFormat(MiniDownPayment, lang)}</div>
                                                </div>
                                            </>


                                            <div className="my-10 ">
                                                <div className="flex flex-col nice-gap-2rem">
                                                    <h3 className="text-gray text-20">{trans("new:avilable_platforms")}</h3>
                                                    <div className="breakout!! flex flex-col nice-gap-2rem ">


                                                        {
                                                            platformTypes && platformTypes.includes("payTabs") &&
                                                            <div className={"flex flex-col nice-gap-2rem   " + (paymentType == "Online" ? " bg-gray-lighter " : "")}>
                                                                <label className={" text-20 font-bold flex flex-row nice-gap-1rem "}>
                                                                    <input type='radio' checked={paymentType == "Online" ? true : false} name='paymentType'
                                                                        onClick={() => {
                                                                            setPaymentType("Online");
                                                                            const val = { ...requestForm };
                                                                            val.paymentType = "Online";
                                                                            setRequestForm(val);

                                                                        }}

                                                                        onChange={() => {



                                                                        }}
                                                                        id="primary"
                                                                    />
                                                                    {(trans("new:online_batch") + " " + trans("new:and_the_rest_via_SADAD"))}

                                                                </label>
                                                                <div className="flex flex-row items-center  mx-10">
                                                                    <img className="cart-payment-icon object-contain" src="/assets/icons/companies/master-card-3.png" alt={"master"} />
                                                                    <img className="cart-payment-icon object-contain" src="/assets/icons/companies/visa-3.png" alt={"visa"} />
                                                                    <img className="cart-payment-icon object-contain" src="/assets/icons/companies/mada-3.png" alt={"mada"} />

                                                                    <img className="cart-payment-icon object-contain" src={"/assets/icons/companies/SADAD-Logo.png"} alt={"SADAD"} />

                                                                </div>

                                                            </div>


                                                        }

                                                        {
                                                            platformTypes && platformTypes.includes("myfatoorah") &&
                                                            <div className={"flex flex-col nice-gap-2rem border-radius p-5 " + (paymentType == "myfatoorah" ? " bg-gray-light " : "")}
                                                                onClick={() => {
                                                                    setPaymentType("myfatoorah");
                                                                }}
                                                            >
                                                                <label className={" text-20 font-bold flex flex-row nice-gap-1rem "}>
                                                                    <input type='radio' checked={paymentType == "myfatoorah" ? true : false} name='paymentType'


                                                                        onChange={() => {



                                                                        }}
                                                                        id="primary"
                                                                    />
                                                                    {trans("new:online_all")}

                                                                </label>
                                                                <div className="flex flex-row items-center  mx-10">
                                                                    <img className="cart-payment-icon object-contain" src="/assets/icons/companies/master-card-3.png" alt={"master"} />
                                                                    <img className="cart-payment-icon object-contain" src="/assets/icons/companies/visa-3.png" alt={"visa"} />
                                                                    <img className="cart-payment-icon object-contain" src="/assets/icons/companies/mada-3.png" alt={"mada"} />

                                                                </div>

                                                            </div>


                                                        }
                                                    </div>

                                                </div>
                                            </div>

                                            <div className=" grid nice-align-item-center grid-cols-12 gap-5 justify-items-auto p-10   my-20 ">

                                                <div className="border-box-periodic-checkInput col-span-12 md:col-span-8 sm:col-span-8 lg:col-span-8 xl:col-span-8">
                                                </div>

                                                <div className="col-span-12 md:col-span-4 sm:col-span-4 lg:col-span-4 xl:col-span-4">

                                                    <div className={"btn nice-flex-row  py-5 px-5 text-white text-30 " + (dataFullNum > 2 ? " bg-primary " : " bg-gray ")}
                                                        onClick={async () => {
                                                            const num = await checkStepsErrors();

                                                            if (num >= (stepNum)) {

                                                                // if (stepNum < 3) {
                                                                setStepNum(3);
                                                                handlePayment();
                                                                // }

                                                            } else {
                                                                // checkErrors();
                                                            }
                                                        }}




                                                    >
                                                        {
                                                            isLoadingSendOrder && <span className='mx-2 loading-btn '>
                                                                <img className="object-max-full " src='/assets/icons/loading.svg' width='25' height='25' />
                                                            </span>
                                                        }

                                                        {trans("info:دفع")}

                                                    </div>
                                                </div>

                                            </div>


                                        </>}
                                </form>


                            </div>




                            {/* //------------------------ */}

                            <div id="parent-footer"
                                className="eparts-page-footer bg-theme-up show-xs"
                                style={{ height: scrollSnackUp.height }}
                                onTouchEnd={(event) => {
                                    var hh = "70rem";
                                    let vx = document.getElementById("parent-footer");
                                    if (vx) {
                                        var hh = vx.scrollHeight + 50;
                                        //console.log(hh);  // Check the scroll height value
                                    }

                                    if (!event?.target?.id.includes("footer-actions")) {
                                        const currentValue = { ...scrollSnackUp };
                                        currentValue.show = !currentValue.show;
                                        if (currentValue.show) {
                                            currentValue.height = hh;
                                        } else {
                                            currentValue.height = '85px';
                                        }
                                        setScrollSnackUp(currentValue);

                                    }

                                }}>

                                {

                                    (


                                        ((carInfo?.buyOnline == true)) ? (
                                            <>


                                                <div className="flex flex-row justify-between">

                                                    <div className="" >

                                                        <div className="carOverview-page-body-desc">
                                                            <div className="carOverview-page-body-name">
                                                                <div className="carOverview-page-body-type">
                                                                    <div className="carOverview-page-body-type-content nice-gap-1rem">
                                                                        <h2 className="text-20 text-bold">{propsData?.vehicle?.brand && propsData?.vehicle?.brand}</h2>
                                                                        {/* <img src={vehicle?.brandIconPath && vehicle?.brandIconPath} /> */}
                                                                        <img className="" src={`/assets/icons/brands/${(isToyota ? "toyota" : "") + (isLexus ? "lexus" : "")}.webp`} />
                                                                    </div>
                                                                </div>

                                                                <h3 className="text-30 text-bold">{vehicle?.name && vehicle?.name}</h3>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div className="flex flex-col justify-center">
                                                        {<>
                                                            <div className="flex flex-row gap-1">
                                                                <div className="flex flex-row gap-1">
                                                                    <span className="text-30 font-bold text-primary">{(carInfo?.NetSalePrice) && <Price price={parseFloat(carInfo?.NetSalePrice)} />}</span>
                                                                </div>
                                                            </div>
                                                        </>}
                                                    </div>

                                                </div >
                                                {
                                                    // scrollSnackUp.show &&
                                                    <div className="flex flex-col nice-gap-1rem py-5">


                                                        <div className="carOverview-page-body-desc">

                                                            <div className="nice-container carOverview-page-body-number flex flex-row nice-gap-1rem my-10">
                                                                <span className="text-20 text-gray"> {trans("new:item_no")}</span>
                                                                <span className="text-20 text-gray">{vehicle?.productNo ? vehicle?.productNo : "########"}</span>
                                                            </div>
                                                        </div>

                                                        <div className="carOverview-page-body-img">
                                                            <img className="carOverview-page-header-img"
                                                                // src={"data:image;base64," + image.DATA}
                                                                src={config.FilePathURL + filterCarImages[0]?.Image_URL}
                                                            />

                                                        </div>


                                                    </div>
                                                }
                                            </>
                                        )

                                            :
                                            (

                                                <div className="flex flex-row justify-between">
                                                    <div className="flex flex-col justify-center">


                                                    </div>


                                                    <div id="footer-actions-buy" className="flex flex-col btn-notfound bg-gray-light p-5">
                                                        <div id="footer-actions-buy" className="flex flex-row items-center justify-center btn-notfound-available">
                                                            <p id="footer-actions-buy" className="text-center  text-24  text-danger ">
                                                                {trans("new:not_available")}
                                                            </p>
                                                        </div>

                                                        <div className="border-l-5 my-5 mx-5 text-gray"></div>


                                                        <div id="footer-actions-buy" className="flex flex-row basis-2/4 items-center btn-notfound-alert"

                                                            onClick={() => {

                                                            }}
                                                        >
                                                            <p id="footer-actions-buy" className="text-center text-alert text-24  text-line-clamp   ">
                                                                {trans("new:alert_when_available")}
                                                            </p>
                                                            <img className="  " src="/assets/icons/alert-icon.svg" />
                                                        </div>
                                                    </div>


                                                </div>
                                            )




                                    )

                                }


                            </div >




                            {/* //----------------- */}
                        </div >
                    </div >

                    <div className=" hide-xs col-span-0  md:col-span-4 sm:col-span-4 lg:col-span-5 xl:col-span-5 ">
                        <div className="carOverview-page-sub pt-20 nice-shadow-3 border-radius bg-white ">
                            <div className=" py-10 px-5 ">
                                <div className="py-5">
                                    <h3 className="text-30 text-bold ">{"تفاصيل الطلب"}</h3>
                                </div>

                                <div className="ruler-2 mb-5"></div>
                                <div className="carOverview-page-body">
                                    {

                                        (
                                            propsData?.vehicle ?
                                                <>

                                                    <div className="carOverview-page-body-desc">
                                                        <div className="carOverview-page-body-name">
                                                            <div className="carOverview-page-body-type">
                                                                <div className="carOverview-page-body-type-content nice-gap-1rem">
                                                                    <h2 className="text-20 text-bold">{propsData?.vehicle?.brand && propsData?.vehicle?.brand}</h2>
                                                                    {/* <img src={vehicle?.brandIconPath && vehicle?.brandIconPath} /> */}
                                                                    <img className="" src={`/assets/icons/brands/${(isToyota ? "toyota" : "") + (isLexus ? "lexus" : "")}.webp`} />
                                                                </div>
                                                            </div>

                                                            <h3 className="text-20 text-gray">{vehicle?.name && vehicle?.name}</h3>
                                                        </div>
                                                        <div className="nice-container carOverview-page-body-number flex flex-row nice-gap-1rem my-10">
                                                            <span className="text-20 text-gray"> {trans("new:item_no")}</span>
                                                            <span className="text-20 text-gray">{vehicle?.productNo ? vehicle?.productNo : "########"}</span>
                                                        </div>
                                                    </div>

                                                    <div className="carOverview-page-body-img">
                                                        <img className="carOverview-page-header-img"
                                                            // src={"data:image;base64," + image.DATA}
                                                            src={config.FilePathURL + filterCarImages[0]?.Image_URL}
                                                        />

                                                    </div>
                                                </>
                                                :
                                                <div className="container search-line my-40"><h4 className="text-gray text-20 py-2">{trans("new:no_data")}</h4></div>
                                        )


                                    }
                                </div>
                                <div className="ruler-2 mb-5"></div>
                                <div className="nice-container flex flex-col gap-3 justify-between">
                                    <div className="flex flex-col justify-center">
                                        {<>

                                            <div className="nice-flex nice-flex-row nice-flex-space-between mb-10">
                                                <h4 className="text-20">{trans("new:cash_price")}&nbsp;<span className="text-14 text-gray mx-1">{trans("new:total_include_tax")}</span> </h4>
                                                <span className="text-20 font-bold text-primary">{(carInfo?.NetSalePrice) && <Price price={parseFloat(carInfo?.NetSalePrice)} />}</span>
                                            </div>

                                        </>}
                                    </div>

                                </div>



                                {/* {
                                <div className="flex flex-col nice-gap-1rem py-5">


                                    {
                                        finance?.totalPrice ?
                                            <>
                                                <p className="text-center text-24 mt-10">
                                                    {trans("new:installment_price")}
                                                </p>
                                                <div className="nice-ruler-base bg-secondary"></div>

                                                <div className="nice-flex nice-flex-column nice-gap-1rem">
                                                    <div className="nice-flex nice-flex-row nice-flex-space-between my-4">
                                                        <h3 className="text-gray-dark text-16">{trans("finance:finance")}</h3>
                                                        <p className={"text-primary-dark text-16"}>{lang == "ar" ? financeSettings.DescriptionAr : financeSettings.DescriptionEn}</p>
                                                    </div>
                                                    <div className="nice-flex nice-flex-row nice-flex-space-between my-4">
                                                        <h3 className="text-gray-dark text-16">{trans("finance:period")}</h3>
                                                        <p className={"text-primary-dark text-16"}>{finance?.period}</p>
                                                    </div>
                                                    <div className="nice-flex nice-flex-row nice-flex-space-between my-4">
                                                        <h3 className="text-gray-dark text-16">{trans("finance:management_fees")}</h3>
                                                        <p className={"text-primary-dark text-16"}><Price price={finance?.mangFees} showZero={true} /></p>
                                                    </div>
                                                    <div className="nice-flex nice-flex-row nice-flex-space-between my-4">
                                                        <h3 className="text-gray-dark text-16">{trans("finance:monthPayment")}</h3>
                                                        <p className={"text-primary-dark text-16"}><Price price={finance?.price} showZero={true} /></p>
                                                    </div>
                                                    <div className="nice-flex nice-flex-row nice-flex-space-between my-4">
                                                        <h3 className="text-gray-dark text-16">{trans("finance:advance_payment")}</h3>
                                                        <p className={"text-primary-dark text-16"}> <Price price={finance?.first} showZero={true} /></p>
                                                    </div>
                                                    <div className="nice-flex nice-flex-row nice-flex-space-between my-4">
                                                        <h3 className="text-gray-dark text-16">{trans("finance:lastPayment")}</h3>
                                                        <p className={"text-primary-dark text-16"}> <Price price={finance?.last} showZero={true} /></p>
                                                    </div>

                                                    <div className="mx-2 my-10 nice-flex nice-flex-row">
                                                        <img src="/assets/icons/info.svg" className="mx-3 text-inline-block nice-max-icon" />
                                                        <p className="text-12 text-bold text-gray text-inline-block">{trans("finance:This_calculation_is_estimate")} </p>
                                                    </div>
                                                </div>

                                                <div className="nice-ruler-base bg-secondary"></div>

                                            </>
                                            : null

                                    }


                                </div>
                            } */}


                            </div>
                        </div>
                    </div>
                </div >


            </section >

            <Popup show={showFinalPopup}>
                <div className="w-full  flex flex-column justify-center items-center" >
                    <div className="cart-finalized" >
                        <div className="cart-finalized-container flex flex-col">

                            <div className="cart-finalized-container-img flex flex-col">
                                <img src="/assets/images/finalized.svg" />
                            </div>

                            <div className="flex flex-wrap justify-center">
                                <span className="text-20 font-bold text-black mx-2 unselectable ">{trans("new:thank_to_choose")}</span>
                                <span className="text-20 font-bold text-primary mx-2"> {trans("new:HJ_cars")}</span>
                            </div>

                            {
                                <>
                                    <div className="flex flex-wrap justify-center mx-20">
                                        <span className="text-24 text-gray  mx-2"> {showFinalPopupMessage}</span>
                                    </div>

                                </>

                            }
                            {
                                dataFinalPopup &&
                                <>

                                    {
                                        dataFinalPopup?.OrderId &&
                                        <div className="flex flex-row justify-center cart-finalized-code"
                                            onClick={() => {

                                                navigator.clipboard.writeText(dataFinalPopup?.OrderId);
                                            }}

                                        >

                                            <span className="text-20 font-bold text-white  mx-2"> {trans("info:order-number") + " :"}</span>
                                            <span className="text-20 font-bold text-white  mx-2">{dataFinalPopup?.OrderId && dataFinalPopup?.OrderId}</span>
                                            <img src="/assets/icons/copy.svg" alt='' />
                                        </div>
                                    }
                                </>

                            }
                            <div className="flex flex-center flex-col  mt-5 pt-5 w-80">
                                <div className=" mb-5 w-100">
                                    <div className="nice-sm-btn cart-notification-go  bg-primary text-white text-16" onClick={() => {


                                        if (location.pathname)
                                            history.push("/");

                                    }}>
                                        {trans("cartFile:Continue_browsing")}
                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>
                </div>
            </Popup>
        </>
    );
}