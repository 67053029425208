function WhyHJBox({icon, title, subtitle,secondTheme}) {
    return (
        <div className={"border-radius-sm why-box " +(secondTheme ? " why-box-second ": " ")}>
            <div className="why-box-img">
                <img data-src={icon} className={"lazyload object-full object-contain dark-filter-white"}  alt={title}/>
            </div>
            <div className="why-box-text">
                <h3 className={"text-30 font-bold text-primary mb-5 text-line-clamp text-line-clamp-2"}>{title}</h3>
                <p className={"text-20 text-gray text-line-clamp text-line-clamp-2"}>{subtitle}</p>
            </div>

        </div>
    );
}

export default WhyHJBox;
