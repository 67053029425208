import { useTranslation } from "react-i18next";
import { Link,useHistory } from "react-router-dom";
import { Price } from "./__simple/_price";
import ConfirmContext from "../utils/confirmContext";
import { useContext } from "react";
import { slugCombine } from "../utils/slugManager";
import { useCompare } from "../utils/compareProvider";
import config from "../configuration/config.json"
function Slide({ id, modelTypes, img, bg, type, seats, name, brand, year, slug, minPrice, HP, cylinders, petrol, colorID,

    RateCareDiscount, group, Tax, ProductId, otherBrand, end_Offer, NewCar_DateEnd, NewCar, Hybird, showPric, hybridImage,
    viewSliderIcon,
    viewSliderDetails,
    viewBtns,
    sliderBackOpasity,
    brandID,
}) {

    const { t: trans,i18n } = useTranslation(["car", "common", "vehicales", "info"]);
    const languageDir = 'language-' + (i18n.language || 'ar');
    const context = useContext(ConfirmContext);
    let history = useHistory();
    const { onInsert } = useCompare(null);
    function handleRequest() {
        try {
            context.onShow(
                {
                    displayConfirm: true,
                    title: name,
                    image: img,
                    brand: brand,
                    isHyper: Hybird,
                    isNew: NewCar,
                    type: type,
                    brandID: brandID,
                    defaultColorID: colorID,
                    modelYear: year,
                    cartDetails: {
                        id: ProductId,
                        name: name,
                        price: minPrice,
                        brand: brand,
                        image: img,
                        Tax: Tax,
                        Discount: RateCareDiscount,
                        Type: "car",
                        modelTypes: modelTypes
                    },
                    carOrder: {
                        vehicle: ProductId,
                        group: group
                    },
                }
            );
        } catch (err) {
            console.log(err);
        }

    }
    function getpric() {
        if (RateCareDiscount > 0) {
            return (

                <div className="slide-info-text-price 30 space-x-4 space-x-reverse">
                    <span className={"text-24 mx-1"}>{trans("info:price")}:</span>
                    <span className={"price  text-30 mx-2"}><Price price={RateCareDiscount} /></span>
                    <span className={"old-price  text-30 mx-3"}>{minPrice}{trans("common:rial")}</span>

                </div>);
        } else {
            return (
                <div className="slide-info-text-price 30 space-x-4 space-x-reverse">
                    <span className={"text-24 mx-3"}>{trans("info:price")}:</span>
                    <span className={"price  text-30"}><Price price={minPrice} /></span>
                </div>);
        }
    }
    function getEndDate() {
        if (end_Offer) {
            return (
                <div className={"flex items-center slide-offer-date my-8"}>
                    <img data-src="/assets/icons/info.svg" className={"pl-4 size-24"} alt="info" />
                    <span className={"text-20 mx-2"}>{trans("common:End-Date-view")}&nbsp;{new Date(end_Offer).toLocaleDateString()}</span>
                </div>
            );
        }

    }

    // the following function is used to add the card to compare list
    const handleAddToCompare = (value) => {
        onInsert(value);
    };
 
    return (
        <div className=" mx-10 mb-8 bg-page slide-car-container p-8 border-radius box-shadow-large">
            <div className="slide-overlay" style={(sliderBackOpasity && sliderBackOpasity != 0) ? { background: "linear-gradient( #090d0b 0%, rgba(26, 28, 27, 0.76) " + sliderBackOpasity + "%, rgba(42, 42, 42, 0.13) 100%)" } : { background: "none" }}>

            </div>
            <div className="slide-bg" ><img className="" width={787} height={203}  src={config.FilePathURL + bg} alt="slide cars image" onError={e => e.target.style.display = 'none'} /></div>

            <div className="slide-info  mb-8 pb-8" style={{ height: 100 + "%" }}>
                <Link to={`/vehicles/${slugCombine(brand, otherBrand)}/${group}/${year}`}
                 className="slide-info-img flex-grow-1">
                    {
                        viewSliderIcon &&
                        <img className="" width={395} height={142} src={config.FilePathURL + img} 
                        alt="Slider Icon" />
                    }

                </Link>

                <div className={"slide-info-desc  " + languageDir}>

                    <div className={"slide-info-text mx-8 lex-grow-2  mx-3 "+ languageDir }>
                        {

                            viewSliderDetails &&
                            <div className={"text-section language-en"}>
                                <h2 className={" text-line-clamp text-line-clamp-2"}>
                                    {name}
                                </h2>
                                {showPric && getpric()}
                            </div>
                        }


                        {
                            viewBtns &&
                            <>
                                {getEndDate()}
                                <div className={"slide-info-text-btn"}>
                                    <button
                                        onClick={() => {
                                            handleRequest();
                                        }}
                                        className={"btn btn-primary text-24 w-full py-5"}>{trans("common:buy")}</button>
                                </div>
                                <div
                                    className={"slide-info-text-links w-full flex items-center justify-center  space-x-2 space-x-reverse"}>

                                    <Link to={`/vehicles/${slugCombine(brand, otherBrand)}/${group}/${year}`}
                                        className={"btn btn-black-50 text-14px font-size-js text-nowrap w-full"}>
                                        <span className={"text-12 mx-2"}>{trans("car:overview")}</span>
                                        <img  width={20} height={20}  className={" pr-2 size-26"} src="/assets/icons/car/overview.svg"
                                            alt="overview" />
                                    </Link>
                                    <Link to={`/vehicles/${slugCombine(brand, otherBrand)}/${group}/${year}/feature?t=feature`}
                                        className={"btn btn-black-50 text-14px font-size-js text-nowrap w-full"}>
                                        <span className={"text-12 mx-2"}>{trans("car:features")}</span>
                                        <img  width={20} height={20}  className={" pr-2 size-26"} src="/assets/icons/car/features.svg"
                                            alt="features" />
                                    </Link>
                                    <Link to={`/vehicles/${slugCombine(brand, otherBrand)}/${group}/${year}/gallery?t=gallery`}
                                        className={"btn btn-black-50 text-14px font-size-js text-nowrap w-full"}>
                                        <span className={"text-12 mx-2"}>{trans("car:gallery")}</span>
                                        <img  width={20} height={20}  className={" pr-2 size-26"} src="/assets/icons/car/gallery.svg" alt="gallery" />
                                    </Link>
                                    {
                                        // <button onClick={() => { { handleAddToCompare(ProductId) } }} className={"btn btn-black-50 text-14px font-size-js text-nowrap w-full"}>
                                        //     <span className={"text-12 mx-2"}>{trans("car:compare")}</span>
                                        //     <img className={"pr-2 size-26"} data-src="/assets/icons/car/compare.svg" alt="compare" />
                                        // </button>
                                    }

                                </div>
                            </>
                        }


                    </div>



                    {
                        viewSliderDetails &&
                        <div className="slide-info-spec space-y-12">
                            <div>
                                <span className={"text-24 flex items-center flex-col"}>
                                    <span className={"text-24"}>{petrol}</span>
                                    <span className={"text-20"}>{trans("vehicales:veh-petrol")}</span>
                                </span>
                                <div className="spec-img">
                                        <img className=""  width={43} height={43} src="/assets/icons/car/petrol.svg" alt="petrol" />
                                </div>
                            </div>
                            <div>
                                <span className={"text-24 flex items-center flex-col"}>
                                    <span className={"text-24"}>{cylinders}</span>
                                    <span className={"text-20"}>{trans("car:cylinders")}</span>
                                </span>
                                <div className="spec-img" style={{ minWidth: '4rem' }}>
                                        <img className=""   width={43} height={43} src="/assets/icons/car/cylinders.svg" alt="cylinders" />
                                </div>
                            </div>
                            <div>

                                <span className={"text-24 flex items-center flex-col"}>
                                    <span className={"text-24"}>{HP}</span>
                                    <span className={"text-20"}>{trans("car:horse")}</span>
                                </span>
                                <div className="spec-img">
                                        <img className=""   width={43} height={43} src="/assets/icons/car/engine.svg" alt="engine" />
                                </div>
                            </div>
                            <div>
                                <span className={"text-24 flex items-center flex-col"}>
                                    <span className={"text-24"}>{seats}</span>
                                    <span className={"text-20"}>{trans("car:seats")}</span>
                                </span>
                                <div className="spec-img">
                                        <img className=""    width={43} height={43} src="/assets/icons/car/seat.svg" alt="seat" />
                                </div>
                            </div>
                        </div>
                    }

                </div>



                {
                    Hybird && (<div className={"slide-info-tag"}>
                        <img  src={"data:image/png;base64," + hybridImage} className={" object-full object-contain"}
                            alt="hybrid" />
                    </div>)
                }






            </div>

        </div>
    );


}

export default Slide;