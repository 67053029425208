import { Fragment, useEffect, useState } from "react";
import SwiperCore, { Pagination, Navigation, Autoplay, EffectFade } from "swiper";
import { SwiperSlide, Swiper } from 'swiper/react';
import PageHeading from "../../../components/__simple/_pageheading";
import { FilterButton } from "../../../components/__simple/_filterButton";
import { useTranslation } from "react-i18next";
import Car from "../../../components/car";
import { slugSplitter, slugCombine } from '../../../utils/slugManager';
SwiperCore.use([Pagination, Navigation, Autoplay, EffectFade,]);

export default function HomeVehicleSection({ cars, brands }) {
    const { t: trans } = useTranslation(["common", "inputs", 'cars', "home", "info"]);

    const [filteredCars, setFilteredCars] = useState(cars);
    const [carFilter, setCarFilter] = useState(null);


    useEffect(() => {
        setFilteredCars(cars);
    }, [cars])


    function filterCars(brand) {
        setCarFilter(brand);
        let filterData = [];
        if (brand != null)
            filterData = cars.filter((car) => {
                return car.brand === brand;
            });
        else {
            filterData = cars;
        }
        setFilteredCars(filterData);
    };


    const [visibleRange, setVisibleRange] = useState({ start: 0, end: 4 }); // Render 4 slides at a time

    const handleSlideChange = (swiper) => {
        const activeIndex = swiper.activeIndex;
        const buffer = 2; // Number of slides before and after the active one to render
        setVisibleRange({
            start: 0,
            end: Math.min(filteredCars.length, activeIndex + buffer + 2) > visibleRange.end ? Math.min(filteredCars.length, activeIndex + buffer + 2):
            visibleRange.end ,
        });
    };
    return (

        <div className=" container mb-36 ">
            <PageHeading
                fixed={true}
                subtitle={trans("home:subtitle")}
                title={trans("home:titlePage")}
            >
                <div className="filter-btns">
                    {
                        brands?.map((brand, index) => {
                            return (
                                <FilterButton key={index} name={brand.name} isActive={carFilter === brand.name} onclick={() => filterCars(brand.name)} />
                            );
                        })
                    }

                    <FilterButton name={trans("inputs:all")} isActive={carFilter === null}
                        onclick={() => filterCars(null)} />
                </div>
            </PageHeading>
            <Swiper
                onSlideChange={handleSlideChange}
                //  className={"slide-car-360"}
                spaceBetween={0}
                slidesPerView={2}
                breakpoints={{
                    576: {
                        slidesPerView: 4,

                    },
                }}
                pagination
                navigation
                resizeObserver={"true"}
                watchSlidesVisibility
                watchSlidesProgress
                autoplay={false}
                loop={false}
                observer={true}

            >
                {
                    filteredCars.map((car, index) => {
                        // Render only slides within the visible range
                        if (index >= visibleRange.start && index < visibleRange.end) {

                                return (
                                    <SwiperSlide className={"px-3"} key={car.slug + "-" + index}>
                                        <Car
                                            key={car.slug + "-" + index}
                                            type={car.type}
                                            slug={car.slug}
                                            year={car.year}
                                            qty={car.qty}
                                            colorID={car.colorID}
                                            id={car.siteId}
                                            gifts={car.countGifts}
                                            discountPrice={car.discount}
                                            img={car.image}
                                            name={car.name}
                                            brand={car.brand}
                                            brandID={car.brandID}
                                            category={car.category}
                                            group={car.group}
                                            minPrice={parseFloat(car.minPrice)}
                                            brandIcon={car.brandIcon}
                                            hybirdImage={car.hybridImage}
                                            brandImage={car.brandImage}
                                            Tax={car.rateTaxCar}
                                            hybird={car.hybird}
                                            newCar={car.newCar}
                                            ProductId={car.productId}
                                            RateCareDiscount={car.rateCareDiscount}
                                            modelTypes={car.modelTypes}
                                            hybridImage={car.hybridImage}
                                            // link={"/vehicles/" + slugCombine(car.brand,car.otherBrand) + '/' + car.slug}
                                            link={"/vehicles/" + slugCombine(car.brand, car.otherBrand) + '/' + car.group + "/" + car.year}
                                            showPric={car.showPric}
                                            buyOnline={car?.buyOnline}
                                        />
                                    </SwiperSlide>
                                )
                        } else {
                            return <SwiperSlide className={"px-3"} key={car.slug + "-" + index} >  </SwiperSlide>;
                        }

                    })
                }
            </Swiper>

        </div>

    );
}