import {useTranslation} from "react-i18next";
import {Link,useHistory} from "react-router-dom";
import { InputField } from "../components/_input/_input";
import { FormButton } from "../components/_input/_button";
import { Fragment, useEffect , useState } from "react";
import axios from "axios";
import crypto from 'crypto-js';
import Joi from "joi";
import {GetMessages , formValidate,validateProperty} from '../utils/validation';
import { PhoneInput } from "../components/_input/_phoneInput";
import { toast } from "react-toastify";
import { CountDown } from "./components/countDown";
import * as db from '../utils/localDB';
import config from '../configuration/config.json';
export default function LoginValidation({onTabChange,onLogin}){
    const {t: trans} = useTranslation(["common","form","inputs","model"]);
    const tabsList = [{
        tab : 1 , title : 'sign-in'
    },{
        tab : 2 , title : 'sign-up'
    }, {
        tab : 3 , title : 'reset-password'
    }];
    const [requestForm,setRequestForm] = useState({
    validationKey:'',
    });
    const history = useHistory();
    const schema = Joi.object({
        validationKey:Joi.string().required().messages(GetMessages())
    });
    const [error,setError] = useState({});
    const handleRequest = async ()=>{
    const result = formValidate(schema,requestForm) ?? {};
    setError(result);
    try{
    if(Object.keys(result).length == 0){
    const secretKey = "secretKey";   
    const secretKeyValue = localStorage.getItem(secretKey); 
    if(secretKeyValue){
        db.createDbRequest("UserDB",1);
        axios.post(
            "/api/Administration/"+ "SignInValidate2",
            {
                "AccessType": "Global",
                "Access": String(secretKeyValue),
                "ValidationKey":requestForm.validationKey
            }
        ).then((response)=>{
            if(response.status == 200){
              const encodedData = crypto.AES.encrypt(JSON.stringify(response.data),config.cryptoKey).toString();
               localStorage.setItem("userData",JSON.stringify({ email : response.data.email , phone : response.data.phone , 
                id:response.data.id.toString() ,descriptionAr:response.data.descriptionAr , descriptionEn : response.data.descriptionEn
             , logo : response.data.logo}));
              localStorage.setItem("userId",response.data.id.split("_")[1]);
              localStorage.setItem("custId",response.data.custID);
              const status =  db.proccessUserData({uId:1,data:encodedData});
                 db.getUserDataByID(1).onsuccess = (event)=>{
                    const result =  event.target.result;
                    if(result){
                      localStorage.setItem("isAuthenticated",true); 
                      history.push('/user');
                      if(onLogin)
                         onLogin();
                    }
                  };  
            }
        }).catch((err)=>{
           if(err.response && err.response.status == 400){
               toast.error(trans("common:invaild-validation-key"));
           }
        });
    }
    }
    }catch(err){
        console.log(err);
    }
    };



    return (
        <div className='popup-content'>
            <p className='text-size-14 mb-3 border-section-right p-2 bg-light'>          
             {trans("common:reset-password-step-2-note")}
            </p>
                       <InputField
                        errorLabel = {error?.validationKey}
                        title={trans("form:validation-key")}
                        name='validationKey'
                        onChangeValue={
                            (e) => {
                                const val = { ...requestForm };
                                val.validationKey = e.target.value;
                                setRequestForm(val);
                            }
                        }
                    /> 
                 

            <FormButton isPrimary={true} classes={"px-5 py-2"} name={trans("form:next")} onClick={handleRequest}/>
            <Link className='text-size-14 mt-5 text-center text-block text-primary-dark' onClick={()=>onTabChange(tabsList[0])}>
             {trans("form:main-page")}       
            </Link>
         </div>    
    );}
