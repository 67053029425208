import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, EffectFade, Navigation, Pagination as SlidePagination } from "swiper";
import OfferSupportedCar from "../pages/offers/compontents/car";
import axios from 'axios';
import config from '../configuration/config.json';
import { Price } from "./__simple/_price";
import { toast } from "react-toastify";
import { getData, User_Data_Key } from "../utils/localStorageManager";
import CustomMoreServices from "./CustomMoreServices";
import Car from "./car";
import CustomItemServices from "./CustomItemServices";
import { slugCombine } from '../utils/slugManager';
import CarUser from "../User/components/CarUser";
SwiperCore.use([SlidePagination, Navigation, Autoplay, EffectFade]);


export function OfferDetail({ desc, terms, slug, supportedCars, offerId, SupportedServices, SupportedMainServices, SupportedLastsServices, packages, handelActiceCar, handleActicePackage, handelActiceService, acticePackage }) {
    const { t: trans, i18n } = useTranslation(["offers", "common", "error", "info", "extra", "global"]);
    const history = useHistory();
    let lang = (i18n.language || 'ar');
    const languageDir = 'language-' + lang;
    const [indexPanel, setIndexPanel] = useState("1000");
    const [id, setId] = useState(null);
    const [acticeCar, setActiceCar] = useState(null);
    // const [acticePackage, setActicePackage] = useState(null);
    const handleOrderRequest = async () => {


        const _isLogin = getData('isAuthenticated');
        if (_isLogin) {
            const userData = JSON.parse(getData(User_Data_Key, true));
            const userId = userData.id.toString().split('_')[1];
            const response = await axios.post(
                config.baseURL + "details",
                {
                    "Object": "SiteOfferReservation",
                    "ObjectSettings": {
                        "MetaData": false
                    },
                    "Filters": `where offerId = ${offerId}  and userId = ${userId}`
                });

            if (response.status == 200) {
                if (response.data.ApiObjectData.length != 0) {
                    toast.error(trans("common:request-repeated"));
                } else {
                    axios.post(
                        config.baseURL + "add",
                        {
                            "Object": "SiteOfferReservation",
                            "Values": {
                                "UserID": Number(userId),
                                "OfferID": offerId,
                                "DescriptionAr": userData.descriptionAr,
                                "DescriptionEn": userData.descriptionEn,
                                "Email": userData.email,
                                "Phone": userData.phone
                            }
                        }
                    ).then((response) => {
                        toast.success(trans("common:order-request-success"));
                    }).catch((err) => {
                        if (err.response && err.response.status == 400) {
                            toast.error(trans("error:unknown_failure"));
                        }
                    });
                }
            }
            return;
        }
        history.push("/offers/order/" + slug);
    };

    const OnhandleActicePackage = async (Package) => {

        if (handleActicePackage)
            handleActicePackage(Package);

    }
    return (
        <div className={"offer-detail  nice-shadow-0 " + languageDir}>

            <div className="offer-detail-info clearfix">

                {/* {
                    desc ?
                        <div className={"new-lines offer-detail-data"}>
                            <h3>{trans("offers:offer")}:</h3>
                            <p>
                                {desc}
                            </p>
                        </div>
                        : null
                } */}
                {/* <div className={"w-100 flex flex-wrap"}>


                <div className={" offer-detail-info-R"}>
                        {
                            (desc || packages && packages.length > 0) &&
                            <div className="panel-section panel-white bg-theme-up">
                                <div className={`panel-content   ${(indexPanel == "1000") ? "show-panel-info" : ""}`}>
                                    <div className="panel-content-top px-5"

                                        onClick={(e) => setIndexPanel(indexPanel == "1000" ? "000" : "1000")}

                                    >
                                        <h1 className="text-30   text-super-gray font-bold">{trans("offers:offer")}</h1>
                                        <img src="/assets/icons/arrow.svg" />
                                    </div>

                                    <div className="panel-content-body">
                                        <div className="mx-10 my-10">

                                            {
                                                desc ?
                                                    <div className={"new-lines offer-detail-data"}>
                                                        <ul>
                                                            {
                                                                desc?.split('\n')?.map((item, index) => {

                                                                    return <li className={"text-20 font-bold"} key={index}>
                                                                        {item}
                                                                    </li>
                                                                })
                                                            }
                                                        </ul>

                                                    </div>
                                                    : null
                                            }


                                            {
                                        packages && packages.length > 0 &&
                                        packages?.map((data, index) => {

                                            return (
                                                <div className={"offer-detail "} key={index}>
                                                    <div className={"offer-detail-name " + (data?.id == acticePackage?.id ? "active" : "")}

                                                        onClick={() => {

                                                            OnhandleActicePackage(data);
                                                            setActicePackage(data);
                                                        }}

                                                    >
                                                        <h3 className={"text-20 font-bold text-line-clamp text-line-clamp-2"}>{data?.title}</h3>
                                                        <div className="offer-detail-name-info">
                                                            <h5 className={"text-20 fond-bold text-primary px-4"}>
                                                                <Price price={data?.price} />
                                                            </h5>


                                                        </div>

                                                    </div>


                                                    {
                                                        data?.desc ?
                                                            <div className={"new-lines offer-detail-data"}>
                                                                <ul>
                                                                    {
                                                                        data?.desc?.split('\n')?.map((item, index) => {

                                                                            return <li className={"text-20 font-bold"} key={index}>
                                                                                {item}
                                                                            </li>
                                                                        })
                                                                    }
                                                                </ul>

                                                            </div>
                                                            : null
                                                    }

                                                </div>
                                            );
                                        })
                                    }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                    <div className={"offer-detail-info-L"}>

                        {
                            supportedCars != null && supportedCars.length > 0 ?

                                <>  <div className={"my-2 mt-2 flex nice-flex-center-items "}>

                                    <h1 className="text-20 line-top text-super-gray font-bold">{trans("offers:vehicles_supported")}</h1>
                                </div>

                                    <div className={"my-10  p-8 pb-20 border-radius"}>
                                        <Swiper
                                            spaceBetween={20}
                                            slidesPerView={2}
                                            breakpoints={{
                                                576: {
                                                    slidesPerView: 3,
                                                },
                                            }}
                                            pagination
                                            resizeObserver={"true"}

                                            watchSlidesVisibility
                                            watchSlidesProgress
                                            autoplay={false}
                                            loop={false}
                                            observer={true}

                                        >
                                            {
                                                supportedCars?.map((data, index) => {
                                                    return (
                                                        <SwiperSlide
                                                            key={index}
                                                        >
                                                            <CarUser
                                                                onclick={() => {

                                                                    setActiceCar(data.slug);
                                                                    if (handelActiceCar)
                                                                        handelActiceCar(data);
                                                                }}

                                                                withMoreLinke={false}
                                                                key={index + 5000}
                                                                // inputDate={data.InputDate}
                                                                showDetails={false}
                                                                active={data.slug == acticeCar}
                                                                id={data.slug}
                                                                img={data.image ? data.image : '/assets/images/eparts/default.png'}
                                                                name={(data?.modelTypes + " " + data?.year)}
                                                                brand={data.brand}

                                                            // link={"/user/vehicles/privateVehicles/" + data.CarID + "/" + data.FetchType}
                                                            />

                                                        </SwiperSlide>
                                                    );
                                                })
                                            }

                                        </Swiper>
                                    </div>
                                </>
                                : null
                        }
                    </div>
                 
                </div> */}





                {/* {
                    SupportedLastsServices != null && SupportedLastsServices.filter(a => a.packageId == acticePackage?.id).length > 0 &&
                    <div className="panel-section panel-white bg-theme-up">
                        <div className={`panel-content   ${(indexPanel == "3000") ? "show-panel-info" : ""}`}>
                            <div className="panel-content-top px-5"

                                onClick={(e) => setIndexPanel(indexPanel == "3000" ? "000" : "3000")}

                            >
                                <div className="flex">
                                    <h1 className="text-30   text-super-gray font-bold">{trans("offers:services_supported")}</h1>

                                    <div className="panel-content-top-tage ">
                                        <h2 className="mx-5 text-gray  text-30">{SupportedLastsServices?.length}</h2>

                                    </div>
                                </div>

                                <img src="/assets/icons/arrow.svg" />

                            </div>

                            <div className="panel-content-body">
                                <div className="mx-10 my-10">
                                    {
                                        <div className="more-services nice-apply-theme bg-secondary-lighter border-radius py-2">
                                            <div className="my-10 nice-apply-theme ">
                                                <h3 className="text-20 text-center nice-apply-theme font-bold"></h3>
                                                <Swiper
                                                    spaceBetween={5}
                                                    slidesPerView={2}
                                                    breakpoints={{
                                                        576: {
                                                            slidesPerView: 6,
                                                        },
                                                    }}
                                                    className={"mr-8 my-8  nice-apply-theme"}
                                                    resizeObserver={"true"}
                                                >

                                                    {SupportedLastsServices.filter(a => a.packageId == acticePackage?.id)?.map((_val, _index) => {
                                                        return (

                                                            <SwiperSlide key={_index} className={"px-3"}>
                                                                <div onClick={() => {
                                                                    // setId(_val.ServiceID);
                                                                    if (handelActiceService) {

                                                                        // handelActiceService(_val);
                                                                    }

                                                                }}>
                                                                    <CustomItemServices
                                                                        // title={trans("mobileCommon:finance")}
                                                                        title={lang == "ar" ? _val.ServiceNameAR : _val.ServiceName}
                                                                        borderCSS={_val.ServiceID == id ? "border-primary-box?? " : ""
                                                                        }
                                                                    />
                                                                </div>
                                                            </SwiperSlide>

                                                        );
                                                    })}



                                                </Swiper>
                                            </div>


                                        </div>


                                    }


                                </div>

                            </div>
                        </div>
                    </div>
                } */}

                {
                    terms &&
                    <div className="panel-section panel-white bg-theme-up">
                        <div className={`panel-content   ${(indexPanel == "2000") ? "show-panel-info" : ""}`}>
                            <div className="panel-content-top px-5"

                                onClick={(e) => setIndexPanel(indexPanel == "2000" ? "000" : "2000")}

                            >
                                <h1 className="text-30   text-super-gray font-bold">{trans("offers:terms")}</h1>
                                <img src="/assets/icons/arrow.svg" />
                            </div>

                            <div className="panel-content-body">
                                <div className="mx-10 my-10">

                                    {
                                        terms ?
                                            <div className={"new-lines offer-detail-data"}>
                                                <ul>
                                                    {
                                                        terms?.split('\n')?.map((item, index) => {

                                                            return (
                                                                item && item.trim().toString() &&
                                                                <li className={"text-20 font-bold"} key={index}>
                                                                    {item}
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>

                                            </div>
                                            : null
                                    }

                                </div>

                            </div>
                        </div>
                    </div>
                }




                {
                    // SupportedMainServices != null && SupportedMainServices.length > 0 ?
                    //     SupportedMainServices.map((ccItem, ccIndex) => {
                    //         return (

                    //             ccItem?.subService2GUID &&
                    //             <div className=" my-10 " key={ccIndex}>
                    //                 <div className=" my-10">
                    //                     <div className="mx-5">
                    //                         <div
                    //                             className=" flex-inline-block ">
                    //                             <h3 className="text-40 font-bold text-black">
                    //                                 {lang == "ar" ? ccItem?.DescriptionAr : ccItem?.DescriptionEn}
                    //                             </h3>
                    //                         </div>
                    //                     </div>
                    //                 </div>

                    //                 <div className="sizedBox-mines-8-top">
                    //                     <CustomMoreServices
                    //                         mainServiceID={ccItem.subService2GUID}

                    //                         selectItem={(serviceId) => {

                    //                             setId(serviceId);


                    //                         }}

                    //                         activeID={id}
                    //                     /></div>

                    //             </div>

                    //         )
                    //     })
                    //     : null
                }


                {/* <Link onClick={handleOrderRequest} className={"btn btn-primary text-20 py-4"}>{trans("extra:order")}</Link> */}
            </div>
        </div >
    );
}
