import { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactWhatsapp from 'react-whatsapp';
const CustomerServices = ({ whatsupNumber = '+967772630622', phonCallNumber = '+967772630622', welcomeTitle = null, fullName = '', isOnline = false, data = null, image }) => {
    const { t: trans, i18n } = useTranslation(["info", "popular"]);
    const [showPopup, setShowPopup] = useState(false);
    const languageDir = 'language-' + i18n?.language;
    return (
        <div className={"nice-services " + languageDir}>
            <div
                className="nice-services-box nice-flex nice-flex-row nice-flex-center nice-pointer"
                style={{ width: (showPopup) ? '30rem' : ' ' }}
                onClick={() => {
                    setShowPopup(!showPopup);
                }}
            >
                <img src="/assets/icons/customer-support.svg" width={150} height={150} className=" filter-white nice-services-icon text-12" alt="hassan jameel customer supports" />
                <div className="text-white text-16" style={{ display: (showPopup) ? 'block' : ' none' }}>
                    {trans("popular:how_can_help")}
                </div>
            </div>
            <div className="nice-services-popup" style={{ bottom: (showPopup) ? '8rem' : '-50rem' }}>
                {/* <div className="nice-services-popup-header p-3 nice-flex nice-flex-center nice-flex-column">
                    {

                        i18n?.language == "ar" ?
                            <img src="/assets/logo/logo_AR.png" alt="hassan jameel logo" />
                            :
                            <img src="/assets/logo/logo_EN.png" alt="hassan jameel logo" />

                    }

                
                </div> */}
                <div className="p-4 nice-flex nice-flex-row nice-flex-space-center">
                    <div className="nice-flex nice-flex-column nice-flex-space-between">
                        <span className="text-black text-16">{trans("popular:client_help")}</span>
                        <div className="mt-3">
                            <h2 className="text-gray text-14 text-inline-block">{fullName}</h2>
                            <span className={`text-${isOnline ? 'primary' : 'toyota'} text-14 mx-3`}>{isOnline ? 'Online' : 'Offline'}</span>
                        </div>
                        <div className="nice-flex nice-flex-row mt-4">
                            <div
                                className="contact-box contact-box-whatsup"
                                onClick={(event) => {
                                    event.stopPropagation();
                                }}
                            >
                                <ReactWhatsapp
                                    number={whatsupNumber}
                                    message={welcomeTitle ? welcomeTitle : ""}
                                >
                                    <img className={""} width={44} height={44} src='/assets/icons/social/whatsup.svg' alt="what's up icon" />
                                </ReactWhatsapp>
                            </div>
                            <a href={`tel:${phonCallNumber}`} className="contact-box contact-box-call "
                                onClick={(event) => {
                                    event.stopPropagation();
                                }}>
                                <img className={""}  width={44} height={44} src='/assets/icons/phone.svg' alt="call icon" />
                            </a>
                        </div>
                    </div>
                    <div  style={image && { backgroundImage: `url("${(image).replaceAll("\\","/")}")` }} className='lazyload nice-services-logo'>
                    </div>
                </div>
            </div>
        </div>
    )
};
export default CustomerServices;