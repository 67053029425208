import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { InputField } from "../components/_input/_input";
import { FormButton } from "../components/_input/_button";
import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import config from '../configuration/config.json';
import crypto from 'crypto-js';
import Joi from "joi";
import { GetMessages, formValidate, validateProperty } from '../utils/validation';
import { toast } from "react-toastify";
import { CountDown } from "./components/countDown";
import PhoneDropDown from "../components/_input/_phoneDropDown";
export default function ResetPassword({ onTabChange }) {
    const { t: trans,i18n } = useTranslation(["common", "form", "inputs", "model", "error2", "finance"]);
    let lang = (i18n.language || 'ar');
    const languageDir = 'language-' + lang;
    const tabsList = [{
        tab: 1, title: 'sign-in'
    }, {
        tab: 2, title: 'sign-up'
    }, {
        tab: 3, title: 'reset-password'
    }];
    const [activeStep, setActiveStep] = useState(0);
    const [requestForm, setRequestForm] = useState({
        phoneNumber: '',
        identityNo: '',
        validationKey: '',
        password: '',
        confirmPassword: ''
    });
    const [ticker, setTicker] = useState(1);
    const [clicked, setClicked] = useState(false);
    const notes = [
        trans("common:reset-password-step-1-note"),
        trans("info:reset-password-step-2-note"),
        trans("common:reset-password-step-2-note"),
        trans("common:reset-password-step-3-note"),
    ];
    const [identityType, setIdentityType] = useState(null);

    const schema = Joi.object({
        validationKey: Joi.string().required().messages(GetMessages()),
        phoneNumber: Joi.string().min(13).required().messages(GetMessages()),
        identityNo: Joi.string().length(10).required().messages(GetMessages()),
        password: Joi.string().required().messages(GetMessages()),
        confirmPassword: Joi.string().valid(Joi.ref('password')).required().messages(GetMessages())
    });
    const [error, setError] = useState({});
    const [passwordStrength, setPasswordStrength] = useState(false);
    const handleSteps = () => {
        const result = formValidate(schema, requestForm);
        setError(result);
        switch (activeStep) {
            case 0:
                if (!result?.phoneNumber) {
                    axios.post(
                        config.baseURL + 'func',
                        {
                            "Name": "SiteCheckPhoneBeforeResetPassword",
                            "Values": {
                                "phone": requestForm.phoneNumber
                            }
                        }
                    ).then(response => {
                        if (response.data.length > 0) {
                            const { NeedIdentity } = { ...response.data[0] };
                            setActiveStep(activeStep + 1);
                            if (NeedIdentity) {
                                setIdentityType(1);
                            } else {
                                setIdentityType(0);
                            }
                        }
                    }).catch(error => {
                        toast.error(trans("common:invaild-phonenumber"));
                    });
                }
                break;

            case 1:
                if (!result?.identityNo) {
                    axios.post(
                        config.baseURL + 'func',
                        {
                            "Name": "SiteCheckMatchedPhoneWithIdentity",
                            "Values": {
                                "phone": requestForm.phoneNumber,
                                "Identity": requestForm.identityNo,
                                "Type": identityType
                            }
                        }
                    ).then(response => {
                        if (response.data == true) {
                            axios.post(
                                "/api/Administration/" + "SendSms",
                                {
                                    "AccessType": "PhoneNumber",
                                    "Access": requestForm.phoneNumber
                                }
                            ).then(response => {
                                if (response.status) {
                                    if (response.data) {
                                        setActiveStep(activeStep + 1);
                                        setError({});
                                    }
                                }
                            }).catch(error => {
                                toast.error(trans("error2:unknown_identity_or_custType_not"));
                            });
                        } else {
                            toast.error(trans("error2:not_matched_identity_with_phone"))
                        }
                    }
                    ).catch(err => {
                        toast.error(trans("error2:not_matched_identity_with_phone"))
                    });
                }
                break;
            case 2:
                if (!result?.validationKey) {
                    axios.post(
                        config.controllURL + "/api/Administration/" + "MatchedKeys",
                        {
                            "AccessType": "PhoneNumber",
                            "Access": requestForm.phoneNumber,
                            "ValidationKey": requestForm.validationKey
                        }
                    ).then(response => {
                        if (response.status == 200) {
                            setActiveStep(activeStep + 1);
                            setError({});
                        }
                    }).catch(error => {
                        toast.error(trans("common:invaild-validation-key"));
                    });
                }
                break;
            case 3:
                if (!result?.password && !result?.confirmPassword) {
                    if (passwordStrength) {
                        axios.post(
                            config.controllURL + "/api/Administration/" + "ForgetPassword",
                            {
                                "AccessType": "PhoneNumber",
                                "Access": requestForm.phoneNumber,
                                "ValidationKey": requestForm.validationKey,
                                "Password": crypto.MD5(requestForm.password).toString()
                            }
                        ).then(response => {
                            if (response.status == 200) {
                                if (response.data) {
                                    toast.success(trans("common:reset-password-successfully"));
                                    setError({});
                                    setActiveStep(0);
                                    setTicker(1);
                                    setIdentityType(null);
                                    setRequestForm({});
                                    if (onTabChange)
                                        onTabChange(tabsList[0]);
                                }
                            }
                        }).catch(error => {
                            toast.error(trans("common:invaild-validation-key"));
                        });
                    }
                }
                break;
        }
    };
    const handleResendSms = () => {
        axios.post(
            config.controllURL + "/api/Administration/" + "SendSms",
            {
                "AccessType": "PhoneNumber",
                "Access": requestForm.phoneNumber
            }
        ).then(response => {
            if (response.status == 200) {
                if (response.data) {
                    toast.success(trans("common:validation-key-sent"));
                }
            }
        }).catch(error => {
            toast.error(trans("common:invaild-phonenumber"));
        });
    };
    return (
        <div className={'popup-content  ' + languageDir}>
            <p className={`text-block text-20 text-center text-bold my-10  border-section-${lang == 'en' ? 'left' : 'right'} p-2 `}>
                {notes[activeStep] + ((activeStep == 1) ? identityType == true ? trans("form:identity-no") : identityType == false ? trans("finance:cn") : '' : '')}
            </p>

            <div className="flex flex-col w-100">
                <Fragment>
                    {activeStep == 0 && (
                        <>
                            <PhoneDropDown
                                errorLabel={error?.phoneNumber}
                                onChangeValue={(countryCode, phoneNumber) => {
                                    const val = { ...requestForm };
                                    val.phoneNumber = `+${countryCode}${phoneNumber}`;
                                    setRequestForm(val);
                                }}
                            />
                        </>
                    )
                    }
                </Fragment>
                <Fragment>
                    {activeStep == 1 && (<InputField
                        length={10}
                        type="number"
                        errorLabel={error?.identityNo}
                        value={requestForm.identityNo}
                        onChangeValue={(e) => {
                            const val = { ...requestForm };
                            val.identityNo = e.target.value;
                            setRequestForm(val);
                        }}
                        placeholder={identityType == 1 ? trans("form:identity-no") : trans("finance:cn")}
                    />)
                    }
                </Fragment>
                <Fragment>
                    {activeStep == 2 &&
                        <Fragment>
                            <InputField
                                errorLabel={error?.validationKey}
                                placeholder={trans("form:validation-key")}
                                name='validationKey'
                                onChangeValue={
                                    (e) => {
                                        const val = { ...requestForm };
                                        val.validationKey = e.target.value;
                                        setRequestForm(val);
                                    }
                                }
                            />
                            <div className='mb-4'>
                                <span className='text-14 text-inline-block mx-2'>
                                    {
                                        trans("common:countdown-note")
                                    }
                                </span>
                                <CountDown contM={ticker} onFinish={() => {
                                    setClicked(true);
                                }} />
                                {clicked && <Link className='text-inline-block text-14 mx-4 text-primary' onClick={
                                    () => {
                                        setTicker(ticker + 3);
                                        setClicked(false);
                                        handleResendSms();
                                    }
                                }>
                                    {
                                        trans("common:resend")
                                    }
                                </Link>
                                }
                            </div>
                        </Fragment>
                    }
                </Fragment>
                <Fragment>
                    {activeStep == 3 && <Fragment>
                        <InputField
                            type='password'
                            onAcceptPassword={(e) => {
                                setPasswordStrength(e);
                            }}
                            length={20}
                            errorLabel={error?.password}
                            placeholder={trans("form:password")}
                            name='password'
                            passwordStrength={true}
                            onChangeValue={
                                (e) => {
                                    const val = { ...requestForm };
                                    val.password = e.target.value;
                                    setRequestForm(val);
                                }
                            }
                        />
                        <InputField
                            length={20}
                            type='password'
                            errorLabel={error?.confirmPassword}
                            placeholder={trans("form:confirm-password")}
                            name='confirmPassword'
                            onChangeValue={
                                (e) => {
                                    const val = { ...requestForm };
                                    val.confirmPassword = e.target.value;
                                    setRequestForm(val);
                                }
                            }
                        />
                    </Fragment>
                    }
                </Fragment>
                <div className={"mt-5"} >
                    <FormButton isPrimary={true} classes={"px-5 py-2"} name={trans("form:next")} onClick={() => handleSteps()} />
                </div>

                <Link className='text-size-14 mt-5 text-center text-block text-white' onClick={() => onTabChange(tabsList[0])}>
                    {trans("form:main-page")}
                </Link>
            </div>
        </div>
    );
}
