import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Price } from "../../../components/__simple/_price";

export function FinanceCar({
    groupColor,
    onApplay,
    offerID,
    productID,
    image,
    modelType,
    name,
    hasOffer,
    brand,
    brandImage,
    brandIcon,
    offerTitle,
    group,
    model,
    brandID,
    price,
    showPric,
    colorName = ""
}) {
    const { t: trans,i18n } = useTranslation(["finance", "model", "common", "info"]);
    let lang =(i18n.language || 'ar');
    const languageDir = "language-" + lang;
    const isToyota = brand && brand.toLowerCase() == trans("common:toyota").toLowerCase() ? true : false;
    return (

        <div className={"finance-car-card border-radius"}>
            <div className={"finance-car-card-type " + (isToyota || "lexus")}>
                <div className={"finance-car-card-type-brand " + (isToyota || "bg-lexus")}>
                    {/* <img className={"filter-white pt-1"}
                        data-src={brandIcon} alt="toyota" /> */}
                    <img className={"lazyload pt-1"}
                        data-src={`/assets/icons/brands/${(isToyota ? "toyota" : "lexus")}.webp`} />
                    {/* <h4 className={"text-white text-12 pb-1"}>{(isToyota ? trans("common:toyota") : trans("common:toyota"))}</h4> */}
                </div>
            </div>

            <div className="car-img">

                <img className={"lazyload"} data-src={image ? image : '/assets/images/car-finance.png'} alt={name} />

                <div className="car-img-bg">
                    {/* <img className={"object-full"} data-src={brandImage}
                            alt={(isToyota ? "toyota" : "lexus")} /> */}
                    <img className="lazyload"
                        data-src={`/assets/icons/brands/${(isToyota ? "toyota-light" : "lexus")}.webp`} />
                </div>

                < div className={"car-card-top-btn"}>
                    {colorName && <div className={"car-card-top-btn-tags flex flex-row"}>

                        {/* <marquee direction="right" scrollamount="10" scrolldelay="200"> This text will bounce </marquee> */}
                        {(colorName) && <span className={"tag bg-white text-line-clamp text-line-clamp-2 text-center"}>{colorName}</span>}

                    </div>}
                </div>
            </div>

            <div className={"finance-car-card-info border-radius box-shadow finance " + languageDir}>
                <div className="finance-car-card-info-name language-en">
                    <h3 className={"text-14 font-bold  text-line-clamp text-line-clamp-2"} title={name}>{name}</h3>
                    {/* {
                        hasOffer ? <div className={"finance-car-card-info-tag " + languageDir + '-pos-dir'}>
                            <img className={"filter-white object-contain object-full"}
                                data-src="/assets/icons/services/fin.svg" alt="" title={offerTitle} />
                        </div> : null
                    } */}
                </div>
                <div className="w-full space-y-3">
                    {/* <div className="flex-list">
                        <h3 className={"text-gray-dark"}>{trans("finance:group")}</h3>
                        <p className={"text-primary-dark font-bold"}>{group}</p>
                    </div> */}
                    <div className="flex-list">
                        <h3 className={"text-gray-dark"}>{trans("info:vehicle_year")}</h3>
                        <p className={"text-gray-dark"}>{model}</p>
                    </div>
                    <div className="flex-list">
                        {
                            showPric && <>
                                <h3 className={"text-gray-dark"}>{trans("finance:price")}</h3>
                                <p className={"text-primary-dark"}>{showPric && <Price price={price} />}</p>
                            </>

                        }

                    </div>

                </div>
                <div className='my-3 '>
                    <span className='finance-apply-btn bg-black text-14' onClick={
                        () => {
                            if (onApplay) {
                                onApplay({
                                    productID,
                                    offerID,
                                    name,
                                    price,
                                    group,
                                    model,
                                    brand,
                                    brandID,
                                    image,
                                    brandIcon,
                                    brandImage,
                                    modelType,
                                    groupColor,
                                    showPric
                                });
                            }
                        }
                    }>
                        {
                            trans("finance:apply")
                        }
                    </span>
                </div>
            </div>
        </div>

    );
}
