import {Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Banner from "../../components/banner";
import {InputField} from "../../components/_input/_input";
import {TextArea} from "../../components/_input/_textArea";
import {DropDownInput} from "../../components/_input/_dropdown";
import {FormButton} from "../../components/_input/_button";
import Breadcrumb from "../../components/__simple/breadcrumb";
import {DateInput} from "../../components/_input/_dateInput";
import {CheckInput} from "../../components/_input/_checkInput";
import axios from "axios";
import { useParams} from "react-router";
import {PhoneInput} from "../../components/_input/_phoneInput";
import Joi from 'joi';
import {GetMessages , formValidate} from '../../utils/validation';
import config from '../../configuration/config.json';
import { toast } from "react-toastify";

export function MaintenanceOrderPage() {
    const {t: trans,i18n} = useTranslation(["heading", "maintenance", "form","inputs","eparts"]);
    const [requestForm, setRequestForm] = useState({
        name: '',
        address: '',
        phoneNumber: '',
        email: '',
        message: '',
        selectedBrand: '',
        selectedGroup: '',
        selectedType: '',
        selectedBranch: '',
        selectedOffer:'',
        maintenanceDate: ''
    });
    const [error, setError] = useState({});
    const [selectedPrivacy , setSelectedPrivacy] = useState(false);
    const schema = Joi.object({
        name:Joi.string().required().messages(GetMessages()),
        address:Joi.string().required().messages(GetMessages()),
        email:Joi.string().email({minDomainSegments: 2, tlds: { allow: false}}).required().messages(GetMessages()),
        phoneNumber:Joi.string().length(13).required().messages(GetMessages()),
        message:Joi.string().required().messages(GetMessages()),
        selectedBrand:Joi.string().required().messages(GetMessages()),
        selectedGroup:Joi.string().required().messages(GetMessages()),
        selectedType:Joi.string().required().messages(GetMessages()),
        selectedBranch:Joi.string().required().messages(GetMessages()),
        maintenanceDate:Joi.string().required().messages(GetMessages()),
    });

    let {service} = useParams();

    let lang = (i18n.language || 'ar');
    const languageDir = 'language-' + lang;
    const [serviceData, setServiceData] = useState({});
    const [locations, setLocations] = useState([{text: "", value: ""}]);
    const [offers, setOffers] = useState([{text: "", value: ""}]);
    
    const submitRequest = async () => {
        const result = formValidate(schema, requestForm)??{};
        setError(result);
        if(selectedPrivacy){
       try {   
            if (Object.keys(result).length === 0) {
             const response = await axios.post(
                config.baseURL + 'add',
                {
                    "object":"siteCarMaintenanceRequests",
                    "values":{
                     "name": requestForm.name,
                     "address":requestForm.address,
                     "phoneNumber":requestForm.phoneNumber,
                     "email":requestForm.email,
                     "message":requestForm.message,
                     "brandId":requestForm.selectedBrand,
                     "groupId":requestForm.selectedGroup,
                     "typeId":requestForm.selectedType,
                     "branchId":requestForm.selectedBranch,
                     "categoryId":parseInt(serviceData.categoryID),
                     "serviceId":parseInt(serviceData.serviceID),
                     "offerId": (requestForm.selectedOffer != '' ? Number(requestForm.selectedOffer) : null), 
                     "created_at":requestForm.maintenanceDate
                    }
                }
             );
             console.log('response' , response);
             if(response.status === 200 || response.status === 201){
                const defaultValue = {...requestForm}; 
                 setRequestForm({
                    name: '',
                    address: '',
                    phoneNumber: defaultValue.phoneNumber,
                    email: '',
                    message: '',
                    selectedBrand: defaultValue.selectedBrand,
                    selectedGroup: defaultValue.selectedGroup,
                    selectedType: defaultValue.selectedType,
                    selectedBranch: defaultValue.selectedBranch,
                    selectedOffer:defaultValue.selectedOffer,
                    maintenanceDate: ''
                });
                setError({});
                toast.success(trans("eparts:success-sent"));
                //alert(trans("employment:success_employment_success"));
             }
            }
        } catch (e) {
           console.log(e);
        }
        }else{
         toast.error(trans("common:not-agree"));
        }  
    }

///starting_code_hamza
const [Brands,setBrands]=useState([]);
const [ProductGroups,setProductGroups]=useState([]);
const [ProductModel,setProductModel]=useState([]);
const [ProductModelTypes,setProductModelTypes]=useState([]);
const [brand, setBrand] = useState("0");
const [group, setGroup] = useState( "0");


  //values for filter dropdown
  const [FProductGroups,setFProductGroups]=useState([]);
  const [FProductModelTypes,setFProductModelTypes]=useState([]);


function filterModel(option)
{
    setBrand(option.value);
    setFProductGroups(ProductGroups.filter(a=>a.BrandID===option.value));
    console.log(FProductGroups);
} 
function filterModelType(option)
{
    setGroup(option.value);
    setFProductModelTypes(ProductModelTypes.filter(a=>a.ProductGroupID===option.value));
    console.log(FProductModelTypes);
} 
const GetUrl= config.baseURL + 'details';
async function SendRequset()
{
    
    const  BodyBrands={
    "object":"InvBrands",
    "ObjectSettings":{
        "MetaData":false
    }
    ,"option":"column",
    "Fields":"BrandID,DescriptionAr,DescriptionEn"

};
    const  requestBrands=   axios.request({
        url: GetUrl,
        data: BodyBrands, method:"POST",responseType:"json"
    });
    
    const  BodyProductGroups={
        "object":"InvProductGroups",
        "ObjectSettings":{
            "MetaData":false,
        },
        "Option":"Column",
        "Fields":"DescriptionAr,DescriptionEn,ProductGroupID,BrandID"
    };
    const  requestProductGroups=axios.request({
        url: GetUrl,
        data: BodyProductGroups, method:"POST",responseType:"json"
    });
    
    const BodyProductModel={
        "object":"InvProductModel",
        "ObjectSettings":{
            "MetaData":false
        }
    };
    const requestProductModel=axios.request({
        url: GetUrl,
        data: BodyProductModel, method:"POST",responseType:"json"
    });
    
    const BodyProductModelTypes={
       "object":"InvProductModelTypes",
       "ObjectSettings":{
           "MetaData":false
       }
   };
    const requestProductModelTypes=axios.request({
    url: GetUrl,
    data: BodyProductModelTypes, method:"POST",responseType:"json"
});
    
 
 
    await axios.all([requestBrands,requestProductGroups,requestProductModel,requestProductModelTypes]).then(axios.spread(function(Brands, ProductGroups, ProductModel, ProductModelTypes) {
 
         setTimeout(setBrands(Brands.data.ApiObjectData),500);
         setTimeout(setProductGroups(ProductGroups.data.ApiObjectData),500);
         setTimeout(setProductModel(ProductModel.data.ApiObjectData),500);
         setTimeout(setProductModelTypes(ProductModelTypes.data.ApiObjectData),500);

})).catch(function (error) {
         console.log(error);
     });
    
}

///Finsh_code_hamza
    useEffect(async () => {
        try {
            SendRequset();
            const response = await axios.request({
                 baseURL: config.controllURL,
                url: lang + "/api/maintenance/orderPage/" + service,
                method: "get",
            });

            setServiceData(response.data.service);
            setLocations(response.data.locations);
            setOffers(response.data.offers)
            // setJobs(response.data.employments);
            // setJobDepartments(response.data.jobDepartments);
            // setJobBranches(response.data.jobBranches);

        } catch (error) {

        }
    }, [service]);


    return (
        <Fragment>

            <Banner
                subtitle={serviceData.categoryTitle}
                title={serviceData.serviceTitle}
                desc={serviceData.serviceExcerpt}
                background={serviceData.imagePath}
                backOpasity={serviceData?.backOpasity}
            />
            <div className="container">
                <Breadcrumb className={"mt-16 "}
                            links={[
                                {
                                    name: trans("maintenance:maintenance"),
                                    to: "/maintenance"
                                },
                                {
                                    name: serviceData.categoryTitle,
                                    to: "/maintenance/service/" + serviceData.categoryGUID
                                },
                                {
                                    name: serviceData.serviceTitle,
                                    to: "/maintenance/service/detail/" + serviceData.serviceGUID
                                },
                                {
                                    name: trans("maintenance:order-maintenance"),
                                },
                            ]}
                />
            </div>

            <div className={"container mb-32 " + languageDir}>

                <div className={"grid-2-cols  gap-10 mb-10"}>
                    <div className={"inputs-group flex-grow-1"}>
                        <div>
                            <div className="job-heading">
                                {trans("maintenance:explanation")}
                            </div>
                            <p className={"text-16-children"}>
                                <div dangerouslySetInnerHTML={{__html: serviceData.serviceContent}}/>

                            </p>
                        </div>
                    </div>
                    <div className={"inputs-group flex-grow-1"}>
                        <h2 className="text-20 font-bold mb-6">
                            {trans("form:personal-info")}
                        </h2>
                        <InputField
                            value={requestForm.name}
                            title={trans("form:name")}
                            name='name'
                            errorLabel={error?.name}
                            onChangeValue={
                                (e) => {
                                    const val = {...requestForm};
                                    val.name = e.target.value;
                                    setRequestForm(val);
                                }
                            }
                        />
                        <InputField
                            value={requestForm.address}
                            title={trans("form:address")}
                            name='address'
                            errorLabel={error?.address}
                            onChangeValue={
                                (e) => {
                                    const val = {...requestForm};
                                    val.address = e.target.value;
                                    setRequestForm(val);
                                }
                            }
                        />
                        <PhoneInput
                            value={requestForm.phoneNumber}
                            title={trans("form:phone")}
                            name='phoneNumber'
                            errorLabel={error?.phoneNumber}
                            onChangeValue={
                                (e) => {
                                    const val = {...requestForm};
                                    val.phoneNumber = e;
                                    setRequestForm(val);
                                }
                            }
                        />
                        <InputField
                           value={requestForm.email}
                            title={trans("form:email")}
                            name='email'
                            errorLabel={error?.email}
                            onChangeValue={
                                (e) => {
                                    const val = {...requestForm};
                                    val.email = e.target.value;
                                    setRequestForm(val);
                                }
                            }
                        />
                        <TextArea
                            value={requestForm.message}
                            title={trans("form:message")}
                            name='message'
                            errorLabel={error?.message}
                            onChangeValue={
                                (e) => {
                                    const val = {...requestForm};
                                    val.message = e;
                                    setRequestForm(val);
                                }
                            }
                        />
                        <div className="text-20 font-bold my-6">
                            {trans("form:vehicle-info")}
                        </div>
                        <DropDownInput
                            errorLabel ={error?.selectedBrand}
                            className={"flex-grow-1"}
                            title={trans("form:brand")}
                            placeholder={trans("form:brand")}
                            onfilter={filterModel}
                            options={[{value: "0", text:trans("inputs:all")},
                            ...Brands.map(B=> {
                                if (lang==="ar")
                                {
                                    return {value: B.BrandID, text:B.DescriptionAr};
                                }else {
                                    return  {value: B.BrandID, text:B.DescriptionEn}; 
                                }
                   
                        })]}
                        onSelect={
                            (e)=>{
                                const val = {...requestForm};
                                val.selectedBrand= String(e);    
                                setRequestForm(val); 
                            }
                        }
                        />

                        <DropDownInput
                            errorLabel ={error?.selectedGroup}
                            className={"flex-grow-1"}
                            title={trans("form:vehicle-type")}
                            placeholder={trans("form:vehicle-type")}

                            options={[{value: "0", text: trans("inputs:all")},
                            ...FProductGroups.map(B=> {

                                if (lang==="ar")
                                {
                                    return {value: B.ProductGroupID, text:B.DescriptionAr};
                                }else {
                                    return  {value: B.ProductGroupID, text:B.DescriptionEn};
                                }
                                 
                            })]}
                        onfilter={filterModelType}
                        onSelect={
                            (e)=>{
                                const val = {...requestForm};
                                val.selectedGroup= String(e);    
                                setRequestForm(val); 
                            }
                        }
                        />

                        <DropDownInput
                            errorLabel ={error?.selectedType}
                            className={"flex-grow-1"}
                            title={trans("form:vehicle-model")}
                            placeholder={trans("form:vehicle-model")}
                            options={[{value: "0", text:trans("inputs:all") },
                            ...FProductModelTypes.map(B=> {

                                if (lang==="ar")
                                {
                                    return {value: B.ProductTypeID, text:B.DescriptionAr};
                                }else {
                                    return  {value: B.ProductTypeID, text:B.DescriptionEn};
                                }
                              
                            })]}
                            onSelect={
                                (e)=>{
                                    const val = {...requestForm};
                                    val.selectedType= String(e);    
                                    setRequestForm(val); 
                                }
                            }
                        />


                        <div className="text-20 font-bold my-6">{trans("form:maintenance-info")}</div>
                        <DropDownInput
                            name = "selectedBranch"
                            className={"flex-grow-1"}
                            title={trans("form:maintenance-branch")}
                            placeholder={trans("form:maintenance-branch")}
                            options={[
                                {
                                    text: trans("form:maintenance-branch-select"),
                                    value: ""
                                },
                                ...locations.map((job) => {
                                    return {
                                        text: job.content,
                                        value: job.name
                                    }
                                })
                            ]}
                            onSelect={
                                (e)=>{
                                    const val = {...requestForm};
                                    val.selectedBranch= String(e);    
                                    setRequestForm(val); 
                                }
                            }
                            errorLabel = {error?.selectedBranch}
                        />

                        {/*  <DropDownInput
                            className={"flex-grow-1"}
                            title={"فئة الصيانة*"}
                            placeholder={"فئة الصيانة"}
                        />

                        <DropDownInput
                            className={"flex-grow-1"}
                            title={"نوع الصيانة*"}
                            placeholder={"نوع الصيانة"}
                        />*/}
                        <DateInput
                            value={requestForm.maintenanceDate}
                            onSelect={
                                (e) => {
                                    const val = {...requestForm};
                                    val.maintenanceDate = e.target.value;
                                    setRequestForm(val);
                                }
                            }
                            name='maintenanceDate'
                            title={trans("form:maintenance-date")}
                            errorLabel={error?.maintenanceDate}/>
                        <div className="text-20 font-bold my-6">{trans("form:maintenance-available-offers")}</div>

                        <DropDownInput
                            name = "selectedOffer"
                            className={"flex-grow-1"}
                            title={trans("form:maintenance-offers")}
                            placeholder={trans("form:maintenance-offers")}
                            options={[
                                {
                                    text: trans("form:maintenance-available-offers"),
                                    value: ""
                                },
                                ...offers.map((offer) => {
                                    return {
                                        text: offer.content,
                                        value: offer.name
                                    }
                                })
                            ]}
                            onSelect={(e)=>{
                                const val = {...requestForm};
                                val.selectedOffer= String(e);   
                                setRequestForm(val); 
                            }}
                            errorLabel = {error.selectedOffer}
                        />

                        <CheckInput oncheng={
                            (e)=>{
                              setSelectedPrivacy(e.checked);
                            }
                        }>
                         <div className={"flex"} dangerouslySetInnerHTML={{__html: trans("form:agree_terms")}}/>

                        </CheckInput>

                        <FormButton
                        isPrimary={true} classes={"px-5 py-2"}
                            name={trans("form:send-order")}
                            onClick = {submitRequest}
                        />

                    </div>
                </div>


            </div>
        </Fragment>
    );
}