import React, { Fragment, useEffect, useState, useCallback } from "react";
import SwiperCore, { Pagination, Navigation, Autoplay, EffectFade } from "swiper";
import { useTranslation } from "react-i18next";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { InputField } from "../../components/_input/_input";
import { DateInput2 as DateInput } from "../../components/_input/_dateInput_2";
import { RadioInput } from "../../components/_input/_radioInput";
import { RadioInputDynamic } from "../../components/_input/_radioInputDynamic";
import { DropDownInput } from "../../components/_input/_dropdown";
import { FormButton } from "../../components/_input/_button";
import Breadcrumb from "../../components/__simple/breadcrumb";
import { toast } from 'react-toastify';
import axios from "axios";
import { TextArea } from "../../components/_input/_textArea";
import Popup from "../../User/popup";
import Joi from "joi";
import moment from "moment";
import { GetMessages, formValidate, } from '../../utils/validation';
import config from '../../configuration/config.json';
import PhoneDropDown from "../../components/_input/_phoneDropDown";
import { reverse } from "lodash";
SwiperCore.use([Pagination, Navigation, Autoplay, EffectFade]);

export function EmploymentApplicationFormPage() {
    const { id } = useParams();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const { t: trans, i18n } = useTranslation(["common", "employment", "heading", "inputs", "model", "new"]);
    const [showFinalPopup, setShowFinalPopup] = useState(false);
    const [showFinalPopupMessage, setShowFinalPopupMessage] = useState("");
    const [requestForm, setRequestForm] = useState({ selectedNationality: "SAU" });
    const [skillsList, setSkillsList] = useState([{}]);
    const [certificationList, setCertificationList] = useState([{}]);
    const [statusList, setStatusList] = useState({});
    const [phoneNumberApplicants, setPhoneNumberApplicants] = useState([]);
    const [reject, setReject] = useState(true);
    const history = useHistory();
    const formatDate = { year: 'numeric', month: '2-digit', day: '2-digit', weekday: "long", hour: '2-digit', hour12: true, minute: '2-digit' };
    const schema_Reject = Joi.object({
        note: Joi.string().required().messages(GetMessages()),
        phoneNumber: Joi.string().min(12).required().messages(GetMessages()),
    });
    const schema_1 = Joi.object({
        name: Joi.string().required().messages(GetMessages()),
        email: Joi.string().email({ minDomainSegments: 2, tlds: { allow: false } }).required().messages(GetMessages()),
        phoneNumber: Joi.string().min(12).required().messages(GetMessages()),
        cardNumber: Joi.string().min(10).required().messages(GetMessages()),
        cardExpiryDate: Joi.string().required().messages(GetMessages()),
        placeResidence: Joi.string().required().messages(GetMessages()),
        numberWives: Joi.string().required().messages(GetMessages()),
        numberChildren: Joi.string().required().messages(GetMessages()),
        chooseDateOfBirth: Joi.string().required().messages(GetMessages()),
        selectedStatus: Joi.string().required().messages(GetMessages()),
        selectedGender: Joi.string().required().messages(GetMessages()),
        selectedjob: Joi.string().required().messages(GetMessages()),
        dateReadyToJob: Joi.string().required().messages(GetMessages()),
        workedOurCompany: Joi.string().required().messages(GetMessages()),
        salaryToFrenchBank: Joi.string().required().messages(GetMessages()),
        socialSecurity: Joi.string().required().messages(GetMessages()),
        medicalInsurance: Joi.string().required().messages(GetMessages()),
        applyVia: Joi.string().required().messages(GetMessages()),
        selectedNationality: Joi.string().required().messages(GetMessages()),
        City: Joi.string().required().messages(GetMessages()),

    });
    const schema_2 = Joi.object({

        durationStudy: Joi.string().required().messages(GetMessages()),
        specialty: Joi.string().required().messages(GetMessages()),
        yearOuter: Joi.string().required().messages(GetMessages()),
        scientificCertificate: Joi.string().required().messages(GetMessages()),
    });
    const schema_3 = Joi.object({

    });
    const schema_4 = Joi.object({
        chooseGraduation: Joi.string().required().messages(GetMessages()),
        selectedLanguageLevel: Joi.string().required().messages(GetMessages()),
        selectedComputerLevel: Joi.string().required().messages(GetMessages()),
        selectedDegree: Joi.string().required().messages(GetMessages()),
        DealingWithOthers: Joi.string().required().messages(GetMessages()),

    });
    const [error, setError] = useState({});
    const [nationalitiesList, setNationalities] = useState([{}]);
    const [SYS_Cities, setSYS_Cities] = useState([]);
    const [filteredCities, setFilteredCities] = useState([]);
    const [gender, setGender] = useState({});
    const [employment, setEmployment] = useState(null);
    const lang = (i18n.language || 'ar');
    const languageDir = 'language-' + lang;

    const [dataFullNum, setDataFullNum] = useState(0);
    const [stepNum, setStepNum] = useState(1);

    const getYears = (start, end) => {
        let result = [];
        for (let index = start; index <= end; index++) {
            result.push({
                value: String(index), text: String(index)
            });
        }
        return reverse(result);
    };
    const [allYear, setAllYear] = useState(getYears(1960, new Date().getFullYear()));
    const [isLoadingBtnSend, setIsLoadingBtnSend] = useState(false);
    const [interviewData, setInterviewData] = useState({});
    const [interviewApplicantsData, setInterviewApplicantsData] = useState([]);
    useEffect(async () => {
        GetinterviewApplicants();
    }, []);
    useEffect(async () => {
        if (phoneNumberApplicants && phoneNumberApplicants.length > 0 && query.get("ph")) {
            const val = { ...requestForm };


            var queryPhone = query.get("ph").trim();
            val.phoneNumber = queryPhone.startsWith('+') ? queryPhone : "+" + queryPhone;
            setDefultEmailandName(val.phoneNumber);
        }
    }, [phoneNumberApplicants]);
    const [OTP, setOTP] = useState("");
    const submitRequest = async (e) => {
        e.preventDefault();

        var seccessSteps = await checkStepsErrors();

        try {
            if (seccessSteps >= 4) {
                if (!(await isDataSentBefore(requestForm.email, requestForm.phoneNumber))) {

                    const employmentId = jobs.find(j => j.positionId == requestForm.selectedjob)?.id;

                    const OTPNumber = Math.floor(100 + Math.random() * 900);
                    const OTPString = interviewData?.interviewID + OTPNumber.toString();
                    setOTP(OTPString);
                    setIsLoadingBtnSend(true);
                    const response = await axios.post(
                        config.baseURL + 'add',
                        {
                            "object": "siteEmployment_application",
                            "values": {
                                "name": requestForm.name,
                                "phoneNumber": requestForm.phoneNumber,
                                "email": requestForm.email,
                                "nationality": requestForm.selectedNationality,
                                "city": requestForm.City,
                                "cardNumber": requestForm.cardNumber,
                                "cardExpiryDate": requestForm.cardExpiryDate,
                                "placeResidence": requestForm.placeResidence,
                                "numberWives": parseInt(requestForm.numberWives),
                                "numberChildren": parseInt(requestForm.numberChildren),
                                "brithDate": requestForm.chooseDateOfBirth,
                                "gender": requestForm.selectedGender,
                                "maritalStatus": requestForm.selectedStatus,
                                "employmentId": parseInt(employmentId),
                                "dateReadyToJob": requestForm.dateReadyToJob,
                                "applyVia": requestForm.applyVia,
                                "workedOurCompany": requestForm.workedOurCompany == 1 ? true : false,
                                "salaryToFrenchBank": requestForm.salaryToFrenchBank == 1 ? true : false,
                                "socialSecurity": requestForm.socialSecurity == 1 ? true : false,
                                "medicalInsurance": requestForm.medicalInsurance == 1 ? true : false,
                                //---------------------------------------------------
                                "englishLevel": requestForm.selectedLanguageLevel,
                                "computerLevel": requestForm.selectedComputerLevel,
                                "degree": requestForm.selectedDegree,
                                "graduationYear": parseInt(requestForm.chooseGraduation),
                                "dealingWithOthers": requestForm.DealingWithOthers,
                                "PositionID": requestForm.selectedjob ? parseInt(requestForm.selectedjob) : null,
                                "interviewID": interviewData?.interviewID,
                                "OTP": OTPString
                            }
                        }
                    );
                    if (response.status === 200 || response.status === 201) {

                        var maxID = await getMaxData(requestForm.email, requestForm.phoneNumber);


                        if (maxID) {
                            inputFields_2?.forEach((val, index) => {

                                submitRequestCertificates(val, maxID);
                            });

                            inputFields?.forEach((val, index) => {

                                submitRequestworkExperience(val, maxID);
                            });

                        }

                        setIsLoadingBtnSend(false);
                        setRequestForm({ selectedNationality: "SAU" });
                        setStepNum(1);
                        setDataFullNum(0);
                        setError({});
                        setInputFields([{}]);
                        setInputFields_2([{}]);
                        toast.success(lang == "ar" ? interviewData?.SuccessMessageAr : interviewData?.SuccessMessageEn);
                        setShowFinalPopup(true);
                        setShowFinalPopupMessage(lang == "ar" ? interviewData?.SuccessMessageAr : interviewData?.SuccessMessageEn);
                    } else {
                        setIsLoadingBtnSend(false);
                    }
                } else {
                    setIsLoadingBtnSend(false);
                    setShowFinalPopup(true);
                    setShowFinalPopupMessage(trans("employment:data-sent-before"));
                }
            }
        } catch (e) {
            setIsLoadingBtnSend(false);
            console.log(e);
        }

    };
    const submitRejectRequest = async (e) => {
        e.preventDefault();
        var result = formValidate(schema_Reject, requestForm) ?? {};

        setError(result);

        if (Object.keys(result).length === 0) {

            if (requestForm.phoneNumber != null && !phoneNumberApplicants.includes(requestForm.phoneNumber)) {
                setError({ ...result, phoneNumber: trans("employment:The_mobile_number_not_on_list") });
                return;
            }


            try {

                const response = await axios.put(

                    config.baseURL + 'update',
                    {
                        "object": "siteEmployment_interview_applicants",
                        "values": {
                            "isRejection": true,
                            "rejectionReason": requestForm?.note
                            // attendanceDateTime

                        },
                        "Filters": `Where  phoneNumber = '${requestForm.phoneNumber}'  `
                    }
                );
                if (response.status == 200) {
                    setShowFinalPopup(true);
                    setShowFinalPopupMessage(trans("employment:Rejection_confirmed"));
                } else {
                    setIsLoadingBtnSend(false);
                }
            } catch (err) {
                console.log("isDataSentBefore catch", err);
                setIsLoadingBtnSend(false);
            }
        }
    };
    const submitRequestCertificates = async (CertificateObject, applicationID) => {

        try {
            if (CertificateObject.scientificCertificate && CertificateObject.yearOuter && CertificateObject.specialty) {
                const response = await axios.post(
                    config.baseURL + 'add',
                    {
                        "object": "siteEmployment_application_certificates",
                        "values": {
                            "scientificCertificate": CertificateObject.scientificCertificate,
                            "yearOuter": CertificateObject.yearOuter,
                            "specialty": CertificateObject.specialty,
                            "durationStudy": CertificateObject.durationStudy,
                            "applicationID": parseInt(applicationID)
                        }
                    }
                );
                if (response.status === 200 || response.status === 201) {



                } else {
                }
            }

        } catch (e) {
            console.log(e);
        }

    };

    const submitRequestworkExperience = async (workExperienceObject, applicationID) => {

        try {
            if (workExperienceObject.employer && workExperienceObject.durationemploy && workExperienceObject.JobTitle
                && workExperienceObject.JobTitle
            ) {
                const response = await axios.post(
                    config.baseURL + 'add',
                    {
                        "object": "siteEmployment_application_workExperience",
                        "values": {
                            "employer": workExperienceObject.employer,
                            "durationemploy": workExperienceObject.durationemploy,
                            "JobTitle": workExperienceObject.JobTitle,
                            "lastSalary": workExperienceObject.lastSalary,
                            "reasonLeaving": workExperienceObject.reasonLeaving,
                            "applicationID": parseInt(applicationID)
                        }
                    }
                );
                if (response.status === 200 || response.status === 201) {



                } else {
                }
            }

        } catch (e) {
            console.log(e);
        }

    };
    const getMaxData = async (email, phone) => {
        let maxID = null;
        try {
            const response = await axios.post(
                config.baseURL + "details",
                {
                    "Object": "siteEmployment_application",
                    "ObjectSettings": {
                        "MetaData": false
                    },
                    "Fields ": "*",
                    "Filters": `Where employmentId = ${parseInt(employment?.id)} and (email = '${email}' or phoneNumber = '${phone}') and PositionID =${parseInt(employment?.positionId)} and applicationID = (
  SELECT MAX(applicationID)
  FROM siteEmployment_application
) `
                }
            );
            if (response.status == 200) {
                if (response.data.ApiObjectData.length > 0) {

                    maxID = response.data.ApiObjectData[0].applicationID;
                }
            }
        } catch (err) {
            console.log("isDataSentBefore catch", err);
        }
        return maxID;
    };

    const GetinterviewApplicants = async () => {
        try {

            axios.post(
                config.baseURL + "func",
                {
                    "name": "SiteGetinterview_applicants",
                    "Values": {
                        "GUID": id,

                    }
                }
            ).then(response => {
                const result = response.data;

                var interviewData = {};
                if (result && result.length > 0) {
                    setInterviewApplicantsData(result);
                    interviewData = result[0];
                    try {
                        interviewData["interviewDateFormat"] = new Date(interviewData?.interviewDate).toLocaleDateString(lang + '-US', formatDate);
                    } catch (error) {

                    }
                    setInterviewData(interviewData);
                    const date = new Date();

                    const stardate = new Date(interviewData.startDate);
                    const diffTime22 = date - stardate;
                    const diffDays22 = Math.ceil(diffTime22 / (1000 * 60 * 60 * 24));

                    if (diffDays22 <= 0 && diffTime22 <= 0) {
                        setShowFinalPopupMessage(trans("employment:The_application_deadline_has_not_yet_begun"));
                        setShowFinalPopup(true);
                        return;
                    }
                    const date1 = new Date(interviewData.endDate);
                    const diffTime = date1 - date;
                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                    if (diffDays <= 0 && diffTime <= 0) {
                        setShowFinalPopupMessage(trans("employment:Application_deadline_has_expired"));
                        setShowFinalPopup(true);
                        return;
                    }
                    var phoneNumberApplicants = result.map(i => { return i.phoneNumber });
                    setPhoneNumberApplicants(phoneNumberApplicants);

                    callback();
                } else {
                    setShowFinalPopupMessage(trans("employment:The_page_link_is_incorrect"));
                    setShowFinalPopup(true);
                }

            }).catch(error => {
                setShowFinalPopupMessage(trans("employment:The_page_link_is_incorrect"));
                setShowFinalPopup(true);
            });


        } catch (err) {
            console.log("isDataSentBefore catch", err);
        }
    };
    const [applymentsData, setApplymentsData] = useState({});
    const GetEmployment_applymentsData = async (mobile, val) => {
        try {
            setApplymentsData({});
            axios.post(config.baseURL + "details",
                {
                    "Object": "siteEmployment_applyments",
                    "ObjectSettings": {
                        "MetaData": false
                    },
                    "option": "column",
                    "fields": "GUID,maritalStatus,lastName,firstName,email,nationality,brithDate,gender,englishLevel,computerLevel,maritalStatus,graduationYear,degree",
                    "Filters": `Where phoneNumber = '${mobile}' `
                }
            ).then(response => {
                const result = response?.data?.ApiObjectData;
                if (result && result.length > 0) {

                    var appData = result[0];
                    val.selectedStatus = appData.maritalStatus;
                    val.name = appData.firstName + " " + appData.lastName;
                    val.email = appData.email;
                    val.nationality = appData.nationality;
                    val.brithDate = appData.brithDate;
                    val.selectedGender = appData.gender;
                    val.selectedjob = interviewData?.PositionID ? interviewData?.PositionID.toString() : appData?.positionId?.toString();
                    val.selectedLanguageLevel = appData.englishLevel;
                    val.selectedComputerLevel = appData.computerLevel;
                    val.selectedDegree = appData.degree;
                    val.chooseGraduation = appData.graduationYear?.toString();

                    val.medicalInsurance = appData.maritalStatus == 1029 ? "0" : undefined;;
                    val.numberChildren = appData.maritalStatus == 1029 ? "0" : "";
                    val.numberWives = appData.maritalStatus == 1029 ? "0" : "";

                    setRequestForm(val);
                    setApplymentsData(appData);
                }

            }).catch(error => {

            });


        } catch (err) {
            console.log("isDataSentBefore catch", err);
        }
    };

    const isDataSentBefore = async (email, phone) => {
        let isExisted = true;
        try {
            const response = await axios.post(
                config.baseURL + "details",
                {
                    "Object": "siteEmployment_application",
                    "ObjectSettings": {
                        "MetaData": false
                    },
                    "Filters": `Where interviewID = ${interviewData?.interviewID} and phoneNumber = '${phone}' `
                }
            );
            if (response.status == 200) {
                isExisted = response.data.ApiObjectData.length > 0;
            }
        } catch (err) {
            console.log("isDataSentBefore catch", err);
        }
        return isExisted;
    };
    const [jobs, setJobs] = useState([]);


    const callback = useCallback(async () => {


        try {
            var page = 1;
            try {
                if ("caches" in window) {
                    var curl = "employment-" + page;

                    // Opening that particular cache
                    const cacheStorage = await caches.open("HJWeb");

                    // Fetching that particular cache data
                    const cachedResponse = await cacheStorage.match(
                        curl
                    );
                    var datacash = await cachedResponse?.json();

                    if (datacash) {
                        setJobs(datacash.employments);
                    }



                }
            } catch (error) {

            }

            const response = await axios.request({
                baseURL: config.controllURL,
                url: lang + "/api/employment/employment/" + page,
                method: "get",
            });

            setJobs(response.data.employments);

            const dataResponse = new Response(JSON.stringify(response.data));

            if ("caches" in window) {
                // Opening given cache and putting our data into it
                caches.open("HJWeb").then((cache) => {
                    cache.put("employment-" + page, dataResponse);

                });
            }
        } catch (error) {
            console.log(error);
        }

        const responsemm = await axios.request({
            baseURL: config.controllURL,
            url: lang + "/api/employment/GetSettings",
            method: "get",
        });
        // console.log('responsemm', responsemm);
        const  {certificateLevels,cities,genders, maritalStatus,nationalities,skillsLevels} = responsemm.data;

        try {

            const SYS_CitiesResponse = cities;
            if (SYS_CitiesResponse[0]) {
                const Cities = SYS_CitiesResponse.map(e => ({ value: String(e['ID']), text: e[`${trans("model:description")}`], ...e }));
                setSYS_Cities(Cities);
                setFilteredCities(Cities?.filter(item => {
                    return item.CountryID == "SAU"
                }));
            }
            //********************
            const nationalitiesResponse = nationalities;
            if (nationalitiesResponse[0])
                setNationalities(nationalitiesResponse.map(e => ({ value: String(e['ID']), text: e[`${trans("model:description")}`] })));
            //************************************************************************************************************************
            const genderResponse =genders;
            //*************************************************************************************************************************
            if (genderResponse[0])
                setGender({ value1: genderResponse[0].ID, value2: genderResponse[1].ID });

            const skillsResponse = skillsLevels;

            //***************************************************************************************************************************
            if (skillsResponse)
                setSkillsList(skillsResponse.map(e => ({ value: String(e['ID']), text: e[`${trans("model:description")}`] })));
            //*************************************************************************************************************************
            const certificationResponse = certificateLevels;
            setCertificationList(certificationResponse.map(e => ({ value: String(e['ID']), text: e[`${trans("model:description")}`] })));
            //*************************************************************************************************************************
            const statusResponse = maritalStatus;
            setStatusList({
                value1: statusResponse[0].ID
                , value2: statusResponse[1].ID, value3: statusResponse[2].ID
            });
        } catch (error) {
            console.log(error);
        }
    }, [lang]);

    const [inputFields_2, setInputFields_2] = useState([
        { firstName: '', lastName: '' }
    ]);
    // Handle input changes
    const handleInputChange_2 = (index, e) => {
        const { name, value } = e.target;
        const newInputFields = [...inputFields_2];
        newInputFields[index][name] = value;
        setInputFields_2(newInputFields);
    };
    const handleDropDownChange_2 = (index, e) => {
        const { name, value } = e;
        const newInputFields = [...inputFields_2];
        newInputFields[index][name] = value;


        setInputFields_2(newInputFields);
    };
    // Add a new set of input fields
    const handleAddMore_2 = () => {

        var lastGroupindex = inputFields_2.length - 1;

        if (lastGroupindex >= 0 && !inputFields_2[lastGroupindex]["specialty"]
            || !inputFields_2[lastGroupindex]["yearOuter"]
            || !inputFields_2[lastGroupindex]["scientificCertificate"]) {

            return;
        }

        setInputFields_2([...inputFields_2, { firstName: '', lastName: '' }]);
    };
    // Initial state for input fields
    const [inputFields, setInputFields] = useState([
        { firstName: '', lastName: '' }
    ]);

    // Handle input changes
    const handleInputChange = (index, e) => {
        const { name, value } = e.target;
        const newInputFields = [...inputFields];
        newInputFields[index][name] = value;
        setInputFields(newInputFields);
    };

    // Add a new set of input fields
    const handleAddMore = () => {

        var lastGroupindex = inputFields.length - 1;

        if (lastGroupindex >= 0 && !inputFields[lastGroupindex]["reasonLeaving"]) {

            return;
        }

        setInputFields([...inputFields, { firstName: '', lastName: '' }]);
    };

    const checkStepsErrors = async () => {

        setOTP("");
        var result = formValidate(schema_1, requestForm) ?? {};

        if (stepNum == 1)
            setError(result);


        var srempNumber = 0

        if (requestForm.phoneNumber != null && !phoneNumberApplicants.includes(requestForm.phoneNumber)) {
            setDataFullNum(srempNumber);
            setStepNum(srempNumber + 1);
            setError({ ...result, phoneNumber: trans("employment:The_mobile_number_not_on_list") });
            return srempNumber;
        }


        if (Object.keys(result).length === 0) {
            srempNumber = 1;
        } else {
            setDataFullNum(srempNumber);
            setStepNum(srempNumber + 1);
            return srempNumber;
        }
        result = formValidate(schema_2, requestForm) ?? {};
        if (stepNum == 2)
            setError(result);

        if (Object.keys(result).length === 0) {
            srempNumber = 2;
        } else {
            setDataFullNum(srempNumber);
            setStepNum(srempNumber + 1);
            return srempNumber;
        }
        result = formValidate(schema_3, requestForm) ?? {};
        if (stepNum == 3)
            setError(result);

        if (Object.keys(result).length === 0) {
            srempNumber = 3;
        } else {
            setDataFullNum(srempNumber);
            setStepNum(srempNumber + 1);
            return srempNumber;
        }

        result = formValidate(schema_4, requestForm) ?? {};

        if (stepNum == 4)
            setError(result);
        console.log("result", result);
        if (Object.keys(result).length === 0) {
            srempNumber = 4;
        } else {
            setDataFullNum(srempNumber);
            setStepNum(srempNumber + 1);
            return srempNumber;
        }

        setDataFullNum(srempNumber);
        return srempNumber;
    }
    const cheangeSteps = async (step) => {

        if (step > 0 && step <= 4 && step != stepNum) {

            const statusStep = await checkStepsErrors();
            if (statusStep >= step) {
                setStepNum(step);



            }

        }

    }

    const setDefultEmailandName = async (mobile) => {
        if (!requestForm?.email || !requestForm?.name) {

            const def = interviewApplicantsData.filter(a => a.phoneNumber == mobile);

            console.log("interviewApplicantsData:", def);
            if (def && def.length > 0) {

                if (def[0].isRejection == true) {
                    setIsLoadingBtnSend(false);
                    setShowFinalPopup(true);
                    setShowFinalPopupMessage(trans("employment:The_appointment_was_previously_rejected"));
                    return;
                }
                const val = { ...requestForm };
                val.phoneNumber = mobile;
                val.phoneNumberOnly = val.phoneNumber.slice(-9);
                val.phoneNumberCode = val.phoneNumber.startsWith('+') ? val.phoneNumber.slice(1, -9) : val.phoneNumber.slice(0, -9);
                if (!requestForm?.name)
                    val.name = def[0]?.name;
                if (!requestForm?.email)
                    val.email = def[0]?.email;

                if (interviewData?.PositionID || applymentsData?.positionId)
                    val.selectedjob = applymentsData?.positionId ? applymentsData?.positionId.toString() : interviewData?.PositionID.toString();
                setRequestForm(val);

                if (!(await isDataSentBefore(requestForm.email, mobile))) {

                    GetEmployment_applymentsData(mobile, val);
                } else {

                    setIsLoadingBtnSend(false);
                    setShowFinalPopup(true);
                    setShowFinalPopupMessage(trans("employment:data-sent-before"));
                }

            } else {
                const val = { ...requestForm };
                val.phoneNumber = mobile;
                setRequestForm(val);
            }
        }


    }

    useEffect(async () => {
        if (jobs && jobs.length > 0 && applymentsData?.positionId || interviewData?.PositionID && employment == null) {
            const currentEmploy = jobs.filter(j => j.positionId == (interviewData?.PositionID ?? applymentsData?.positionId))[0];
            setEmployment(currentEmploy);
        }
    }, [jobs, interviewData, applymentsData, employment]);
    return (
        <Fragment >
            <div className="employment-application">

                <div className="container">
                    <Breadcrumb className={"mt-16 "}
                        links={[
                            {
                                name: trans("common:employment"),
                                to: "/recruitment"
                            },
                            {
                                name: trans("employment:job_application_form")
                            }
                        ]}
                    />
                </div>
                {/* <h1 className="text-balck text-bold text-24 text-center">{trans("employment:job_application_form")}</h1> */}
                <h1 className="text-balck text-bold text-24 text-center">{lang == "ar" ? interviewData.titel : interviewData.titelEn}</h1>
                <div className={" mb-32 grid-1-cols " + languageDir} style={{ gap: "3rem" }}>


                    <div className={"container"}>
                        <>
                            {
                                reject == true ?
                                    <>
                                        <div className={"flex flex-col nice-gap-1rem  mt-20"}>
                                            <span className=" text-bold text-20  ">
                                                {lang == "ar" ? interviewData.subTitelAr : interviewData.subTitelEn}
                                                {/* {trans("employment:Welcome") + (requestForm.name ?? "") + trans("employment:You_have_job_interview")} */}
                                            </span>

                                        </div>
                                        <form className="flex flex-col nice-gap-3rem  my-20 " onSubmit={(e) => {
                                            submitRejectRequest(e);
                                        }}>

                                            {
                                                !applymentsData?.phoneNumber &&
                                                < PhoneDropDown
                                                    mandotory={true}
                                                    name='phoneNumber'
                                                    disabled={applymentsData?.phoneNumber}
                                                    defaultFlag={requestForm.phoneNumberCode}
                                                    defaultValue={requestForm.phoneNumberOnly}
                                                    errorLabel={error?.phoneNumber}
                                                    onChangeValue={(countryCode, phoneNumber) => {
                                                        const val = { ...requestForm };
                                                        val.phoneNumberOnly = phoneNumber;
                                                        val.phoneNumberCode = countryCode;
                                                        val.phoneNumber = `+${countryCode}${phoneNumber}`;
                                                        setRequestForm(val);
                                                        setDefultEmailandName(val.phoneNumber);
                                                    }}

                                                />
                                            }

                                            <div className="text-primary text-bold text-20 ">
                                                {trans("employment:The_appointment") + ":"}
                                                <span className="text-primary text-bold text-20  text-center">

                                                    {interviewData?.interviewDateFormat ?
                                                        interviewData?.interviewDateFormat
                                                        :
                                                        moment(interviewData?.interviewDate).format('dddd,YYYY-MM-DD  hh:mm A')

                                                    }
                                                </span>
                                            </div>
                                            <RadioInput
                                                onChangeValue={(e) => {
                                                    const val = { ...requestForm };
                                                    val.selectedReject = String(e);
                                                    setRequestForm(val);
                                                    console.log(e);
                                                }}

                                                selectValue={!requestForm.selectedReject ? 1 : requestForm.selectedReject}
                                                name="selectedReject"
                                                groupName="selectedReject"
                                                title={trans("employment:Please_confirm_interview_date")}
                                                value1={1}
                                                text1={trans("employment:Confirm_the_appointment_and_continue_filling_form")}
                                                text2={trans("employment:Reject_the_appointment")}
                                                value2={0}
                                                errorLabel={error.selectedReject}
                                            />
                                            {
                                                requestForm.selectedReject == 1 || !requestForm.selectedReject ?
                                                    <>

                                                        <div className="nice-bg-btn bg-primary text-white text-20 p-5 "
                                                            onClick={async () => {

                                                                setReject(false);

                                                            }}>
                                                            {trans("employment:Confirm_appointment")}
                                                            <div className={" mx-2 " + (lang == "ar" ? " rotate-180 " : "")}>

                                                                <svg xmlns="http://www.w3.org/2000/svg" height="20" width="25" viewBox="0 0 448 512" >
                                                                    <path fill="#ffffff" strokeWidth="2" d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                                                                </svg>

                                                            </div>


                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <TextArea
                                                            value={requestForm.note}
                                                            errorLabel={error.note}
                                                            onChangeValue={
                                                                (e) => {
                                                                    const val = { ...requestForm };
                                                                    val.note = e;
                                                                    setRequestForm(val);
                                                                }
                                                            }
                                                            name='note'
                                                            placeholder={trans("employment:Please_state_the_reason")}
                                                        />
                                                        <FormButton
                                                            isLoading={isLoadingBtnSend}
                                                            isPrimary={true} 
                                                            name={trans("employment:Reject")}
                                                            classes={"px-5 py-2 bg-black"}
                                                        />
                                                    </>
                                            }



                                        </form>
                                    </>

                                    : (
                                        <>
                                            <div className="customStepper">
                                                <div className={"customStepper-part " + (dataFullNum > 0 ? "active" : " ")}
                                                    onClick={() => cheangeSteps(1)}
                                                >
                                                    <div className={`customStepper-part-titel ${stepNum == 1 ? " active" : null}`}>
                                                        <li>{"1"}</li><span>{trans("employment:personal_information")}</span>
                                                    </div>
                                                    <div className={"customStepper-part-connector  "}></div>
                                                </div>
                                                <div className={"customStepper-part " + (dataFullNum > 1 ? "active" : " ")}
                                                    onClick={() => cheangeSteps(2)}
                                                >
                                                    <div className={`customStepper-part-titel ${stepNum == 2 ? " active" : null}`}><li>{"2"}</li>
                                                        <span> {trans("employment:education_academic_background_training")}</span>
                                                    </div>
                                                    <div className={"customStepper-part-connector  "}></div>
                                                </div>

                                                <div className={"customStepper-part " + (dataFullNum > 2 ? "active" : " ")}
                                                    onClick={() => cheangeSteps(3)}
                                                >
                                                    <div className={`customStepper-part-titel ${stepNum == 3 ? " active " : null}`}><li>{"3"}
                                                    </li><span>{trans("employment:work_experience")}</span></div>
                                                    <div className={"customStepper-part-connector  "}></div>
                                                </div>

                                                <div className={"customStepper-part " + (dataFullNum > 3 ? "active" : " ")}
                                                    onClick={() => cheangeSteps(4)}
                                                >
                                                    <div className={`customStepper-part-titel ${stepNum == 4 ? " active " : null}`}>
                                                        <li>{"4"}</li><span>{trans("employment:acquired_skills")}</span></div>
                                                    <div className={"customStepper-part-connector  "}></div>
                                                </div>
                                            </div>
                                            <div className="ruler-2 mb-3"></div>


                                            <form className="flex flex-col nice-gap-3rem  my-5 " onSubmit={(e) => {
                                                submitRequest(e);
                                            }}>

                                                {
                                                    stepNum == 1 &&
                                                    <>
                                                        <h3 className="text-primary text-bold text-24 show-xs text-center">{trans("employment:personal_information")}</h3>
                                                        <div className="flex flex-row grid-3-cols nice-gap-2rem ">

                                                            <PhoneDropDown
                                                                mandotory={true}
                                                                name='phoneNumber'
                                                                disabled={applymentsData?.phoneNumber}
                                                                defaultFlag={requestForm.phoneNumberCode}
                                                                defaultValue={requestForm.phoneNumberOnly}
                                                                errorLabel={error?.phoneNumber}
                                                                onChangeValue={(countryCode, phoneNumber) => {
                                                                    const val = { ...requestForm };
                                                                    val.phoneNumberOnly = phoneNumber;
                                                                    val.phoneNumberCode = countryCode;
                                                                    val.phoneNumber = `+${countryCode}${phoneNumber}`;
                                                                    setRequestForm(val);
                                                                    setDefultEmailandName(val.phoneNumber);
                                                                }}

                                                            />

                                                            <InputField
                                                                placeholder={trans("form:name")}
                                                                value={requestForm.name}
                                                                errorLabel={error.name}
                                                                disabled={applymentsData?.firstName}
                                                                onChangeValue={(e) => {
                                                                    const val = { ...requestForm };
                                                                    val.name = e.target.value;
                                                                    setRequestForm(val);
                                                                }}
                                                                name='name'
                                                            />


                                                            <InputField
                                                                placeholder={trans("employment:email")}
                                                                value={requestForm.email}
                                                                disabled={applymentsData?.email}
                                                                type='email'
                                                                onChangeValue={(e) => {
                                                                    const val = { ...requestForm };
                                                                    val.email = e.target.value;
                                                                    setRequestForm(val);
                                                                }}
                                                                errorLabel={error.email}
                                                                name='email'
                                                            />

                                                            <DropDownInput
                                                                disabled={applymentsData?.nationality}
                                                                onSelect={(e) => {
                                                                    const val = { ...requestForm };
                                                                    val.selectedNationality = String(e);
                                                                    val.City = null;
                                                                    console.log(e);
                                                                    setRequestForm(val);

                                                                    setFilteredCities(SYS_Cities?.filter(item => {
                                                                        return item.CountryID == val.selectedNationality
                                                                    }));
                                                                }}
                                                                selectedValue={requestForm.selectedNationality}
                                                                name='selectedNationality'
                                                                className={"flex-grow-1"}
                                                                placeholder={trans("employment:nationalte")}
                                                                options={[...nationalitiesList]}
                                                                errorLabel={error.selectedNationality}
                                                                defaultOption={requestForm.selectedNationality ? null : { value: "SAU", text: lang == "ar" ? "سعودي  / سعودية" : "Kingdom of Saudi Arabia" }}


                                                            />
                                                            <DropDownInput
                                                                onSelect={(e) => {
                                                                    const val = { ...requestForm };
                                                                    val.City = String(e);
                                                                    console.log(e);
                                                                    setRequestForm(val);

                                                                }}
                                                                selectedValue={requestForm.City}
                                                                name='City'
                                                                className={"flex-grow-1"}
                                                                placeholder={trans("form:city")}
                                                                options={[...filteredCities]}
                                                                errorLabel={error.City}


                                                            />
                                                            <InputField
                                                                placeholder={trans("employment:id_card_number")}
                                                                value={requestForm.cardNumber}
                                                                type="text"
                                                                onChangeValue={(e) => {
                                                                    if (/^\d*$/.test(e.target.value) && e.target.value.length <= 10) {
                                                                        const val = { ...requestForm };
                                                                        val.cardNumber = e.target.value;
                                                                        setRequestForm(val);
                                                                    }

                                                                }}
                                                                errorLabel={error.cardNumber}
                                                                name='cardNumber'
                                                            />

                                                            <DateInput
                                                                value={requestForm.cardExpiryDate}
                                                                onSelect={
                                                                    (e) => {
                                                                        const val = { ...requestForm };
                                                                        val.cardExpiryDate = e.target.value;
                                                                        setRequestForm(val);
                                                                    }
                                                                }
                                                                name='cardExpiryDate'
                                                                placeholder={trans("employment:end_date")}

                                                                errorLabel={error.cardExpiryDate}
                                                            />
                                                            <DateInput
                                                                value={requestForm.chooseDateOfBirth}
                                                                onSelect={
                                                                    (e) => {
                                                                        const val = { ...requestForm };
                                                                        val.chooseDateOfBirth = e.target.value;
                                                                        setRequestForm(val);
                                                                    }
                                                                }
                                                                name='chooseDateOfBirth'
                                                                placeholder={trans("employment:birthday")}

                                                                errorLabel={error.chooseDateOfBirth}
                                                            />

                                                            <InputField
                                                                placeholder={trans("employment:place_of_residence")}
                                                                value={requestForm.placeResidence}
                                                                onChangeValue={(e) => {
                                                                    const val = { ...requestForm };
                                                                    val.placeResidence = e.target.value;
                                                                    setRequestForm(val);
                                                                }}
                                                                errorLabel={error.placeResidence}
                                                                name='placeResidence'
                                                            />




                                                        </div>





                                                        <div className="flex flex-row grid-2-cols-importent nice-gap-2rem ">

                                                            <RadioInputDynamic
                                                                disabled={applymentsData?.maritalStatus}
                                                                onChangeValue={(e) => {
                                                                    const val = { ...requestForm };

                                                                    val.selectedStatus = String(e);
                                                                    if (val.selectedStatus == statusList.value2) {
                                                                        val.numberChildren = "0";
                                                                        val.numberWives = "0";
                                                                        val.medicalInsurance = "0";
                                                                    } else {
                                                                        val.numberChildren = "";
                                                                        val.numberWives = "";
                                                                        val.medicalInsurance = undefined;
                                                                    }
                                                                    setRequestForm(val);
                                                                    console.log(e);

                                                                }
                                                                }
                                                                selectValue={requestForm.selectedStatus}
                                                                name="selectedStatus"
                                                                groupName="SocialStatus"
                                                                title={trans("employment:state")}
                                                                ListValues={[{ text: trans("employment:marred"), value: statusList.value1 }, {
                                                                    text: trans("employment:single"),
                                                                    value: statusList.value2
                                                                }, { text: trans("employment:other"), value: statusList.value3 }]}
                                                                errorLabel={error.selectedStatus}
                                                            />
                                                            <RadioInput
                                                                disabled={applymentsData?.gender}
                                                                onChangeValue={(e) => {
                                                                    const val = { ...requestForm };
                                                                    val.selectedGender = String(e);
                                                                    setRequestForm(val);
                                                                    console.log(e);
                                                                }}
                                                                selectValue={requestForm.selectedGender}
                                                                name="selectedGender2"
                                                                groupName="Gender"
                                                                title={trans("employment:type")}
                                                                value1={gender.value1}
                                                                text1={trans("employment:man")}
                                                                text2={trans("employment:female")}
                                                                value2={gender.value2}
                                                                errorLabel={error.selectedGender}
                                                            />

                                                        </div>
                                                        <div className="flex flex-row grid-2-cols-importent nice-gap-2rem ">
                                                            {
                                                                (statusList.value2 == null || (requestForm?.selectedStatus != statusList.value2)) &&
                                                                <>
                                                                    <InputField
                                                                        placeholder={trans("employment:number_of_spouses")}
                                                                        value={requestForm.numberWives}
                                                                        type="number"
                                                                        onChangeValue={(e) => {
                                                                            const val = { ...requestForm };
                                                                            val.numberWives = e.target.value;
                                                                            setRequestForm(val);
                                                                        }}
                                                                        errorLabel={error.numberWives}
                                                                        name='numberWives'
                                                                    />
                                                                    <InputField
                                                                        placeholder={trans("employment:number_of_children")}
                                                                        type="number"
                                                                        value={requestForm.numberChildren}
                                                                        onChangeValue={(e) => {
                                                                            const val = { ...requestForm };
                                                                            val.numberChildren = e.target.value;
                                                                            setRequestForm(val);
                                                                        }}
                                                                        errorLabel={error.numberChildren}
                                                                        name='numberChildren'
                                                                    />

                                                                </>
                                                            }
                                                        </div>
                                                        <div className="flex flex-row grid-1-cols-importent nice-gap-2rem ">

                                                            <RadioInputDynamic
                                                                onChangeValue={(e) => {
                                                                    const val = { ...requestForm };
                                                                    val.applyVia = String(e);
                                                                    setRequestForm(val);
                                                                    console.log(e);
                                                                }
                                                                }
                                                                selectValue={requestForm.applyVia}
                                                                name="applyVia"
                                                                groupName="applyVia"
                                                                title={trans("employment:apply_through")}
                                                                ListValues={[{ text: trans("employment:TAQAT"), value: trans("employment:TAQAT") }, { text: "Twitter", value: "Twitter" }, { text: "snapchat", value: "snapchat" },
                                                                { text: trans("employment:email"), value: trans("employment:email") },
                                                                { text: trans("employment:other"), value: trans("employment:other") }]}
                                                                errorLabel={error.applyVia}
                                                            />


                                                        </div>
                                                        <div className="flex flex-row grid-2-cols nice-gap-2rem ">
                                                            {
                                                                (statusList.value2 == null || requestForm?.selectedStatus != statusList.value2) &&
                                                                <>

                                                                    <RadioInput
                                                                        onChangeValue={(e) => {
                                                                            const val = { ...requestForm };
                                                                            val.medicalInsurance = String(e);
                                                                            setRequestForm(val);
                                                                            console.log(e);
                                                                        }
                                                                        }
                                                                        selectValue={requestForm.medicalInsurance}
                                                                        name="medicalInsurance"
                                                                        groupName="medicalInsurance"
                                                                        title={trans("employment:health_insurance_spouse_children")}
                                                                        text1={trans("employment:yes")}
                                                                        value1={1}
                                                                        text2={trans("employment:no")}
                                                                        value2={0}
                                                                        errorLabel={error.medicalInsurance}
                                                                    />

                                                                </>
                                                            }
                                                            <RadioInput
                                                                onChangeValue={(e) => {
                                                                    const val = { ...requestForm };
                                                                    val.socialSecurity = String(e);
                                                                    setRequestForm(val);
                                                                    console.log(e);
                                                                }}
                                                                selectValue={requestForm.socialSecurity}
                                                                name="socialSecurity"
                                                                groupName="socialSecurity"
                                                                title={trans("employment:registered_with_social_insurance")}
                                                                value1={1}
                                                                text1={trans("employment:yes")}
                                                                text2={trans("employment:no")}
                                                                value2={0}
                                                                errorLabel={error.socialSecurity}
                                                            />


                                                            <RadioInput
                                                                onChangeValue={(e) => {
                                                                    const val = { ...requestForm };
                                                                    val.salaryToFrenchBank = String(e);
                                                                    setRequestForm(val);
                                                                    console.log(e);
                                                                }
                                                                }
                                                                selectValue={requestForm.salaryToFrenchBank}
                                                                name="salaryToFrenchBank"
                                                                groupName="salaryToFrenchBank"
                                                                title={trans("employment:transfer_salary_objections")}
                                                                text1={trans("employment:yes")}
                                                                value1={1}
                                                                text2={trans("employment:no")}
                                                                value2={0}
                                                                errorLabel={error.salaryToFrenchBank}
                                                            />

                                                            <RadioInput
                                                                onChangeValue={(e) => {
                                                                    const val = { ...requestForm };
                                                                    val.workedOurCompany = String(e);
                                                                    setRequestForm(val);
                                                                    console.log(e);
                                                                }}
                                                                selectValue={requestForm.workedOurCompany}
                                                                name="workedOurCompany"
                                                                groupName="workedOurCompany"
                                                                title={trans("employment:worked_for_hassan_jameel")}
                                                                value1={1}
                                                                text1={trans("employment:yes")}
                                                                text2={trans("employment:no")}
                                                                value2={0}
                                                                errorLabel={error.workedOurCompany}
                                                            />

                                                        </div>

                                                        <div className="flex flex-row grid-3-cols nice-gap-2rem ">

                                                            <DateInput
                                                                value={requestForm.dateReadyToJob}
                                                                onSelect={
                                                                    (e) => {
                                                                        const val = { ...requestForm };
                                                                        val.dateReadyToJob = e.target.value;
                                                                        setRequestForm(val);
                                                                    }
                                                                }
                                                                name='dateReadyToJob'
                                                                placeholder={trans("employment:available_to_start_job_date")}

                                                                errorLabel={error.dateReadyToJob}
                                                            />
                                                            <DropDownInput
                                                                disabled={jobs.filter(j => j.positionId == applymentsData?.positionId || j.positionId == interviewData?.PositionID).length > 0}
                                                                defaultOption={{
                                                                    text: trans("employment:position_applied_for"),
                                                                    value: '0'
                                                                }}
                                                                onSelect={(e) => {
                                                                    const val = { ...requestForm };
                                                                    val.selectedjob = String(e);
                                                                    setRequestForm(val);

                                                                    const currentEmploy = jobs.filter(j => j.positionId == val.selectedjob)[0];
                                                                    console.log("currentEmploy:", currentEmploy);
                                                                    setEmployment(currentEmploy);
                                                                }}
                                                                selectedValue={requestForm.selectedjob}
                                                                name='selectedjob'
                                                                className={"flex-grow-1"}
                                                                options={[...jobs?.map(job => { return { text: job.position, value: job.positionId, ...job } })]}
                                                                errorLabel={error.selectedjob}
                                                            />
                                                        </div>
                                                    </>

                                                }
                                                {
                                                    stepNum == 2 &&
                                                    <>
                                                        <h3 className="text-primary text-bold text-24 show-xs text-center">{trans("employment:education_academic_background_training")}</h3>
                                                        {inputFields_2.map((field, index) => (
                                                            <React.Fragment key={index}>
                                                                <div className="flex flex-row grid-4-cols nice-gap-2rem ">
                                                                    <InputField
                                                                        placeholder={trans("employment:degree_obtained")}
                                                                        value={field.scientificCertificate}
                                                                        errorLabel={error.scientificCertificate}
                                                                        onChangeValue={(e) => {
                                                                            handleInputChange_2(index, e);
                                                                            const val = { ...requestForm };
                                                                            val.scientificCertificate = e.target.value;
                                                                            setRequestForm(val);
                                                                        }}
                                                                        name='scientificCertificate'
                                                                    />

                                                                    <DropDownInput

                                                                        defaultOption={field.yearOuter == null ? {
                                                                            text: trans("employment:yearOuter"),
                                                                            value: '0'
                                                                        } : {
                                                                            text: field.yearOuter,
                                                                            value: field.yearOuter
                                                                        }}
                                                                        onSelect={(e) => {
                                                                            const val = { ...requestForm };
                                                                            val.yearOuter = String(e);
                                                                            setRequestForm(val);
                                                                            handleDropDownChange_2(index, { name: "yearOuter", value: val.yearOuter });
                                                                        }}
                                                                        selectValue={field.yearOuter}
                                                                        className={"flex-grow-1"}
                                                                        errorLabel={error.yearOuter}

                                                                        name='yearOuter'
                                                                        options={allYear}
                                                                    />

                                                                    <InputField
                                                                        placeholder={trans("employment:specialization")}
                                                                        value={field.specialty}
                                                                        errorLabel={error.specialty}
                                                                        onChangeValue={(e) => {
                                                                            handleInputChange_2(index, e);
                                                                            const val = { ...requestForm };
                                                                            val.specialty = e.target.value;
                                                                            setRequestForm(val);
                                                                        }}
                                                                        name='specialty'
                                                                    />
                                                                    <InputField
                                                                        placeholder={trans("employment:duration_of_study")}
                                                                        value={field.durationStudy}
                                                                        onChangeValue={(e) => {
                                                                            handleInputChange_2(index, e);
                                                                            const val = { ...requestForm };
                                                                            val.durationStudy = e.target.value;
                                                                            setRequestForm(val);
                                                                        }}
                                                                        errorLabel={error.durationStudy}
                                                                        name='durationStudy'
                                                                    />
                                                                </div>
                                                                <div className="ruler-2 mb-3"></div>
                                                            </React.Fragment>
                                                        ))}
                                                        <div className="flex nice-justifiy-start gap-2 py-10 px-5">
                                                            <div className="my-5">
                                                                <button type="button" className="nice-bg-btn bg-primary text-white text-40 p-5 "
                                                                    onClick={handleAddMore_2}>+</button>
                                                            </div>
                                                        </div>

                                                    </>
                                                }

                                                {
                                                    stepNum == 3 &&
                                                    <>
                                                        <h3 className="text-primary text-bold text-24 show-xs text-center">{trans("employment:work_experience")}</h3>
                                                        {inputFields.map((field, index) => (
                                                            <React.Fragment key={index}>
                                                                <div className="flex flex-row grid-4-cols nice-gap-2rem ">
                                                                    <InputField
                                                                        placeholder={trans("employment:employer")}
                                                                        value={field.employer}
                                                                        errorLabel={error.employer}
                                                                        onChangeValue={(e) => {
                                                                            handleInputChange(index, e);
                                                                            const val = { ...requestForm };
                                                                            val.employer = e.target.value;
                                                                            setRequestForm(val);
                                                                        }}
                                                                        name='employer'
                                                                    />
                                                                    <InputField
                                                                        placeholder={trans("employment:duration_of_employment")}
                                                                        value={field.durationemploy}
                                                                        onChangeValue={(e) => {
                                                                            handleInputChange(index, e);
                                                                            const val = { ...requestForm };
                                                                            val.durationemploy = e.target.value;
                                                                            setRequestForm(val);
                                                                        }}
                                                                        errorLabel={error.durationemploy}
                                                                        name='durationemploy'
                                                                    />

                                                                    <InputField
                                                                        placeholder={trans("employment:job_title")}
                                                                        value={field.JobTitle}
                                                                        errorLabel={error.JobTitle}
                                                                        onChangeValue={(e) => {
                                                                            handleInputChange(index, e);
                                                                            const val = { ...requestForm };
                                                                            val.JobTitle = e.target.value;
                                                                            setRequestForm(val);
                                                                        }}
                                                                        name='JobTitle'
                                                                    />
                                                                    <InputField
                                                                        placeholder={trans("employment:last_salary")}
                                                                        value={field.lastSalary}
                                                                        onChangeValue={(e) => {
                                                                            handleInputChange(index, e);
                                                                            const val = { ...requestForm };
                                                                            val.lastSalary = e.target.value;
                                                                            setRequestForm(val);
                                                                        }}
                                                                        errorLabel={error.lastSalary}
                                                                        name='lastSalary'
                                                                    />
                                                                    <InputField
                                                                        placeholder={trans("employment:reason_for_leaving_job")}
                                                                        value={field.reasonLeaving}
                                                                        onChangeValue={(e) => {
                                                                            handleInputChange(index, e);
                                                                            const val = { ...requestForm };
                                                                            val.reasonLeaving = e.target.value;
                                                                            setRequestForm(val);
                                                                        }}
                                                                        errorLabel={error.reasonLeaving}
                                                                        name='reasonLeaving'
                                                                    />
                                                                </div>
                                                                <div className="ruler-2 mb-3"></div>
                                                            </React.Fragment>
                                                        ))}
                                                        <div className="flex nice-justifiy-start gap-2 py-10 px-5">
                                                            <div className="my-5">
                                                                <button type="button" className="nice-bg-btn bg-primary text-white text-40 p-5 "
                                                                    onClick={handleAddMore}>+</button>
                                                            </div>
                                                        </div>
                                                    </>
                                                }

                                                {
                                                    stepNum == 4 &&
                                                    <>
                                                        <h3 className="text-primary text-bold text-24 show-xs text-center">{trans("employment:acquired_skills")}</h3>
                                                        <>
                                                            <div className="flex flex-row grid-4-cols nice-gap-2rem ">

                                                                <DropDownInput
                                                                    title={trans("employment:levelEn")}
                                                                    disabled={applymentsData?.englishLevel}
                                                                    defaultOption={{
                                                                        text: trans("employment:levelEn"),
                                                                        value: '0'
                                                                    }}
                                                                    onSelect={(e) => {
                                                                        const val = { ...requestForm };
                                                                        val.selectedLanguageLevel = String(e);
                                                                        setRequestForm(val);
                                                                    }}
                                                                    selectedValue={requestForm.selectedLanguageLevel}
                                                                    name='selectedLanguageLevel'
                                                                    className={"flex-grow-1"}
                                                                    options={[...skillsList]}
                                                                    errorLabel={error.selectedLanguageLevel}
                                                                />

                                                                <DropDownInput
                                                                    title={trans("employment:skilsComputer")}
                                                                    disabled={applymentsData?.computerLevel}
                                                                    defaultOption={{
                                                                        text: trans("employment:skilsComputer"),
                                                                        value: '0'
                                                                    }}
                                                                    onSelect={(e) => {
                                                                        const val = { ...requestForm };
                                                                        val.selectedComputerLevel = String(e);
                                                                        setRequestForm(val);
                                                                    }}
                                                                    selectedValue={requestForm.selectedComputerLevel}
                                                                    name='selectedComputerLevel'
                                                                    className={"flex-grow-1 "}
                                                                    options={[...skillsList]}
                                                                    errorLabel={error.selectedComputerLevel}
                                                                />
                                                                <DropDownInput
                                                                    title={trans("employment:interpersonal_skills")}
                                                                    defaultOption={{
                                                                        text: trans("employment:interpersonal_skills"),
                                                                        value: '0'
                                                                    }}
                                                                    onSelect={(e) => {
                                                                        const val = { ...requestForm };
                                                                        val.DealingWithOthers = String(e);
                                                                        setRequestForm(val);
                                                                    }}
                                                                    selectedValue={requestForm.DealingWithOthers}
                                                                    name='DealingWithOthers'
                                                                    className={"flex-grow-1 "}
                                                                    options={[...skillsList]}
                                                                    errorLabel={error.DealingWithOthers}
                                                                />

                                                                <DropDownInput
                                                                    title={trans("employment:dagree")}
                                                                    disabled={applymentsData?.degree}
                                                                    defaultOption={{
                                                                        text: trans("employment:dagree"),
                                                                        value: '0'
                                                                    }}
                                                                    onSelect={(e) => {
                                                                        const val = { ...requestForm };
                                                                        val.selectedDegree = String(e);
                                                                        setRequestForm(val);
                                                                    }}
                                                                    selectedValue={requestForm.selectedDegree}
                                                                    name='selectedDegree'
                                                                    className={"flex-grow-1"}
                                                                    options={[...certificationList]}
                                                                    errorLabel={error.selectedDegree}
                                                                />

                                                                <DropDownInput
                                                                    title={trans("employment:yearOuter")}
                                                                    disabled={applymentsData?.graduationYear}
                                                                    defaultOption={{
                                                                        text: trans("employment:yearOuter"),
                                                                        value: '0'
                                                                    }}
                                                                    onSelect={(e) => {
                                                                        const val = { ...requestForm };
                                                                        val.chooseGraduation = String(e);
                                                                        setRequestForm(val);
                                                                    }}
                                                                    selectedValue={requestForm.chooseGraduation}
                                                                    className={"flex-grow-1"}
                                                                    errorLabel={error.chooseGraduation}
                                                                    name='chooseGraduation'
                                                                    options={[...getYears(1960, new Date().getFullYear())]}
                                                                />
                                                            </div>

                                                        </>
                                                        <FormButton
                                                            isLoading={isLoadingBtnSend}
                                                            name={trans("employment:send")}
                                                        />

                                                    </>

                                                }



                                            </form>


                                            <div className="ruler-2 mb-3"></div>
                                            {

                                                <>
                                                    <div className="flex nice-justifiy-end gap-2 py-10 px-5">

                                                        {

                                                            <div className=" mb-1 ">

                                                                {
                                                                    stepNum > 1 &&
                                                                    <div className="nice-bg-btn bg-black text-white text-20  p-5 " onClick={() => {
                                                                        if (stepNum > 1)
                                                                            setStepNum(stepNum - 1);
                                                                    }}>


                                                                        {trans("info:back")}


                                                                        <div className={" mx-2 " + (lang == "ar" ? "  " : " rotate-180 ")}>

                                                                            <svg xmlns="http://www.w3.org/2000/svg" height="20" width="25" viewBox="0 0 448 512" >
                                                                                <path fill="#3bd461" strokeWidth="2" d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                                                                            </svg>



                                                                        </div>
                                                                    </div>
                                                                }

                                                            </div>
                                                        }

                                                        {

                                                            <div className=" mb-1 ">

                                                                {
                                                                    stepNum < 4 &&
                                                                    <div className="nice-bg-btn bg-primary text-white text-20 p-5 " onClick={async () => {

                                                                        const num = await checkStepsErrors();

                                                                        if (num >= (stepNum)) {

                                                                            if (stepNum < 4)
                                                                                setStepNum(stepNum + 1);

                                                                            if (Object.keys(error).length > 0) {
                                                                                // setError({});

                                                                            }
                                                                        } else {
                                                                            // checkErrors();
                                                                        }

                                                                    }}>
                                                                        {trans("form:next")}
                                                                        <div className={" mx-2 " + (lang == "ar" ? " rotate-180 " : "")}>

                                                                            <svg xmlns="http://www.w3.org/2000/svg" height="20" width="25" viewBox="0 0 448 512" >
                                                                                <path fill="#ffffff" strokeWidth="2" d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                                                                            </svg>

                                                                        </div>


                                                                    </div>
                                                                }

                                                            </div>
                                                        }



                                                    </div>


                                                </>
                                            }

                                        </>
                                    )

                            }
                        </>
                    </div>

                </div>
            </div>

            <Popup show={showFinalPopup}>
                <div className="w-full h-full flex flex-column justify-center items-center" >
                    <div className="cart-finalized" >
                        <div className="cart-finalized-container flex flex-col">

                            <div className="cart-finalized-container-img flex flex-col">
                                <img src="/assets/images/finalized.svg" />
                            </div>
                            {
                                <>
                                    <div className="flex flex-wrap justify-center mx-20">
                                        <span className="text-20 font-bold  mx-2"> {showFinalPopupMessage}</span>
                                    </div>

                                </>

                            }


                            {

                                OTP &&
                                <div className="flex flex-row justify-center cart-finalized-code"
                                    onClick={() => {

                                        navigator.clipboard.writeText(OTP);
                                    }}

                                >

                                    <span className="text-20 font-bold text-white  mx-2"> {trans("employment:Your_OTP") + " :"}</span>
                                    <span className="text-20 font-bold text-white  mx-2">{OTP && OTP}</span>
                                    <img src="/assets/icons/copy.svg" alt='' />
                                </div>
                            }
                            {
                                OTP &&
                                <div className="text-primary text-bold text-24 s text-center">
                                    {"الموعد :"}
                                    <span className="text-primary text-bold text-24 s text-center">

                                        {interviewData?.interviewDateFormat ?
                                            interviewData?.interviewDateFormat
                                            :
                                            moment(interviewData?.interviewDate).format('dddd,YYYY-MM-DD  hh:mm A')

                                        }
                                    </span>
                                </div>

                            }
                            <div className="flex flex-center flex-col  mt-5 pt-5 w-80">
                                <div className=" mb-5 w-100">
                                    <div className="nice-sm-btn cart-notification-go  bg-primary text-white text-16" onClick={() => {

                                        setShowFinalPopup(false);
                                        history.push("/");

                                    }}>
                                        {trans("cartFile:Continue_browsing")}
                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>

                </div>
            </Popup>
        </Fragment >


    );
}
