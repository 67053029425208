import Popup from "../User/popup";
import React, { useContext } from "react";
import { FormButton } from "./_input/_button";
import { DropDownInput } from "./_input/_dropdown";
import ConfirmContext from "../utils/confirmContext";
import { useTranslation } from "react-i18next";
import { useRef } from "react";
import { Price } from "./__simple/_price";
import { groupBy, mapValues, omit } from "lodash";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router";
import SectionHeading from '../components/sectionHeading';
import axios from "axios";
import config from '../configuration/config.json';
import { ContentLoading } from "./contentLoading";
import dropDownResetter from "../utils/resetDropDown";
import SimpleButton from "./_input/SimpleButton";
import { useUserCart } from '../User/hooks/useUserCart';
function PurchaseConfirm({
    displayConfirm = false,
    title = 'هنا يتم وضع العنوان الخاص برسالة التاكيد',
    subTitle = null,
    backGround = null,
    modelType = null,
    price = 10000,
    isOnline = false,
    image = null,
    type = null,
    isHyper = false,
    isNew = false,
    brand = null,
    brandID = null,
    cartDetails,
    modelYear,
    defaultColorID,
    carOrder,
    ColorsModel = []
}) {
    const { onClose } = useContext(ConfirmContext);
    const popupRef = useRef();
    const { t: trans,i18n } = useTranslation(["vehicales", "info", "common", "form", "inputs", "model", "new"]);
    const [show, setShow] = useState(displayConfirm);
    let lang = (i18n.language || 'ar');;
    const languageDir = 'language-' + lang;
    const history = useHistory();
    const [selectedColor, setSelectedColor] = useState(null);
    const [productTypes, setProductTypes] = useState([]);
    const [carModels, setCarModels] = useState([]);
    const [defaultProductModel, setDefaultProductModel] = useState(null);
    const [colors, setColors] = useState([]);
    const [contentLoading, setContentLoading] = useState(false);
    const { removecartItems, changecartItem } = useUserCart();
    const [brandType, setBrandType] = useState("");
    useEffect(() => {
        //intialDefaultColor(defaultColorID);
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };


    }, [displayConfirm]);

    useEffect(async () => {

        if (carOrder?.group) {
            await handleFetchModels(carOrder?.group, modelYear, true);
            // setContentLoading(false);
        }

    }, [displayConfirm]);


    useEffect(async () => {

        if (carModels && carModels?.length > 0) {

            var thisType = type;
            if (!type) {
                var _default = carModels.find(item => item.BuyOnline == true) ?? null;
                if (!_default)
                    _default = carModels[0];
                thisType = (_default && _default?.Type) ? _default?.Type : type;
            }

            await handleFetchModelAvaliableColors(thisType, true);
            setContentLoading(false);
        }
    }, [carModels]);

    useEffect(async () => {

        var bb = brand?.toLowerCase() == trans("common:toyota").toLowerCase() ? 'toyota' : 'lexus';
        setBrandType(bb);
    }, [brand]);

    // to close the popup from outside
    const handleClickOutside = (event) => {
        if (popupRef.current && popupRef.current.classList.contains(event.target.classList[0]) && displayConfirm == true) {
            onClose();
            setProductTypes([]);
            setCarModels([]);
            setColors([]);
            setPriceColor(null);
            setCart({ Id: '', colorID: '', colorNameEn: "", modelTypeDescEn: "", groupEn: "", brandEn: "", modelTypeDesc: "", Price: 0, Discount: 0, Tax: 0, ProductID: '', colorName: "", group: "", brand: "", year: "", modelType: "", showPric: false, BrandID: '', MiniDownPayment: 0 });
        }
    };

    // to order purchase
    const handleCarOrderPurchase = () => {
        history.push(`/order-car?vehicle=${carOrder?.vehicle}&model=${modelYear}&type=${Cart.modelType}&color=${Cart?.colorID}`);
        onClose();
    };

    const [PriceColor, setPriceColor] = useState(null);
    const [onLine, setOnline] = useState(false);


    const [Cart, setCart] = useState({ Id: '', colorID: '', colorNameEn: "", modelTypeDescEn: "", groupEn: "", brandEn: "", modelTypeDesc: "", Price: 0, Discount: 0, Tax: 0, ProductID: '', colorName: "", group: "", brand: "", year: "", modelType: "", showPric: false, BrandID: '', MiniDownPayment: 0 });
    function filterPriceoffColor(option) {
        setSelectedColor(option);
        setOuterCarImagesJson(null);
        //console.log("filterPriceoffColor>>>>>:", option);
        //console.log("filterPriceoffColor>>>>>carModels:", carModels);
        const selectdM = carModels?.find(item => item?.Type == option?.Type) ?? null;
        //console.log("filterPriceoffColor>>>>>selectdM:", selectdM);
        try {
            if (option.showPric || option?.buyOnline) {
                setPriceColor(option.NetSalePrice);
            } else {
                setPriceColor(null);

            }
            const currentValues = { ...Cart };
            currentValues.Id = option?.GUID;
            currentValues.brand = option["Brand Arabic"];
            currentValues.brandEn = option["Brand English"];
            currentValues.group = option["Group Arabic"];
            currentValues.groupEn = option["Group English"];
            currentValues.colorName = option["ColorDescriptionAr"];
            currentValues.colorNameEn = option["ColorDescriptionEn"];
            currentValues.colorID = option?.value;
            currentValues.Price = option?.NetSalePrice;
            currentValues.Tax = option?.TaxAmount;
            currentValues.Discount = option?.Discount;
            currentValues.ProductID = option?.ProductID;
            currentValues.BrandID = option?.BrandID;
            currentValues.image = selectdM?.Image;
            currentValues.modelType = option?.Type;
            currentValues.modelTypeDesc = option["Type Arabic"];
            currentValues.modelTypeDescEn = option["Type English"];
            currentValues.showPric = option?.showPric;
            currentValues.year = option?.Model;
            currentValues.MiniDownPayment = option.MiniDownPayment;

            if (

                // ((option.TotalQtyAVA ? option.TotalQtyAVA : 0) > 0)  &&

                option?.buyOnline == true || option?.buyOnline == 1) {

                setOnline(true);
            }
            else {

                setOnline(false);
            }


            setCart(currentValues);

            fetchCarDesign(option?.value, option?.ProductID, option?.Model);

        } catch (err) {
            //console.log(err);
        }

    }
    // to fetch the model types
    const handleFetchModels = async (groupType, modelYear, setDefault = false) => {
        let _models = [];
        try {
            setContentLoading(true);

            var funName = "site_GetCarModelsAr";
            if (lang == "en") {
                funName = "site_GetCarModelsEn";
            }
            const response = await axios.post(
                config.baseURL + 'func',
                {
                    "Name": funName,
                    "Values": {
                        "Group": groupType,
                        "Year": modelYear
                    }
                }
            );
            if (response.status == 200) {

                setCarModels(response.data);
                _models = response.data.map(item => {
                    return { value: item.Type, text: item?.ModelDescription?.replace(item?.Brand + " " + item?.Group, ""), buyOnline: item?.BuyOnline }
                });

                setProductTypes(_models);
                let _default = null;

                if (setDefault) {

                    if (type) {
                        _default = _models.find(item => item.value == type) ?? null;
                    } else {
                        _default = _models.find(item => item.BuyOnline == true) ?? null;

                        if (_default == null) {
                            _default = _models.find(item => item.value == type) ?? null;
                        }

                    }


                }
                setDefaultProductModel(_default);
            }
        } catch (error) {
            //console.log(error);
            setContentLoading(false);
        }
        return _models;
    };
    // to fetch colors based on the product type id
    const handleFetchModelAvaliableColors = async (productTypeID, setDefault = false) => {
        let _colors = [];
        setSelectedColor({ text: trans("form:color"), value: '' });
        //console.log("handleFetchModelAvaliableColors>>>>>:", setDefault);
        try {
            setContentLoading(true);

            const response = await axios.post(
                config.baseURL + 'func',
                {
                    "Name": "site_fetchColorOfTypeWithOutImages",
                    "Values": {
                        "Type": productTypeID,
                        "model": modelYear
                    }
                }
            );

            if (response.status == 200) {
                _colors = response.data.map(item => {
                    return { value: item.ColorID, text: item[`Color${trans("model:description")}`], buyOnline: item?.BuyOnline, ...item }
                });

                
                setColors(_colors);

                // setTimeout(() => {

                let _default = null;
                if (setDefault) {
                    // _default = _colors.find(item => item.value == defaultColorID) ?? null;
                    _default = _colors.find(item => item.buyOnline == true) ?? null;
                    //console.log("_default _colors>>>>>:", _default);
                    if (_default == null && _colors[0]) {

                        _default = _colors[0];
                    }

                    if (_default) {
                        filterPriceoffColor(_default);
                    }
                }


                // }, 1000)


            }


        } catch (error) {
            //console.log(error);
        }
        setContentLoading(false);
        return _colors;
    };



    const [cashCarImages, setCashCarImages] = useState({});
    const [outerCarImagesJson, setOuterCarImagesJson] = useState(null);
    const [isLoadingImages, setIsLoadingImages] = useState(false);
    const fetchCarDesign = async (activeColor, model, year) => {

       // console.log("activeColor>>>>>>>>>>>>>>>>>>>>>>>>>:", activeColor);
       // console.log("model>>>>>>>>>>>>>>>>>>>>>>>>>:", model);
        if (isLoadingImages == false) {

            const Namefiled = "frontImage,leftImage,backImage,sideImage,rightImage,mainColorImage"

            const prefix = 'data:image/png;base64,';
            if (model) {
                if (cashCarImages[model + "-part-" + 0]) {
                    setIsLoadingImages(false);
                    var AllCarImagesOUT = cashCarImages[model + "-part-" + 0];

                    setOuterCarImagesJson(AllCarImagesOUT);

                } else {
                    axios.post(
                        config.baseURL + 'func',
                        {
                            //SiteFetchCarColorImages
                            "Name": "SiteFetchCarColorCodeImages",
                            "Values": {
                                "Id": model,
                                "year": year,
                                // "ColorID": String(codeColor),
                                "ColorID": null,
                                "FieldNameList": null
                            }
                        }
                    ).then(response => {
                        setIsLoadingImages(false);
                        var maincolorimageIndex = 0;
                       // console.log("SiteFetchCarColorCodeImages responseData>>>>>>>>>>>>>>>>>>>>>>>>>:", response.data);
                        if (Array.isArray(response.data)) {
                            const responseData = response.data;
                            const groupResult = mapValues(groupBy(responseData, 'ColorCode'), fList => fList.map(items => omit(items, 'ColorCode')));

                           // console.log("responseData groupResult>>>>>>>>>>>>>>>>>>>>>>>>>:", groupResult);
                            setOuterCarImagesJson(groupResult);
                            cashCarImages[model + "-part-" + 0] = groupResult;
                            setIsLoadingImages(false);




                        }
                    }).catch(error => {
                       // console.log(error);
                        setIsLoadingImages(false);
                        // cashCarImages[model + "-part-" + 0] = {};
                    });

                }

            }
        }
    };
    return (
        <Popup show={displayConfirm}>
            {
                contentLoading ? <ContentLoading /> : (
                    <div className={"alert " + languageDir} ref={popupRef}>
                        <div className={`alert-box alert-box-brand-border-${brandType}`}>
                            <div className={`alert-box-brand-img p-2 alert-box-brand-img-${brandType} alert-box-brand-img-${lang == 'en' ? 'r' : 'l'}`}>
                                <img src={`/assets/icons/brands/${brandType}.webp`} className=""
                                    onError={e => e.target.style.display = 'none'} />
                                {/* <span className="text-white mt-2">{brand}</span> */}
                            </div>
                            <div className="nice-container">
                                <div className="nice-row">
                                    <div className="nice-col-md-6 order-2">
                                        <SectionHeading
                                            hasRuler={false}
                                            title={trans("new:select_model")}
                                            infoText={trans("new:select_model_note")}
                                        >
                                            <DropDownInput
                                                title={trans("info:vehicle_model")}
                                                defaultOption={defaultProductModel}
                                                options={[...productTypes]}
                                                onSelect={async (value) => {
                                                    if (value === '' || value === null || value === '0' || value === undefined) {
                                                        dropDownResetter(() => {
                                                            setColors([]);
                                                            setOnline(false);
                                                            setPriceColor(null);
                                                            setSelectedColor({ text: trans("form:color"), value: '' });
                                                        });
                                                    } else {

                                                        dropDownResetter(() => {
                                                            setColors([]);
                                                            setOnline(false);
                                                            setPriceColor(null);
                                                            setSelectedColor({ text: trans("form:color"), value: '' });
                                                        });

                                                        await handleFetchModelAvaliableColors(value, true);
                                                    }
                                                }}
                                                selectedValue={Cart?.modelType}
                                            />
                                            <div className="my-10">
                                                <DropDownInput
                                                    title={trans("form:color")}

                                                    options={[...colors]}
                                                    onfilter={filterPriceoffColor}
                                                    selectedValue={selectedColor?.value}
                                                />
                                            </div>
                                            <div className="flex flex-row flex-wrap gap-1 items-cente my-5">
                                                <div className="basis-50">
                                                    <SimpleButton classes="bg-black text-white text-20" title={trans("info:purchase_order")} onClick={handleCarOrderPurchase} />
                                                </div>
                                                <div className="basis-50">
                                                    {onLine ?
                                                        <SimpleButton iconPath={'/assets/icons/cart.svg'}
                                                            hint={trans("info:add_to_cart")}
                                                            alt={"add to cart"}
                                                            onClick={() => {
                                                                if (Cart) {

                                                                    var obb =
                                                                    {
                                                                        id: Cart?.Id,
                                                                        Type: 'car',
                                                                        colorId: Cart?.colorID
                                                                        , name: Cart?.modelTypeDesc
                                                                        , nameEn: Cart?.modelTypeDescEn
                                                                        , price: Cart?.Price
                                                                        , brand: Cart?.brand
                                                                        , brandEn: Cart?.brandEn
                                                                        , Tax: Cart?.Tax
                                                                        , image: Cart?.image
                                                                        , Discount: Cart?.Discount
                                                                        , productID: Cart?.ProductID
                                                                        , group: Cart?.group
                                                                        , groupEn: Cart?.group
                                                                        , BrandID: Cart?.BrandID
                                                                        , modelTypes: Cart?.modelTypeDesc
                                                                        , modelTypesEn: Cart?.modelTypeDescEn
                                                                        , colorName: Cart?.colorName
                                                                        , colorNameEn: Cart?.colorNameEn
                                                                        , year: Cart?.year
                                                                        , modelTypeID: Cart.modelType
                                                                        , MiniDownPayment: Cart?.MiniDownPayment
                                                                    }

                                                                    changecartItem(Cart.ProductID, Cart.colorID, Cart.modelType, 1, null, null, obb, null, Cart?.year);
                                                                    // addToCart(
                                                                    //     {
                                                                    //         id: Cart.Id,
                                                                    //         Type: 'car',
                                                                    //         colorId: Cart.colorID
                                                                    //         , name: 'Cart1'
                                                                    //         , price: Cart.Price
                                                                    //         , Tax: Cart.Tax
                                                                    //         , Discount: Cart.Discount
                                                                    //         , ProductID: Cart.ProductID
                                                                    //         , group: Cart.group
                                                                    //         , BrandID: Cart.BrandID
                                                                    //         , modelTypes: Cart.modelType
                                                                    //         , MiniDownPayment: Cart.MiniDownPayment
                                                                    //     }
                                                                    // );
                                                                }
                                                                onClose();
                                                            }}
                                                        />

                                                        : <div className="text-16 flex nice-center mt-5">
                                                            {trans("info:not_avaliable")}
                                                        </div>
                                                        // <FormButton name={trans("info:add_to_cart")} onClick={() => {
                                                        //     if (Cart) {
                                                        //         addToCart(
                                                        //             {
                                                        //                 id: Cart.Id,
                                                        //                 Type: 'car',
                                                        //                 colorId: Cart.colorID
                                                        //                 , name: 'Cart1'
                                                        //                 , price: Cart.Price
                                                        //                 , Tax: Cart.Tax
                                                        //                 , Discount: Cart.Discount
                                                        //                 , ProductID: Cart.ProductID
                                                        //                 , group: Cart.group
                                                        //                 , BrandID: Cart.BrandID
                                                        //                 , modelTypes: Cart.modelType
                                                        //                 , MiniDownPayment: Cart.MiniDownPayment
                                                        //             }
                                                        //         );
                                                        //     }
                                                        //     onClose();
                                                        // }
                                                        // } /> : <div className="text-16 flex nice-center mt-5">
                                                        //     {trans("info:not_avaliable")}
                                                        // </div>
                                                    }
                                                </div>
                                            </div>
                                        </SectionHeading>
                                    </div>
                                    <div className="nice-col-md-6 mt-4 flex flex-col justify-around order-1">
                                        <div className="alert-box-header nice-row-center p-5">

                                            {outerCarImagesJson &&

                                                outerCarImagesJson[selectedColor?.value?.split("/")[0]] &&
                                                <>
                                                    <div className="text-14 text-black text-bold mb-3">{trans("info:main_car")}</div>
                                                    <img src={config.FilePathURL + outerCarImagesJson[selectedColor?.value?.split("/")[0]][0]?.Image_URL} alt="hassan jameel carts " />
                                                </>


                                            }
                                        </div>
                                        <div className="alert-box-header">

                                            {Cart?.modelTypeDesc && <div className="alert-box-header-show  my-1">
                                                <span className="text-inline-block text-bold text-16">{trans("info:veh_type")}:</span>
                                                <span className="text-inline-block text-16 mx-2">{lang == "ar" ? Cart?.modelTypeDesc : Cart?.modelTypeDescEn} {(Cart?.colorName || Cart?.colorNameEn) ? (" ( " + (lang == "ar" ? Cart?.colorName : Cart?.colorNameEn) + " )") : ""}</span>

                                            </div>
                                            }



                                            {PriceColor && <div className="alert-box-header-show  my-2">
                                                <span className="text-inline-block text-bold text-16">{trans("info:price")}:</span>
                                                <span className="text-inline-block text-20 mx-2 font-bold text-primary"><Price price={PriceColor} className={"font-bold text-primary"} /></span>
                                            </div>
                                            }

                                            {
                                                <div className="mx-2 mb-5 nice-flex nice-flex-space">
                                                    <img src='/assets/icons/info.svg' className="mx-3 text-inline-block nice-max-icon" alt="hassan jameel info " />
                                                    <p className="text-12 text-bold text-gray text-inline-block">{"السعر يشمل اللوحات والاستمارة ورسوم استهلاك الوقود وضريبة القيمة المضافة."}</p>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                )
            }
        </Popup>
    )
}

export default PurchaseConfirm;