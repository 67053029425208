import Popup from "../User/popup";
import SignIn from "../User/signIn";
import SignUp from "../User/signUp";
import ResetPassword from "../User/resetPassword";
import LoginValidation from "../User/loginValidation";
import PopupHeader from "../User/components/popupHeader";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
function Registrationpup({
    displaypup = false, handlePopupStatusChange
}) {
    const { t: trans, i18n } = useTranslation(["common", "form", "language", "info"]);
    let lang = (i18n.language || 'ar');
    const [showPopup, setShowPopup] = useState(false);
    const [activePopUpModel, setActivePopUpModel] = useState({
        tab: 1,
        title: 'sign-in'
    });
    const handlePopupStatus = (status) => {
        setShowPopup(status);
        handlePopupStatusChange(status);
    };

    const handleTabChange = (tab) => {
        console.log('New tab is', tab);
        setActivePopUpModel(tab);
    };


    useEffect(async () => {

        setShowPopup(displaypup);

    }, [displaypup]);
    return (
        <Popup show={showPopup}>
            <div className='popup-model flex flex-row w-70 bg-primary'>
                {
                    activePopUpModel.tab == 1 ?
                        <SignIn onTabChange={(tab) => { handleTabChange(tab) }} onLogin={() => handlePopupStatus(false)} />
                        : activePopUpModel.tab == 2 ?
                            <SignUp onTabChange={(tab) => { handleTabChange(tab) }} onLogin={() => handlePopupStatus(false)} />
                            : activePopUpModel.tab == 3 ?
                                <ResetPassword onTabChange={(tab) => { handleTabChange(tab) }} />
                                : <LoginValidation onTabChange={(tab) => { handleTabChange(tab) }} onLogin={() => handlePopupStatus(false)} />

                }
                <div className='popup-close'>

                    <span className='popup-close-icon' onClick={() => handlePopupStatus(false)}>
                        {/* <span className='text-30 '>&times;</span> */}
                        <svg
                            viewBox="0 0 512 512"
                            fill="currentColor"
                            height="4rem"
                            width="4rem"
                        >
                            <path
                                fill="none"
                                stroke="currentColor"
                                strokeMiterlimit={10}
                                strokeWidth={32}
                                d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"
                            />
                            <path
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={32}
                                d="M320 320L192 192M192 320l128-128"
                            />
                        </svg>                        </span>
                    <span className='text-20 mx-5'>{lang == "ar" ? "اغلاق" : "clos"}</span>
                </div>
                <PopupHeader welcomeTitle={trans("common:say-hello")} subTitle={trans("common:" + activePopUpModel.title)} closePopUp={() => handlePopupStatus(false)} />

            </div>
        </Popup>
    )
}

export default Registrationpup;