import { useEffect, useState } from "react";
import { GoogleMap, LoadScript, Marker, InfoWindow } from "@react-google-maps/api";


export function Map({ latitude, longitude, name, content, markers = [], multiMarkers = false }) {
    let googleMapURL = "https://maps.googleapis.com/maps/api/js?key=AIzaSyAN_OIK0AzQyINgni_VzR23kh-ytSgiku0";


    const [currentPosition, setCurrentPosition] = useState({});
    const [multPositions, setMultiPositions] = useState([]);
    const [toggle, setToggle] = useState(false);


    // function changeMapLanguage(){
    //     return  googleMapURL+=`&language=${lang}`;
    // }

    const success = position => {


        const currentPosition = {
            lat: parseFloat(latitude) ?? position.coords.latitude,
            lng: parseFloat(longitude) ?? position.coords.longitude
        }

        if (currentPosition?.lat && currentPosition?.lng)
            setCurrentPosition(currentPosition);
    };

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(success);

    }, [latitude, longitude])



    useEffect(() => {
        if (multiMarkers && markers && markers.length > 0) {

            const mm = markers.map(e => {


                var coords = "";
                try {
                    const { latitude, longitude } = e;
                    coords = { lat: parseFloat(latitude), lng: parseFloat(longitude) };
                } catch (error) {

                }

                return (

                    { lat: e.latitude, lng: e.longitude, coords: coords, content: e.content, name: e.name }
                )



            })

            setMultiPositions(mm);
            if (mm && mm.length > 0) {

                navigator.geolocation.getCurrentPosition((position) => {

                    const currentPosition = {
                        lat: parseFloat(mm[0].lat),
                        lng: parseFloat(mm[0].lng)
                    }
                    if (currentPosition?.lat && currentPosition?.lng)
                        setCurrentPosition(currentPosition);
                });


            }


        }
    }, [markers, multiMarkers]);

    // useEffect(() => {
    //     navigator.geolocation.getCurrentPosition((position) => {

    //         const currentPosition = {
    //             lat: position.coords.latitude,
    //             lng: position.coords.longitude
    //         }

    //         if (currentPosition?.lat && currentPosition?.lng)
    //             setCurrentPosition(currentPosition);
    //     });
    // }, []);

    const handleToggle = (name) => {

        if (name == null)
            setToggle(null);
        else
            setToggle(name);
    };


    const mapStyles = {
        height: "100%",
        width: "100%"
    };


    const infoWindowStyle = {
        backgroundColor: '#fff',
        color: '#000',
        textAlign: 'center',
        minWidth: '100px',
        fontSize: 18,
        fontWeight: 'bold'
    };

    const defaultCenter = {
        lat: 41.3851, lng: 2.1734
    }
    const [selected, setSelected] = useState({});

    const onSelect = item => {
        setSelected(item);
    }

    return (

        <GoogleMap
            mapContainerStyle={mapStyles}
            zoom={8}
            center={currentPosition?.lat ? currentPosition : null}
            clickableIcons={false}
            options={
                {
                    panControl: false,
                    mapTypeControl: false,
                    fullscreenControl: false,
                    streetViewControl: false,
                    zoomControl: false,
                }

            }
        >

            {
                !multiMarkers &&
                (
                    <Marker position={currentPosition?.lat ? currentPosition : null} key={name ?? ''} onClick={()=>handleToggle(name)}>
                        {
                            toggle && <InfoWindow
                                position={currentPosition?.lat ? currentPosition : null}
                                clickable={true}
                                onCloseClick={()=>handleToggle(null)}>
                                <div style={infoWindowStyle}>
                                    {latitude != '' ? content : 'Me'}
                                </div>
                            </InfoWindow>
                        }
                    </Marker>
                )
            }
            {
                multiMarkers && multPositions.map((item, index) => {

                    return (

                        item?.coords &&
                        <Marker position={item?.coords}
                            key={item?.name}
                            label={index.toString()}
                            onClick={()=>handleToggle(item?.name)}
                        >
                            {
                                toggle && item?.name == toggle && <InfoWindow
                                    position={item?.coords}
                                    clickable={true}
                                    onCloseClick={()=>handleToggle(null)}>
                                    <div style={infoWindowStyle}>
                                        {item?.content}
                                    </div>
                                </InfoWindow>
                            }
                        </Marker>
                    )
                })
            }
        </GoogleMap>
    )

}

