import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FormButton } from "../../../components/_input/_button";
import { InputField } from "../../../components/_input/_input";
import { Price } from "../../../components/__simple/_price";
const CouponControl = ({ couponCode = null, discountPrice = null, reset = false, onReset, onSubmit }) => {
    const { t: trans } = useTranslation("new");
    const [codeValue, setCodeValue] = useState('');
    return (
        <div className="cart-copon cart-copon-module">
            <div className="cart-copon-details  ">
                <div className="nice-flex nice-flex-column">
                    <div className="nice-flex nice-flex-row nice-align-item-center">
                        <div className="cart-copon-button">
                            <FormButton
                                name={trans("new:applay")}
                                 classes={"px-5 py-2"}
                                type="button"
                                onClick={async () => {
                                    if (onSubmit) {
                                        onSubmit(codeValue);
                                    }
                                }}
                                isPrimary={codeValue ? true : false}
                            />
                        </div>

                        <div className="nice-flex-1">
                            <InputField
                                value={codeValue}
                                secondary={true}
                                moduleLabel={trans("new:cobon")}
                                placeholder={trans("new:cobon")}
                                extraClasses={"Input-module"}
                                onChangeValue={(e) => {
                                    setCodeValue(e.target.value);
                                }} />
                        </div>
                        {reset &&
                            <div
                                className="cart-copon-delete"
                                onClick={() => {
                                    if (onReset) {
                                        onReset();
                                        setCodeValue('');
                                    }
                                }}>
                                &times;
                            </div>
                        }
                    </div>
                    {reset && <div className="nice-flex nice-flex-column px-6 nice-gap-1rem">
                        <div className="nice-flex nice-flex-row nice-align-item-center">
                            <img src='/assets/icons/coupon.png' />
                            <span className="text-gray text-14">{couponCode ?? ''}</span>
                        </div>
                        <h2 className="text-14">{trans("new:disscount_done")}</h2>
                        <div className="text-14 text-primary mb-2">{trans("new:disccount_note")}<span className="font-bold mx-1 text-14"><Price price={discountPrice} /></span></div>
                    </div>
                    }
                </div>
            </div>
        </div>
    )
};
export default CouponControl;