import { Fragment, useEffect, useRef, useState, useContext } from "react";
import { DropDownContext } from "../../pages/eparts/components/partSearch";
import ErrorLabel from '../../components/error';
import Skeleton from 'react-loading-skeleton'
import { InputField } from "../_input/_input"
import { useTranslation } from "react-i18next";
export function DropDownInput({
    styles,
    title,
    placeholder,
    disabled = false,
    className,
    isSecondary,
    inputName,
    options = [],
    onSelect,
    fieldTextClass,
    classDropdownField,
    defaultOption = null,
    onfilter,
    isepart,
    isLoading = false,
    selectedValue = null,
    errorLabel,
    extraClasses,
    force = false, roundedFull = ''
}) {

    const { t: trans, i18n } = useTranslation(["info"]);
    let lang = (i18n.language || 'ar');
    let languageDir = "language-" + lang;
    const optionsRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState((defaultOption != null && defaultOption != undefined) ? defaultOption : { text: '', value: '' });
    const [filterOptions, setfilterOptions] = useState(options);

    useEffect(() => {

        // console.log(">>>>>>>>>>>>>>>>>>options:",options);
        setfilterOptions(options);

        if (options != null && options.length > 0) {
            funSelectValueOption();
        }

    }, [options]);
    useEffect(() => {

        /**
         * if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (optionsRef.current && !optionsRef.current.contains(event.target) && isOpen === true) {
                setIsOpen(false);
            }
        }
        // if(placeholder != null || placeholder != undefined){
        //     alert(placeholder);
        //     let resultValue = options.find((e)=>e.value === placeholder);
        //     selectOption(resultValue); 
        //     }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [optionsRef, isOpen]);



    function selectOption(option) {
        if (onSelect) {
            const val = option.value !== '0' ? option.value : '';
            onSelect(val);
        }
        setIsOpen(false);
        setSelectedOption(option);
        if (onfilter)
            if (defaultOption != option.text)
                onfilter(option);
        // if (isepart)
        //onfilter(option);

    }

    const funSelectValueOption = () => {
        // console.log("options>>>>>>>>>>>>>",title ,": ",options);
        // console.log("selectedValue>>>>>>>>>>>>>",selectedValue);

        if (selectedValue == null || selectedValue == undefined || selectedValue == '') {
            setSelectedOption((defaultOption != null && defaultOption != undefined)
                ? defaultOption
                : { value: "0", text: placeholder ? placeholder : (lang == 'en' ? 'Choose value' : 'إختيار قيمة') }

            );
        } else {

            var vv = options.find(item => item?.value == selectedValue) ?? null;
            setSelectedOption(vv ? vv : { text: '', value: '' });
        }
    }

    useEffect(() => {

        funSelectValueOption();

    }, [selectedValue]);

    return (

        <Fragment>

            {
                inputName ? (
                    <input type="hidden" value={selectedOption.value} />
                ) : null
            }

            <div title={placeholder} className={`dropdown  ${extraClasses} ` + languageDir + ' ' + className}>

                {
                    title &&
                    <div className={"dropdown-label " + (isSecondary && "secondary")} title={selectedOption?.text}>{title}
                    </div>
                }


                <div ref={optionsRef} className={`dropdown-input    ${roundedFull}`}>
                    <div className={"dropdown-field " + classDropdownField + " " + (isSecondary && "secondary") + " " + (disabled && " bg-gray-lighter ")} style={styles ? styles : null} onClick={() => {
                        if (!disabled)
                            setIsOpen(!isOpen);
                    }}>
                        <div className={"dropdown-field-text " + fieldTextClass} title={selectedOption?.text}>
                            {

                                selectedOption.text

                            }


                        </div>
                        {

                            isLoading ? <span className='mx-2 loading-btn'>
                                <img src='/assets/icons/loading-dark.svg' color="#121212" width='24' height='24' alt={""} />
                            </span>
                                : null
                        }
                        <div className={"dropdown-field-arrow " + (isOpen && "invert")}>
                            <img src="/assets/icons/arrow.svg" alt="arrow" />
                        </div>

                    </div>
                    <ErrorLabel errorTitle={errorLabel} />
                    {

                        isLoading ?
                            <div className={"dropdown-options secondary-theme " + (isOpen || "hidden")}>
                                <ul>
                                    <li>
                                        <Skeleton count={1} borderRadius={"1rem"} width={"100%"} height={"3rem"}
                                            containerClassName={"w-100"} className={"flex"} inline={true} />
                                    </li>
                                    <li>
                                        <Skeleton count={1} borderRadius={"1rem"} width={"100%"} height={"3rem"}
                                            containerClassName={" w-100"} className={"flex"} inline={true} />
                                    </li>
                                    <li>
                                        <Skeleton count={1} borderRadius={"1rem"} width={"100%"} height={"3rem"}
                                            containerClassName={" w-100"} className={"flex"} inline={true} />
                                    </li>

                                </ul>
                            </div>
                            :

                            !disabled && <div className={"dropdown-options " + (isOpen || "hidden")}>

                                {
                                    filterOptions && options && options.length > 10 &&
                                    < InputField
                                        extraClasses={""}
                                        placeholder={trans("info:search_now")}
                                        onChangeValue={(value) => {
                                            if (value.target.value && options) {
                                                const filterData = options?.filter(a => a?.text?.includes(value.target.value));
                                                if (filterData)
                                                    setfilterOptions(filterData);
                                            } else {
                                                setfilterOptions(options);
                                            }

                                        }} />

                                }
                                <ul>
                                    {
                                        filterOptions?.map((option, index) => {

                                            return (
                                                <li key={option?.value + "-" + index} onClick={() => {
                                                    selectOption(option);
                                                    //  const val = option.value !== '0'? option.value : '';
                                                    //  onSelect(val);
                                                }} className={(((index % 2) == 0) ? "  bg-gray-lighter " : "") + ((option && selectedOption && option?.value == selectedOption?.value) ? "  bg-gray-light " : "")} >
                                                    <span>{option.text}</span>
                                                    {(option?.buyOnline == true || option?.buyOnline == 1) && <span className={"bg-primary tag text-white"}> {trans("info:Available_for_purchase_online")}</span>}
                                                </li>
                                            );

                                        }
                                        )
                                    }

                                </ul>
                            </div>
                    }


                </div>

            </div>

        </Fragment>
    );
}
