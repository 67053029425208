import { useTranslation } from "react-i18next";
import { DropDownInput } from "../../../components/_input/_dropdown";
import { useEffect, useState } from "react";
import { FinanceRange } from "./financeRange";
import axios from "axios";
import _ from 'lodash';
import config from '../../../configuration/config.json';
import { InputField } from "../../../components/_input/_input";
import dropDownResetter from "../../../utils/resetDropDown";
import { Swiper, SwiperSlide } from "swiper/react";
import { toast } from "react-toastify";
export function FinanceSearch({ onBankSelected, onQuantitySearchClick, onActiveMenu, onSelectedProduct, onBudgetChange, onSetBanks, onsetDefaultperiod, selectionBank, ...props }) {
    const { t: trans,i18n } = useTranslation(["common", "inputs", "finance", "model"]);
    let lang =  (i18n.language || 'ar');
    const languageDir = "language-" + lang;
    const BANK_KEY = 'Bank_ID';
    const STATUS_KEY = 'Status';

    const [active, setActive] = useState('');
    const [model, setModel] = useState("hidden");
    const [balance, setPart] = useState("hidden");
    const [monthly, setMonthly] = useState("");
    const [activeMenu, setActiveMenu] = useState(1);
    const [banks, setBanks] = useState([]);
    const [brands, setBrands] = useState([]);
    const [groups, setGroups] = useState([]);
    const [fGroups, setFGroups] = useState([]);
    const [isLoadingBanks, setLoadingBanks] = useState(true);
    const [modelSearch, setModelSearch] = useState({
        brandID: '',
        groupID: '',
        subCategoryID: ''
    });
    const [subCategories, setSubCategories] = useState([]);
    const [fSubCategories, setFSubCategories] = useState([]);
    const [budget, setBudget] = useState({});
    const [quantityRange, setQuantityRange] = useState({
        maxFinanceAmount: 5000,
        maxFinancePeriod: 24,
        lastBatch: 40000
    });

    const [selectOneBankDefoliate, setSelectOneBankDefoliate] = useState({});


    const initialBanksData = async (active = true) => {

        setLoadingBanks(true);

        try {

            if ("caches" in window) {
                let url = "SiteFetchActiveBankWithFinanceSettings";

                // Opening that particular cache
                const cacheStorage = await caches.open("HJWeb");
                // Fetching that particular cache data
                const cachedResponse = await cacheStorage.match(
                    url
                );
                let data = await cachedResponse?.json();
                setBanks(data);
                setSelectOneBankDefoliate(data[0])

                setLoadingBanks(false);
            }

        } catch (error) {
        }
        axios.post(
            config.baseURL + "func",
            {
                "name": "SiteFetchActiveBankWithFinanceSettings"
            }
        ).then(response => {
            const result = response.data;
            //console.log("result", result)
            setBanks(result);
            setSelectOneBankDefoliate(result[0]);

            setLoadingBanks(false);
            if (onSetBanks)
                onSetBanks(result);

            const data = new Response(JSON.stringify(result));

            if ("caches" in window) {
                // Opening given cache and putting our data into it
                caches.open("HJWeb").then((cache) => {
                    cache.put("SiteFetchActiveBankWithFinanceSettings", data);

                });
            }
        }).catch(error => {
            //console.log(error);
            setLoadingBanks(false);
        });
    };

    useEffect(() => {
        if(selectOneBankDefoliate && selectOneBankDefoliate.GUID){
            handleActiveBank(selectOneBankDefoliate);
        }
    }, [banks, selectOneBankDefoliate]);


    useEffect(() => {

        initialBanksData();

        axios.request({
            baseURL: config.controllURL,
            url: lang + "/api/finance/index",
            method: "get",
        }).then(response => {
            const result = response.data;

            const customizedResult = result?.brands?.map(row => {
                return {
                    value: row.BrandID,
                    text: row.Description
                }
            });
            setBrands(customizedResult);
            //------------------------------------------------
            setGroups(result?.groups);
            //------------------------------------------------
            setSubCategories(result?.modelTypes);
        }).catch(err => {
            //console.log(err);
        })
    }, []);


    useEffect(() => {

        if (selectionBank) {

            show(activeMenu == 1 ? "monthly" : (activeMenu == 2 ? "model" : "balance"), activeMenu);
        }
    }, [selectionBank]);

    const handleRefechData = () => {

        //console.log("handleRefechData>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.");
        if (selectionBank) {

            show(activeMenu == 1 ? "monthly" : (activeMenu == 2 ? "model" : "balance"), activeMenu);
        }
    };
    props.onHandleRefechData(handleRefechData);

    const handleActiveBank = (bankSelected) => {

        if (active != bankSelected?.GUID) {
            //console.log("active:" + active)
            localStorage.setItem(BANK_KEY, bankSelected?.GUID);
            setActive((prevState) => {
                return prevState !== bankSelected?.GUID ? bankSelected?.GUID : null
            });
            localStorage.setItem(STATUS_KEY, active !== bankSelected?.GUID ? bankSelected?.GUID : '')
            if (onBankSelected) {
                const status = localStorage.getItem(STATUS_KEY);
                onBankSelected(bankSelected);
            }
            //------------------------
            var DefaultFinancePeriod =24;
            if(bankSelected?.DefaultFinancePeriod){
                DefaultFinancePeriod =bankSelected?.DefaultFinancePeriod;
             

            }
            const val = { ...quantityRange };
                val.maxFinancePeriod = DefaultFinancePeriod;
                setQuantityRange(val);
                if (onsetDefaultperiod)
                    onsetDefaultperiod(val.maxFinancePeriod)

        }
    };



    // It's used to initialize the products
    const handleFilterGroups = (option) => {
        if (groups) {
            const filteredValues = groups.filter(e => e.BrandId === option.value);
            setFGroups(filteredValues.map(e => {
                return { value: e.ProductGroupId, text: e.Description }
            }));
        }
    };
    // It's used to initialize the sub categories
    const handleFilterSubGategories = (option) => {
        if (subCategories) {
            const filteredValues = subCategories.filter(e => e.ProductGroupId == option.value);
            setFSubCategories(filteredValues.map(e => {
                return { value: e.ProductTypeId, text: e.Description }
            }));
        }
    };
    function show(section, index) {


        setMonthly("hidden");
        setModel("hidden");
        setPart("hidden");
        switch (section) {
            case "monthly":
                setMonthly("");
                break;
            case "model":
                setModel("");
                break;
            case "balance":
                setPart("");
                break;
            default:
                setMonthly("");
        }

        setActiveMenu(index);

        if (onActiveMenu)
            onActiveMenu(index);

        // setTimeout(() => {

        if (index == 1) {
            if (onQuantitySearchClick) {
                // onLoadingInfo(true);
                onQuantitySearchClick(quantityRange, index, null);
            }

        } else if (index == 2) {
            if (onSelectedProduct) {
                // onLoadingInfo(true);
                onSelectedProduct(modelSearch, index, null)
            }

        } else if (index == 3) {

            if (onBudgetChange) {
                // onLoadingInfo(true);
                onBudgetChange(budget, index, null);
            }
        }

        // }, 1000)



    }


    return (
        <div className="container mb-20 ">

            <h3 className={"text-center text-30 mb-8"}>{trans("finance:choose_bank")}</h3>

            <div className={" mb-40  " + languageDir}>
                <Swiper
                    spaceBetween={5}
                    slidesPerView={3}
                    pagination
                    navigation
                    className={"swiper-pagination-2"}
                    resizeObserver={true}

                    touchRatio={1}
                    initialSlide={1}
                    
                    breakpoints={{
                        576: {
                            slidesPerView: 5,
                            spaceBetween: 10,
                            initialSlide:2

                        },
                    }}
                    grabCursor={true}
                    autoplay={false}
                    loop={false}
                    observer={true}
                    centeredSlides={true}
                >
                    {
                        banks?.map((bank, index) => {
                            return (
                                <SwiperSlide key={index} className={""}>
                                    <div key={index} className={"finance-search-bank " + `${(active === bank.GUID && "active")}`}
                                        onClick={() => { handleActiveBank(bank) }} title={lang == "ar" ? bank.DescriptionAr : bank.DescriptionEn}>
                                        <picture>
                                            <source media="(max-width: 576px)" srcSet={bank.mobileImage} />
                                            <img className={"lazyload object-full object-contain"} data-src={bank.Image ? config.mainURL + bank.Image : "/assets/images/default.png"}
                                                alt={lang == "ar" ? bank.DescriptionAr : bank.DescriptionEn} loading={"lazy"}
                                        
                                            />

                                        </picture>
                                        <span className={"text-center text-20 mb-8"} >{lang == "ar" ? bank.DescriptionAr : bank.DescriptionEn}</span>
                                    </div>
                                </SwiperSlide>
                            );
                        })
                    }


                </Swiper>
            </div>

            {/* <div className={"finance-search mb-20 hidden-scroll-horizontally " + languageDir}>

                {
                    banks.map((bank, index) => {
                        return (
                            <div key={index} className={"finance-search-bank " + `${(active === bank.GUID && "active")}`}
                                onClick={() => { handleActiveBank(bank) }} title={lang == "ar" ? bank.DescriptionAr : bank.DescriptionEn}>
                                <picture>
                                    <source media="(max-width: 576px)" srcSet={bank.mobileImage} />
                                    <img className={"object-full object-contain"} data-src={bank.Image ? "data:image/png;base64," + bank.Image : "/assets/images/default.png"}
                                        alt={lang == "ar" ? bank.DescriptionAr : bank.DescriptionEn} />
                                </picture>
                            </div>
                        );
                    })
                }

            </div> */}
            <div className={"filter-search-btns filter-search-btns-3 mb-8"}>
                <button onClick={() => show("monthly", 1)} className={"text-20 " + monthly}>{trans("finance:monthPayment")}</button>
                <button onClick={() => show("model", 2)} className={"text-20 " + model}>{trans("finance:model")}</button>
                <button onClick={() => show("balance", 3)} className={"text-20 " + balance}>{trans("finance:budget")}</button>
            </div>

            <div className={"filter-search-container "}>

                <div className={"flex nice-align-item-center items-stretch " + monthly}>
                    <div className="filter-search-grid filter-search-2 ml-4">

                        <div>
                            <FinanceRange
                                placeHolder={trans("finance:monthPaymentEnter")}
                                suffix={trans("finance:rialCurency")}
                                min={500}
                                max={10000}
                                step={50}
                                firstValue={quantityRange?.maxFinanceAmount}
                                onChangeValue={(value) => {
                                    const val = { ...quantityRange };
                                    val.maxFinanceAmount = value[0];
                                    setQuantityRange(val);
                                }}
                            />
                        </div>

                        <div>
                            <FinanceRange
                                placeHolder={trans("finance:finance_peroid") + " (" + trans("finance:byMonth") + " )"}
                                suffix={trans("finance:month")}
                                min={24}
                                max={60}
                                step={1}
                                firstValue={quantityRange?.maxFinancePeriod}
                                onChangeValue={(value) => {
                                    const val = { ...quantityRange };
                                    val.maxFinancePeriod = value[0];
                                    setQuantityRange(val);
                                    if (onsetDefaultperiod)
                                        onsetDefaultperiod(val.maxFinancePeriod)
                                }}
                            />
                        </div>

                        <div>
                            <FinanceRange
                                placeHolder={trans("finance:last_batch")}
                                suffix={trans("finance:rialCurency")}
                                min={20000}
                                max={250000}
                                step={50}
                                firstValue={quantityRange?.lastBatch}
                                onChangeValue={(value) => {
                                    const val = { ...quantityRange };
                                    val.lastBatch = value[0];
                                    setQuantityRange(val);
                                }}
                            />
                        </div>


                    </div>
                    <button className={"btn btn-primary text-16 py-3 px-10 mr-4 mt-10 mb-10"} onClick={
                        () => {
                            if (onQuantitySearchClick) {
                                if (active == null || active === '') {
                                    toast.warn(trans("finance:choose_bank"));
                                    return;
                                }
                                onQuantitySearchClick(quantityRange, activeMenu);
                            }
                        }
                    }>{trans("common:search")}</button>
                </div>


                <div className={"flex items-end nice-align-item-center " + languageDir + ' ' + model}>
                    <div className="filter-search-grid filter-search-1 mx-5">
                        <DropDownInput
                            isSecondary={true}
                            title={trans("finance:trade_mark")}
                            options={[...brands]}
                            onfilter={handleFilterGroups}
                            onSelect={(value) => {
                                const val = { ...modelSearch };
                                val.brandID = value;
                                if (value == '' || value == '0' || value == null) {
                                    dropDownResetter(() => {
                                        setFGroups([]);
                                        setFSubCategories([]);
                                    });
                                    val.groupID = '';
                                    val.subCategoryID = '';
                                }
                                setModelSearch(val);
                            }}

                            selectedValue={modelSearch?.brandID}
                        />
                    </div>
                    <div className="filter-search-grid filter-search-1">
                        <DropDownInput
                            title={trans("finance:group")}
                            selectedValue={modelSearch?.groupID}
                            isSecondary={true}
                            options={[...fGroups]}
                            onfilter={handleFilterSubGategories}
                            onSelect={(value) => {
                                const val = { ...modelSearch };
                                if (value == '' || value == '0' || value == null) {
                                    dropDownResetter(() => {
                                        setFSubCategories([]);
                                    });
                                    val.subCategoryID = '';
                                }
                                val.groupID = value;
                                setModelSearch(val);
                            }}

                        />
                    </div>
                    <div className="filter-search-grid filter-search-1 mx-5">
                        <DropDownInput
                            title={trans("finance:sub_category")}
                            selectedValue={modelSearch.subCategoryID}
                            isSecondary={true}
                            options={[...fSubCategories]}
                            onSelect={(value) => {
                                const val = { ...modelSearch };
                                val.subCategoryID = value;
                                setModelSearch(val);
                            }}
                        />
                    </div>
                    <button className={"btn btn-primary text-16 py-3 mb-14 px-10 mr-4"}
                        onClick={
                            () => {
                                if (onSelectedProduct) {
                                    onSelectedProduct(modelSearch, activeMenu)
                                }
                            }
                        }
                    >{trans("common:search")}</button>
                </div>

                <div className={"flex items-end nice-align-item-center " + languageDir + ' ' + balance}>
                    <div className="filter-search-grid filter-search-1">
                        <InputField
                            placeholder={trans("finance:max_quantity_amount")}
                            title={trans("finance:amount")}
                            type='number'
                            onChangeValue={(e) => {
                                const val = e.target.value;
                                setBudget({
                                    budget: val
                                });
                            }}
                        />
                    </div>

                    <button className={"btn btn-primary text-16 py-3 px-10 mr-4"}
                        onClick={
                            () => {
                                if (onBudgetChange)
                                    onBudgetChange(budget, activeMenu);
                            }
                        }
                    >{trans("common:search")}</button>

                </div>

            </div>
        </div>
    );
}



