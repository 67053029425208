import { useEffect, useState, useCallback } from "react";
import { FormButton } from "../components/_input/_button";
import { SwiperSlide, Swiper } from 'swiper/react';
import axios from "axios";
import { useTranslation } from "react-i18next";
import Popup from "./popup";
import SwiperCore, { Pagination, Navigation, Autoplay, EffectFade } from "swiper";
import PopupHeader from "./components/popupHeader";
import { InputField } from "../components/_input/_input";
import SectionHeader from "./components/seactionHeader";
import SectionHeading from "../components/sectionHeading";
import CarUser from "./components/CarUser";
import { DropDownInput } from "../components/_input/_dropdown";
import Joi, { string } from "joi";
import { formValidate, GetMessages } from "../utils/validation";
import config from "../configuration/config.json";
import { toast } from "react-toastify";
import { CarColorPicker } from "../pages/vehicles/components/colorPicker";
import { findIndex, includes, isEqual, join, reverse, split, uniq, uniqBy, uniqWith } from "lodash";
import ErrorLabel from "../components/error";
import { getData, User_Data_Key } from "../utils/localStorageManager";
import dropDownResetter from "../utils/resetDropDown";
SwiperCore.use([Pagination, Navigation, Autoplay, EffectFade,]);
export default function UserVehicle(props) {
    const { t: trans,i18n } = useTranslation(["common", "inputs", "offers", "home", "eparts", "user", "info", "hints"]);
    const lang = (i18n.language || 'ar');
    const langaugeDir = 'language-' + lang;
    const dir = lang == 'en' ? 'left' : 'right';
    const otherLang = lang == 'en' ? 'ar' : 'en';
  
    const [color, setColor] = useState(null);
    const [error, setError] = useState({});
    const [standardColors, setStandardColors] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [selectedModel, setSelectedModel] = useState(null);
    const [isLoadingForAdd, setIsLoadingForAdd] = useState(false);
    const [isLoadingForEdit, setIsLoadingForEdit] = useState(false);

    const [requestForm, setRequestForm] = useState({
        brand: null,
        group: null,
        private_name: '',
        color: null,
        board_No: '',
        structure_no: '',
        year: null,
        productID: null,
        selectedColor: null,
        image: null
    });
    const [requestUpdateForm, setRequestUpdateForm] = useState({
        id: '',
        brand: '',
        group: '',
        private_name: '',
        color: '',
        board_No: '',
        structure_no: '',
        year: null,
        productID: '',
        selectedColor: '',
        image: null
    });
    const [vehicleCurrentData, setVehicleCurrentData] = useState({
        brand: {},
        group: {},
        model: {},
        year: {}
    });
    const [requestFormNewModel, setRequestFormNewModel] = useState({
        newModel: null
    });
    const schema2 = Joi.object({
        newModel: Joi.string().required().messages(GetMessages())
    });
    const [error2, setError2] = useState(null);
    const [carBoardNo, setCarBoardNo] = useState({
        ar: '',
        en: ''
    });
    const [otherLangBoardNo, setOtherBoardNo] = useState('');
    const schema = Joi.object({
        productID: Joi.string().required().messages(GetMessages()),
        board_No: Joi.string().required().messages(GetMessages()),
        structure_no: Joi.string().length(17).required().messages(GetMessages()),
        group: Joi.string().required().messages(GetMessages()),
        brand: Joi.string().required().messages(GetMessages())
    });
    //--------------------------------------------------------

    //values for filter dropdown
    const [Brands, setBrands] = useState([]);
    const [ProductGroups, setProductGroups] = useState([]);
    const [ProductModel, setProductModel] = useState([]);
    const [Products, setProducts] = useState([]);
    const [ProductModelTypes, setProductModelTypes] = useState([]);



    const [FProductGroups, setFProductGroups] = useState([]);
    const [FProductModelTypes, setFProductModelTypes] = useState([]);
    const [FProducts, setFProducts] = useState([]);



    useEffect(() => {
        if (ProductGroups && ProductGroups?.length > 0)
            filterModel();
    }, [selectedBrand, selectedModel, ProductGroups])

    const filterModel = () => {

        if (selectedBrand && selectedModel) {
            setFProductGroups((ProductGroups?.filter(a => (a.brandId == selectedBrand) && (a.model == selectedModel))));

        }


    };
    function filterModelType(option) {
        setFProductModelTypes(ProductModelTypes.filter(a => a.productGroupId === option.value));
    }
    const getUserCars = () => {
        try {
            const _userID = JSON.parse(getData(User_Data_Key, true)).id.toString().split('_')[1];
            axios.post(
                config.baseURL + 'func',
                {
                    "name": "SiteGetUserCars",
                    "values": {
                        "userID": Number(_userID)
                    }
                }
            ).then(response => {
                setCars(response.data);


            }).catch(error => {
                console.log(error);
            });
        } catch (err) {
            console.log(err);
        }
    };
    const [cars, setCars] = useState([]);
    useEffect(() => {
        getUserCars();
        axios.request({
            baseURL: config.controllURL,
            url: lang + "/api/user/index",
            method: "get",
        }).then(response => {
            const dataResponse = response.data;
            setBrands(dataResponse?.brands);
            setProductGroups(dataResponse?.groups);
            setProductModel(reverse(dataResponse?.models));
            setProductModelTypes(dataResponse?.modelTypes);
            console.log('info', dataResponse);
        }).catch(err => {
            console.log(err);
        });
    }, [])
    const [showPopup, setShowPopup] = useState(false);
    const [showUpdatePopup, setShowUpdatePopup] = useState(false);
    //------------------------------------------------------------------------------
    const handlePopupStatus = (status) => {
        setError({});
        setError2({});
        setColor(null);
        setRequestFormNewModel({ newModel: null });
        setOtherBoardNo('');
        setCarBoardNo({
            ar: '',
            en: ''
        });
        setShowPopup(status);

    };
    const handleUpdatePopupStatus = (status) => {
        setVehicleCurrentData({
            brand: {},
            group: {},
            model: {},
            year: {}
        });
        setRequestFormNewModel({ newModel: null });
        setError({});
        setError2({});
        setColor(null);
        setOtherBoardNo('');
        setCarBoardNo({
            ar: '',
            en: ''
        });
        setShowUpdatePopup(status);
    };
    // to submit the data to server
    const submitRequest = async () => {
        const result = formValidate(schema, requestForm) ?? {};
        const specialResult = formValidate(schema2, requestFormNewModel) ?? {};
        setError(result);
        setIsLoadingForAdd(true);
        if (Object.keys(result).length === 0) {
            if (requestForm.productID == 'null') {
                setError2(specialResult);
                if (Object.keys(specialResult).length != 0)
                    return;
            }
            try {
                const _userID = JSON.parse(getData(User_Data_Key, true)).id.toString().split('_')[1];
                const vehicleImage = await fetchVehicleImage(requestForm.year, requestForm?.productID == 'null' ? null : requestForm?.productID);
                const body = {
                    "Brand": requestForm.brand,
                    "ProductGroupID": requestForm.group,
                    "UserId": Number(_userID),
                    "carId": requestForm?.productID == 'null' ? null : requestForm?.productID,
                    "ModelID": requestForm.year == '' || requestForm.year == null ? null : requestForm.year,
                    "NewModelType": requestFormNewModel.newModel != null ? requestFormNewModel.newModel : null,
                    "colorCode": (requestForm?.selectedColor) ? requestForm?.selectedColor : null,
                    "alias": requestForm.private_name,
                    "boardNoAr": join(split(carBoardNo.ar?.replaceAll(' ', ''), ''), ' '),
                    "boardNoEn": join(split(carBoardNo.en?.replaceAll(' ', ''), ''), ' '),
                    "structureno": requestForm.structure_no,
                    "Image": vehicleImage,
                    "createdDate": new Date()

                };
                axios.post(
                    config.baseURL + 'add',
                    {
                        "Object": "SiteUserCars",
                        "Values": body
                    }
                ).then(response => {
                    getUserCars();
                    handlePopupStatus(false);
                    setRequestForm({});
                    dropDownResetter(() => {
                        setFProductGroups([]);
                        setFProductModelTypes([]);
                        setVehicleCurrentData({
                            brand: null,
                            group: null,
                            model: null,
                            year: null
                        })
                    });
                    toast.success(trans('common:vehicle-added'));
                }).catch(err => {
                    toast.error(trans('common:vehicle-added-fail'));
                })
            } catch (err) {
                console.log(err);
            }
        }
        setIsLoadingForAdd(false);
    };

    const fetchVehicleImage = async (model, modelType) => {
        let vehicleImage = null;
        try {
            const response = await axios.post(
                config.baseURL + 'func',
                {
                    "name": "FetchImageOfModelCar",
                    "values": {
                        "modelType": String(modelType),
                        "modelYear": String(model)
                    }
                }
            );
            if (response.status === 200) {
                const responseData = response.data;
                vehicleImage = responseData[0]?.productImage;
            }
        } catch (err) {
            console.log('something wrong happened in fetch vehicle image', err);
        }
        return vehicleImage;
    };
    // the following fun is used to determine acceptable letters for car's plate no
    const handleAcceptCarBoardLetters = (event) => {
        const _numKeys = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57];
        const _arKeys = [1571, 1575, 1576, 1581, 1583, 1585, 1587, 1589, 1591, 1593, 1602, 1603, 1604, 1605, 1606, 1607, 1608, 1609];
        const _enCapitalKeys = [65, 66, 74, 68, 82, 83, 88, 84, 69, 71, 75, 76, 90, 78, 72, 85, 86];
        const _enSmallKeys = [97, 98, 106, 100, 114, 115, 120, 116, 101, 103, 107, 108, 122, 110, 104, 117, 118];
        const _key = event.which || event.keyCode;
        const _request = { ...requestForm };
        const _request2 = { ...requestUpdateForm };
        const _letters = { ...carBoardNo };
        const _enLetters = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'A', 'B', 'J', 'D', 'R', 'S', 'X', 'T', 'E', 'G', 'K', 'L', 'Z', 'N', 'H', 'U', 'V'];
        const _arLetters = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'ا', 'أ', 'ب', 'ح', 'د', 'ر', 'س', 'ص', 'ط', 'ع', 'ق', 'ك', 'ل', 'م', 'ن', 'ه', 'و', 'ى'];
        const _keyChar = String.fromCharCode(_key);
        if (lang == 'ar') {
            if (includes([..._arKeys], _key)) {
                const _index = findIndex(_arLetters, (item) => {
                    return item.toLowerCase() == _keyChar.toLowerCase()
                });
                _letters.ar += _arLetters[_index];
                _letters.en = _enLetters[_index] + _letters.en;
                _request.board_No += _keyChar;
                _request2.board_No += _keyChar;
                setOtherBoardNo(_letters.en ?? '');
            }
            else if (includes([..._numKeys], _key)) {
                const _index = findIndex(_arLetters, (item) => {
                    return item.toLowerCase() == _keyChar.toLowerCase()
                });
                _letters.ar += _arLetters[_index];
                _letters.en += _enLetters[_index];
                _request.board_No += _keyChar;
                _request2.board_No += _keyChar;
                setOtherBoardNo(_letters.en ?? '');
            }
        } else {

            //---------------------------------------------------------
            //---------------------------------------------------------
            if (includes([..._enCapitalKeys, ..._enSmallKeys], _key)) {
                const _index = findIndex(_enLetters, (item) => {
                    return item.toLowerCase() == _keyChar.toLowerCase()
                });
                _letters.ar = _arLetters[_index] + _letters.ar;
                _letters.en += _enLetters[_index];
                _request.board_No += _keyChar;
                _request2.board_No += _keyChar;
                setOtherBoardNo(_letters.ar ?? '');
            }
            else if (includes([..._numKeys], _key)) {
                const _index = findIndex(_enLetters, (item) => {
                    return item.toLowerCase() == _keyChar.toLowerCase()
                });
                _letters.ar += _arLetters[_index];
                _letters.en += _enLetters[_index];
                _request.board_No += _keyChar;
                _request2.board_No += _keyChar;
                setOtherBoardNo(_letters.ar ?? '');
            }
        }

        setRequestForm(_request);
        setRequestUpdateForm(_request2);
        setCarBoardNo(_letters);
    };
    // the following fun is used to remove the letters from end
    const handleRemoveCarBoardLetters = (event, data) => {
        const _request = { ...requestForm };
        const _request2 = { ...requestUpdateForm };
        const _letters = { ...carBoardNo };
        if (event.key == 'Backspace') {
            data = data?.replaceAll(' ', '')?.trim();
            const _len = data && data?.length;
            if (_len > 0) {

                const _enLetters = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'A', 'B', 'J', 'D', 'R', 'S', 'X', 'T', 'E', 'G', 'K', 'L', 'Z', 'N', 'H', 'U', 'V'];
                const _arLetters = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'ا', 'أ', 'ب', 'ح', 'د', 'ر', 'س', 'ص', 'ط', 'ع', 'ق', 'ك', 'ل', 'م', 'ن', 'ه', 'و', 'ى'];
                //--------------------------------------
                const _valAsArray = data.split('');
                const _valAr = _letters.ar.split('');
                const _valEn = _letters.en.split('');
                const _valTemp = otherLangBoardNo?.replaceAll(' ', '')?.trim().split('');
                const _valueDeleted = _valAsArray[_len - 1];
                if (lang == 'ar') {
                    const _indexDeleted = findIndex(_arLetters, (item) => {
                        return item.toLowerCase() == _valueDeleted
                    });
                    delete _valAsArray[_len - 1];
                    delete _valAr[_len - 1];

                    var _indexENDeleted = 0;
                    _valEn?.map((item, index) => {
                        if (item.toLowerCase() == _enLetters[_indexDeleted]?.toLowerCase()) {

                            _indexENDeleted = index
                        }
                    });
                    if (_valEn.length > 0)
                        delete _valEn[_indexENDeleted];
                    if (_valTemp.length > 0)
                        delete _valTemp[_indexENDeleted];
                } else {
                    //------------------
                    const _indexDeleted = findIndex(_enLetters, (item) => {
                        return item.toLowerCase() == _valueDeleted
                    });

                    var _indexARDeleted = 0;
                    _valAr.map((item, index) => {
                        if (item.toLowerCase() == _arLetters[_indexDeleted]?.toLowerCase()) {

                            _indexARDeleted = index;
                        }
                    });

                    if (_valTemp.length > 0)
                        delete _valTemp[_indexARDeleted];
                    if (_valAr.length > 0)
                        delete _valAr[_indexARDeleted];

                    delete _valAsArray[_len - 1];
                    delete _valEn[_len - 1];

                }

                //-----------------------------------------

                const _valAsString = _valAsArray.join('');
                _request.board_No = _valAsString.trim();
                _request2.board_No = _valAsString.trim();
                _letters.ar = _valAr.join('').trim();
                _letters.en = _valEn.join('').trim();
                setOtherBoardNo(_valTemp.join('').trim());
                setRequestForm(_request);
                setRequestUpdateForm(_request2);
                setCarBoardNo(_letters);
                return;
            }
        }
    };
    // to convert image to base64
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    const handleSetStringWithSpaces = useCallback((board_No) => {
        if (board_No != null && board_No != "") {
            // Split the string into an array of characters and then rearrange
            let chars = board_No?.split('') || [];

            // Filter out the numbers and letters separately
            const numbers = chars.filter(char => /\d/.test(char)).join(' ');
            const letters = chars.filter(char => /[\u0600-\u06FF]/.test(char)).join(' ');
            const englishLetters = chars.filter(char => /[a-zA-Z]/.test(char)).join(' ');
            // Combine numbers and letters with a space between them
            return `${numbers} ${letters} ${englishLetters}`.trim();
        } else {
            console.log("handleSetStringWithSpaces>:else ", board_No)
            return "";
        }
    }, []);

    // to choose the image
    const handleChangeImage = async (event) => {
        const files = event.target.files;
        if (files.length > 0) {
            const fileURL = URL.createObjectURL(files[0]);
            const fileBase64 = await toBase64(files[0]);
            const finalFile = fileBase64.substr(fileBase64.indexOf(',') + 1, fileBase64.length);
            const val = { ...requestForm };
            const val2 = { ...requestUpdateForm };
            val.image = finalFile;
            val2.image = finalFile;
            setRequestUpdateForm(val2);
            setRequestForm(val);
        }
    };

    const [cashColors, setCashColors] = useState({});
    const handleFetchModelAvaliableColors = async (productTypeID, year, deColor) => {
        let _colors = [];
        setStandardColors([]);
        if (deColor?.split("/")[0])
            setColor(deColor?.split("/")[0]);
        try {

            if (cashColors[productTypeID + "-" + year]) {
                _colors = cashColors[productTypeID + "-" + year];
                setStandardColors(_colors);


            } else {

                const response = await axios.post(
                    config.baseURL + 'func',
                    {
                        "Name": "site_fetchColorOfTypeWithOutImages",
                        "Values": {
                            "Type": productTypeID,
                            "model": year
                        }
                    }
                );

                if (response.status == 200) {

                    var currentOutColor = [];
                    var Out_colors = [];

                    response.data?.forEach((item, i) => {


                        if (!currentOutColor.includes(item.ColorID?.split("/")[0])) {

                            Out_colors = [...Out_colors, item];
                            currentOutColor = [...currentOutColor, item.ColorID?.split("/")[0]];
                        }

                    })


                    var cc = { ...cashColors };
                    cc[productTypeID + "-" + year] = Out_colors;
                    setCashColors(cc);




                    _colors = Out_colors;
                    setStandardColors(_colors);

                }

            }

        } catch (error) {
            console.log(error);
        }



        return _colors;
    };

    // to update the vehicle selected
    const onUpdate = (vehID) => {
        if (vehID) {
            // the following code is to set the user selected data on the controls
            axios.post(
                config.baseURL + 'details',
                {
                    "object": "siteuserCars",
                    "objectSettings": {
                        "metaData": false
                    },
                    "option": "column",
                    "fields": "carId,Image,alias,ProductGroupID,Brand,NewModelType,ModelID,Carcolor,boardNoAr,boardNoEn,structureno,colorCode",
                    "filters": `where GUID ='${vehID}'`
                }
            ).then(response => {
                const responseData = response.data.ApiObjectData?.length > 0 ? response.data?.ApiObjectData[0] : null;
                const _request = { ...requestUpdateForm };
                if (responseData) {
                    setShowUpdatePopup(true);
                    const _brand = Brands.find(item => {
                        return item.brandId == responseData.Brand
                    });
                    const _group = ProductGroups.find(item => {
                        return item.productGroupID == responseData.ProductGroupID
                    });
                    const _modelData = { text: '', value: '' };
                    const _model = ProductModelTypes.find(item => {
                        return item.productTypeId == responseData.carId
                    });
                    if (_model != undefined || _model != null) {
                        _modelData.text = _model.description;
                        _modelData.value = _model.productTypeId;
                    } else {
                        _modelData.text = trans("info:vehicle_add_new");
                        _modelData.value = 'null';
                    }
                    const _year = ProductModel.find(item => {
                        return item.modelId == responseData.ModelID
                    }) ?? null;
                    if (lang == 'en') {
                        _request.board_No = responseData.boardNoEn;
                        setOtherBoardNo(responseData.boardNoAr)
                    } else {
                        _request.board_No = responseData.boardNoAr;
                        setOtherBoardNo(responseData.boardNoEn)
                    }
                    _request.id = vehID;
                    _request.brand = responseData.Brand;
                    _request.group = responseData.ProductGroupID;
                    _request.productID = responseData.carId ?? 'null';
                    _request.year = responseData.ModelID;
                    _request.structure_no = responseData.structureno;
                    _request.private_name = responseData.alias ?? '';
                    _request.image = responseData.Image;
                    _request.selectedColor = String(responseData.colorCode);
                    if (responseData.ModelID)
                        setSelectedModel(responseData.ModelID);

                    if (responseData.Brand)
                        setSelectedBrand(responseData.Brand);
                    setCarBoardNo({
                        en: responseData.boardNoEn,
                        ar: responseData.boardNoAr
                    })
                    setColor(responseData?.Carcolor);
                    setRequestFormNewModel({ newModel: responseData.NewModelType });
                    filterModel();
                    filterModelType({ value: _group.productGroupID });
                    setVehicleCurrentData({
                        brand: _brand,
                        group: _group,
                        model: _modelData,
                        year: _year
                    });
                    setRequestUpdateForm(_request);

                    handleFetchModelAvaliableColors(_request.productID, _request.year, responseData?.colorCode);
                }
            }).catch(err => {
                console.log(err);
            });
        }
    };
    // submit to update the vehicle
    const submitUpdateRequest = async () => {
        const result = formValidate(schema, requestUpdateForm) ?? {};
        const specialResult = formValidate(schema2, requestFormNewModel) ?? {};
        setError(result);
        setIsLoadingForEdit(true);
        if (Object.keys(result).length === 0) {
            if (requestUpdateForm.productID == 'null') {
                setError2(specialResult);
                if (Object.keys(specialResult).length != 0)
                    return;
            }
            try {
                const _userID = JSON.parse(getData(User_Data_Key, true)).id.toString().split('_')[1];
                const vehicleImage = await fetchVehicleImage(requestUpdateForm.year, requestUpdateForm?.productID == 'null' ? null : requestUpdateForm?.productID);
                const body = {
                    "Brand": requestUpdateForm.brand,
                    "ProductGroupID": requestUpdateForm.group,
                    "carId": requestUpdateForm?.productID == 'null' ? null : requestUpdateForm?.productID,
                    "ModelID": requestUpdateForm.year == '' || requestUpdateForm.year == null ? null : requestUpdateForm.year,
                    "NewModelType": requestFormNewModel.newModel != null ? requestFormNewModel.newModel : null,
                    "colorCode": requestUpdateForm?.selectedColor,
                    "alias": requestUpdateForm.private_name,
                    "boardNoAr": join(split(carBoardNo.ar?.replaceAll(' ', ''), ''), ' '),
                    "boardNoEn": join(split(carBoardNo.en?.replaceAll(' ', ''), ''), ' '),
                    "structureno": requestUpdateForm.structure_no,
                    "Image": vehicleImage,
                    "updatedDate": new Date()
                };

                axios.put(
                    config.baseURL + 'update',
                    {
                        "Object": "SiteUserCars",
                        "Values": body,
                        "filters": `where GUID = '${requestUpdateForm.id}'`
                    }
                ).then(response => {
                    getUserCars();
                    handleUpdatePopupStatus(false);
                    setRequestUpdateForm({});
                    toast.success(trans('info:vehicle_was_updated'));
                    dropDownResetter(() => {
                        setFProductModelTypes([]);
                        setVehicleCurrentData({
                            brand: null,
                            group: null,
                            model: null,
                            year: null
                        })
                    });
                }).catch(err => {
                    toast.error(trans('info:vehicle_was_updated_err'));
                })
            } catch (err) {
                console.log(err);
            }
        }
        setIsLoadingForEdit(false);
    };
    //------------------------------------------------------------------------------
    return (
        <div className={`${langaugeDir}`}>
            <FormButton isPrimary={true}  classes={` px-5 py-2 nice-float-${dir} nice-width-init`} type='button' name={trans("user:add_new_vehicle")}
                onClick={() => handlePopupStatus(true)} />
            <div className="clear-both"></div>
            <SectionHeading
                hasRuler={false}
                infoText={trans("user:private_cars_note")}
                title={trans("user:private_cars")}
            >
                <Swiper
                    spaceBetween={0}
                    slidesPerView={2}

                    breakpoints={{
                        576: {
                            slidesPerView: 4,
                        },
                    }}
                    resizeObserver={"true"}
                    watchSlidesVisibility
                    watchSlidesProgress
                    autoplay={false}
                    loop={false}
                    observer={true}

                >
                    {cars?.map((car, index) => {

                        return <SwiperSlide className={"px-3 my-3"} key={index}>
                            <CarUser
                                onUpdate={onUpdate}
                                inputDate={car.InputDate}
                                id={car.GUID}
                                img={car.Image !== null ? car.Image : '/assets/images/eparts/default.png'}
                                name={car[`Model${trans('model:description')}`] ?? car[`Group${trans('model:description')}`]}
                                brand={car[`Brand${trans('model:description')}`]}
                                brandIcon={car.Icon ? "data:image/png;base64," + car.Icon : null}
                                brandImage={car.IconImage ? car.IconImage : null}
                                link={"/"+lang+"/user/vehicles/privateVehicles/" + car.CarID + "/" + car.FetchType}
                            />

                        </SwiperSlide>
                    })}
                </Swiper>
            </SectionHeading>
            <Popup show={showPopup}>

                <div className='offer-popup nice-flex nice-flex-center'>
                    <div className="offer-popup-container p-4">

                        <div className="offer-popup-container-header">
                            <h1 className="text-balck text-bold text-24 text-center">{trans("common:add-new-car")}</h1>
                            <span className='offer-popup-container-header-close text-block text-gray' onClick={() => {
                                handlePopupStatus(false)
                            }}>&times;</span>
                        </div>

                        <div className='flex flex-column nice-gap-1rem px-5 py-20'>



                            <SectionHeader no='1' title={trans('common:vehicle-model-step-1')} />

                            <DropDownInput
                                className={"flex-grow-1 w-full"}
                                isSecondary={true}
                                errorLabel={error?.brand}
                                defaultOption={requestForm?.brand == null || requestForm?.brand == '0' ? { text: trans("info:company"), value: '0' } : null}
                                title={trans("info:company")}
                                options={[
                                    ...Brands.map(B => {
                                        return { value: B.brandId, text: B.description };
                                    })]}
                                onSelect={(value) => {
                                    if (value == '' || value == '0') {
                                        dropDownResetter(() => {
                                            setFProductGroups([]);
                                            setFProductModelTypes([]);
                                        });
                                    }
                                    const val = { ...requestForm };
                                    val.brand = String(value);
                                    setSelectedBrand(val.brand);
                                    setRequestForm(val);
                                }}

                                selectedValue={requestForm?.brand}
                            />

                            <DropDownInput
                                selectedValue={requestForm?.year}
                                className={"flex-grow-1 w-full"}
                                isSecondary={true}
                                defaultOption={requestForm?.year == null || requestForm?.year == '0' ? { text: trans("info:vehicle_year"), value: '0' } : null}
                                title={trans("info:vehicle_year")}
                                options={[
                                    ...ProductModel.map(B => {
                                        return { value: B.modelId, text: B.modelId };
                                    })]}
                                onSelect={(e) => {
                                    if (e == '' || e == '0') {
                                        dropDownResetter(() => {
                                            setFProductGroups([]);
                                            setFProductModelTypes([]);
                                        });
                                    }
                                    const val = { ...requestForm };
                                    val.year = String(e);
                                    setSelectedModel(val.year);
                                    setRequestForm(val);
                                }}
                                errorLabel={error?.year}
                            />

                            <DropDownInput
                                selectedValue={requestForm?.group}
                                className={"flex-grow-1 w-full"}
                                isSecondary={true}
                                defaultOption={requestForm?.group == null || requestForm?.group == '0' ? { text: trans("info:vehicle_group"), value: '0' } : null}
                                title={trans("info:vehicle_group")}
                                options={[
                                    ...FProductGroups.map(B => {
                                        return { value: B.productGroupID, text: B.description };
                                    })]}
                                errorLabel={error?.group}
                                onfilter={filterModelType}
                                onSelect={(value) => {
                                    if (value == '' || value == '0') {
                                        dropDownResetter(() => {
                                            setFProductModelTypes([]);
                                        });
                                    }
                                    const val = { ...requestForm };
                                    val.group = String(value);
                                    setRequestForm(val);
                                }}
                            />
                            <DropDownInput
                                selectedValue={requestForm?.productID}
                                className={"flex-grow-1 w-full"}
                                isSecondary={true}
                                title={trans("info:vehicle_model")}
                                defaultOption={requestForm?.productID == null || requestForm?.productID == '0' ? { text: trans("info:vehicle_model"), value: '0' } : null}
                                options={[
                                    ...FProductModelTypes.map(B => {
                                        return { value: B.productTypeId, text: B.description };
                                    }), { text: trans("info:vehicle_add_new"), value: "null" }]}
                                errorLabel={error?.productID}
                                onSelect={(e) => {
                                    if (e !== 'null') {
                                        setRequestFormNewModel({
                                            newModel: null
                                        });
                                    }
                                    const val = { ...requestForm };
                                    val.productID = String(e);
                                    setRequestForm(val);

                                    handleFetchModelAvaliableColors(String(e), requestForm?.year);
                                }}
                            />
                            {requestForm.productID == 'null' && <InputField
                                errorLabel={error2?.newModel}
                                onChangeValue={
                                    (e) => {
                                        const val = { ...requestFormNewModel };
                                        val.newModel = e.target.value;
                                        setRequestFormNewModel(val);
                                    }
                                }
                                title={trans("info:new_model")}
                            />}

                            <SectionHeader no='2' title={trans('common:vehicle-model-step-2')} />

                            <InputField
                                // onLeave={(event) => {
                                //     try {
                                //         let currentValue = { ...requestForm };
                                //         if (currentValue?.board_No) {

                                //             // Split the string into an array of characters and then rearrange
                                //             let chars = currentValue?.board_No?.split('') || [];

                                //             // Filter out the numbers and letters separately
                                //             const numbers = chars.filter(char => /\d/.test(char)).join(' ');
                                //             const letters = chars.filter(char => /[\u0600-\u06FF]/.test(char)).join(' ');
                                //             const englishLetters = chars.filter(char => /[a-zA-Z]/.test(char)).join(' ');
                                //             // Combine numbers and letters with a space between them
                                //             currentValue.board_No = `${numbers} ${letters} ${englishLetters}`.trim();

                                //             // const chars = split(currentValue?.board_No?.replaceAll(' ', ''), '');
                                //             // currentValue.board_No = join(chars, ' ');
                                //             setRequestForm(currentValue);
                                //             //----------------------------------------------------
                                //             let other = otherLangBoardNo?.split('') || [];

                                //             // Filter out the numbers and letters separately
                                //             const othernumbers = other.filter(char => /\d/.test(char)).join(' ');
                                //             const otherletters = other.filter(char => /[\u0600-\u06FF]/.test(char)).join(' ');
                                //             const otherenglishLetters = other.filter(char => /[a-zA-Z]/.test(char)).join(' ');

                                //             setOtherBoardNo(`${othernumbers} ${otherletters} ${otherenglishLetters}`.trim());
                                //         }
                                //     } catch (err) {
                                //         console.log(err);
                                //     }
                                // }}
                                extraClasses={'language-en-d ' + (lang == 'en' ? 'language-en-t' : 'language-ar-t')}
                                errorLabel={error?.board_No}
                                value={handleSetStringWithSpaces(requestForm.board_No)}
                                onKeyPress={handleAcceptCarBoardLetters}
                                onKeyDown={(event) => { handleRemoveCarBoardLetters(event, requestForm.board_No) }}
                                placeholder={trans("form:board-no") + ` (${lang}) `}
                            />
                            <InputField
                                extraClasses={'language-en-d ' + (otherLang == 'en' ? 'language-en-t' : 'language-ar-t')}
                                value={handleSetStringWithSpaces(otherLangBoardNo)}
                                readOnly={true}
                                title={trans("form:board-no") + ` (${otherLang}) `}
                                placeholder={trans("form:board-no") + ` (${otherLang}) `}
                            />

                            <InputField
                                length={17}
                                errorLabel={error?.structure_no}
                                title={trans("form:structure-no") + '(VIN)'}
                                onChangeValue={
                                    (e) => {
                                        const val = { ...requestForm };
                                        val.structure_no = e.target.value;
                                        setRequestForm(val);
                                    }
                                }
                                placeholder={trans("form:structure-no") + '(VIN)'}
                            />
                            <InputField
                                onChangeValue={
                                    (e) => {
                                        const val = { ...requestForm };
                                        val.private_name = e.target.value;
                                        setRequestForm(val);
                                    }
                                }
                                title={trans("info:private-name") + ' ' + trans("common:optional")}
                                placeholder={trans("info:private-name") + ' ' + trans("common:optional")}
                            />
                            <div >
                                <div className="input ">
                                    <label className={"input-label text-20"}>{trans('user:vehicle_color')}</label>
                                    <ErrorLabel errorTitle={error?.selectedColor} />
                                    <div className="nice-flex nice-flex-row flex-wrap my-3">
                                        {
                                            standardColors.map((item, index) => {
                                                return <CarColorPicker
                                                    key={`Standard-color(${index})`}
                                                    // imageBase64={item?.colorImage}
                                                    urlImageColor={item?.siteOutColorImage}
                                                    color={item?.ColorID?.split("/")[0]}
                                                    active={color}
                                                    onClick={() => {
                                                        const val = { ...requestForm };
                                                        val.selectedColor = String(item?.ColorID);
                                                        setRequestForm(val);
                                                        setColor(item?.ColorID?.split("/")[0])
                                                    }
                                                    }
                                                />
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <FileInput mimeType="image/*" title={trans('user:vehicle_image') + ' ' + trans("common:optional")} onPicker={handleChangeImage} /> */}
                            <FormButton isPrimary={true} classes={"px-5 py-2"} isLoading={isLoadingForAdd} onClick={() => submitRequest()} name={trans("form:add")} />
                        </div>
                    </div>
                </div>
            </Popup>
            <Popup show={showUpdatePopup}>
                <div className='offer-popup nice-flex nice-flex-center'>
                    <div className="offer-popup-container p-4">

                        <div className="offer-popup-container-header">
                            <h1 className="text-balck text-bold text-24 text-center">{trans("info:vehicle_update_car")}</h1>
                            <span className='offer-popup-container-header-close text-block text-gray' onClick={() => {
                                handleUpdatePopupStatus(false)
                            }}>&times;</span>
                        </div>

                        <div className='flex flex-column nice-gap-1rem px-5 py-20'>

                            <SectionHeader no='1' title={trans('common:vehicle-model-step-1')} />

                            <DropDownInput
                                defaultOption={vehicleCurrentData?.brand?.description ? { text: vehicleCurrentData.brand?.description, value: vehicleCurrentData?.brand?.brandId } : null}
                                className={"flex-grow-1 w-full"}
                                isSecondary={true}
                                selectedValue={requestUpdateForm?.brand}
                                errorLabel={error?.brand}
                                onfilter={filterModel}
                                title={trans("info:company")}
                                options={[
                                    ...Brands.map(B => {
                                        return { value: B.brandId, text: B.description };
                                    })]}
                                onSelect={(value) => {
                                    setVehicleCurrentData({
                                        brand: null,
                                        group: null,
                                        model: null,
                                        year: null
                                    })
                                    const val = { ...requestUpdateForm };
                                    val.brand = String(value);
                                    setRequestUpdateForm(val);
                                    setSelectedBrand(val.brand);
                                }}
                            />
                            <DropDownInput
                                defaultOption={vehicleCurrentData?.year?.modelId ? { text: vehicleCurrentData?.year?.modelId, value: vehicleCurrentData?.year?.modelId } : null}
                                selectedValue={requestUpdateForm?.year}
                                className={"flex-grow-1 w-full"}
                                isSecondary={true}
                                title={trans("info:vehicle_year")}
                                options={[
                                    ...ProductModel.map(B => {

                                        return { value: B.modelId, text: B.modelId };
                                    })]}

                                onSelect={(e) => {
                                    const currentValue = { ...vehicleCurrentData };
                                    setVehicleCurrentData({
                                        brand: currentValue?.brand,
                                        group: null,
                                        model: null,
                                        year: null
                                    });
                                    const val = { ...requestUpdateForm };
                                    val.year = String(e);
                                    setRequestUpdateForm(val);
                                    setSelectedModel(val.year);
                                }}
                                errorLabel={error?.year}
                            />
                            <DropDownInput
                                defaultOption={vehicleCurrentData?.group ? { text: vehicleCurrentData?.group?.description, value: vehicleCurrentData.group?.productGroupID }
                                    : selectedBrand == '' || selectedModel == '' ? { value: "", text: trans("info:vehicle_group") } : null
                                }
                                selectedValue={requestUpdateForm?.group}
                                className={"flex-grow-1 w-full"}
                                isSecondary={true}
                                title={trans("info:vehicle_group")}
                                options={[
                                    ...FProductGroups.map(B => {
                                        return { value: B.productGroupID, text: B.description };
                                    })]}
                                errorLabel={error?.group}
                                onfilter={filterModelType}
                                onSelect={(value) => {
                                    const currentValue = { ...vehicleCurrentData };
                                    setVehicleCurrentData({
                                        brand: currentValue?.brand,
                                        group: null,
                                        model: null,
                                        year: null
                                    })

                                    const val = { ...requestUpdateForm };
                                    val.group = String(value);
                                    setRequestUpdateForm(val);
                                }}
                            />

                            <DropDownInput
                                defaultOption={vehicleCurrentData?.model ? vehicleCurrentData?.model : (selectedBrand == '' || selectedModel == '' || requestUpdateForm?.group == '') ? { value: "", text: trans("info:vehicle_model") } : null}
                                className={"flex-grow-1 w-full"}
                                isSecondary={true}
                                selectedValue={requestUpdateForm?.productID}
                                title={trans("info:vehicle_model")}
                                options={[
                                    ...FProductModelTypes.map(B => {
                                        return { value: B.productTypeId, text: B.description };
                                    }), { text: trans("info:vehicle_add_new"), value: "null" }]}
                                errorLabel={error?.productID}
                                onSelect={(e) => {

                                    const currentValue = { ...vehicleCurrentData };
                                    setVehicleCurrentData({
                                        brand: currentValue?.brand,
                                        group: null,
                                        model: null,
                                        year: null
                                    })


                                    if (e !== 'null') {
                                        setRequestFormNewModel({
                                            newModel: null
                                        });
                                    }
                                    const val = { ...requestUpdateForm };
                                    val.productID = String(e);
                                    setRequestUpdateForm(val);

                                    handleFetchModelAvaliableColors(String(e), requestUpdateForm?.year);
                                }}
                            />

                            {requestUpdateForm.productID == 'null' && <InputField
                                value={requestFormNewModel?.newModel ?? ''}
                                errorLabel={error2?.newModel}
                                onChangeValue={
                                    (e) => {
                                        const val = { ...requestFormNewModel };
                                        val.newModel = e.target.value;
                                        setRequestFormNewModel(val);
                                    }
                                }
                                title={trans("info:new_model")}
                            />}


                            <SectionHeader no='2' title={trans('common:vehicle-model-step-2')} />

                            <InputField
                                // onLeave={(event) => {
                                //     try {
                                //         let currentValue = { ...requestUpdateForm };
                                //         if (currentValue?.board_No) {
                                //             const chars = split(currentValue?.board_No?.replaceAll(' ', ''), '');
                                //             currentValue.board_No = join(chars, ' ');
                                //             setRequestUpdateForm(currentValue);
                                //             //----------------------------------------------------
                                //             const other = split(otherLangBoardNo?.replaceAll(' ', ''), '');
                                //             setOtherBoardNo(join(other, ' '));
                                //         }
                                //     } catch (err) {
                                //         console.log(err);
                                //     }
                                // }}
                                extraClasses={'language-en-d ' + (otherLang == 'en' ? 'language-en-t' : 'language-ar-t')}
                                errorLabel={error?.board_No}
                                value={handleSetStringWithSpaces(requestUpdateForm.board_No)}
                                onKeyPress={handleAcceptCarBoardLetters}
                                onKeyDown={(event) => { handleRemoveCarBoardLetters(event, requestUpdateForm.board_No) }}
                                title={trans("form:board-no") + ` (${lang}) `}
                            />
                            <InputField
                                extraClasses={'language-en-d ' + (otherLang == 'en' ? 'language-en-t' : 'language-ar-t')}
                                value={handleSetStringWithSpaces(otherLangBoardNo)}
                                readOnly={true}
                                title={trans("form:board-no") + ` (${otherLang}) `}
                            />

                            <InputField
                                length={17}
                                value={requestUpdateForm.structure_no}
                                errorLabel={error?.structure_no}
                                title={trans("form:structure-no") + '(VIN)'}
                                onChangeValue={
                                    (e) => {
                                        const val = { ...requestUpdateForm };
                                        val.structure_no = e.target.value;
                                        setRequestUpdateForm(val);
                                    }
                                }
                            />
                            <InputField
                                value={requestUpdateForm.private_name}
                                onChangeValue={
                                    (e) => {
                                        const val = { ...requestUpdateForm };
                                        val.private_name = e.target.value;
                                        setRequestUpdateForm(val);
                                    }
                                }
                                title={trans("info:private-name") + ' ' + trans("common:optional")}
                            />
                            <div >
                                <div className="input ">
                                    <label className={"input-label"}>{trans('user:vehicle_color')}</label>
                                    <ErrorLabel errorTitle={error?.selectedColor} />
                                    <div className="nice-flex nice-flex-row flex-wrap my-3">
                                        {
                                            standardColors.map((item, index) => {
                                                return <CarColorPicker
                                                    key={`Update standard color (${index})`}
                                                    // imageBase64={item?.colorImage}
                                                    urlImageColor={item?.siteOutColorImage}
                                                    color={item?.ColorID?.split("/")[0]}
                                                    active={color}
                                                    onClick={() => {
                                                        const val = { ...requestUpdateForm };
                                                        val.selectedColor = String(item?.ColorID);
                                                        setRequestUpdateForm(val);
                                                        setColor(item?.ColorID?.split("/")[0])
                                                    }
                                                    }
                                                />
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <FileInput mimeType="image/*" title={trans('user:vehicle_image') + ' ' + trans("common:optional")} onPicker={handleChangeImage} /> */}
                            <FormButton isPrimary={true} classes={"px-5 py-2"} isLoading={isLoadingForEdit} onClick={() => submitUpdateRequest()} name={trans("info:edit")} />
                        </div>
                    </div>
                </div>
            </Popup>
        </div>
    );
}