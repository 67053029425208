export function AboutBox({icon, title, subtitle}) {
    return (
        <div className=" box-about">
            <div className="box-about-icon">
                <img data-src={icon} alt="" className={"lazyload object-full object-contain dark-filter-white"}/>
            </div>
            <div className="box-about-text">
                <h3 className={"text-24 font-bold  mb-3  "}>{title}</h3>
                <p className={"text-20 "}>{subtitle}</p>
            </div>
        </div>
    );
}

