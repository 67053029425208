import {Fragment, useState, useEffect} from "react";
import Banner from "../../components/banner";
import {Part} from "./components/part";
import PageHeading from "../../components/__simple/_pageheading";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Autoplay, EffectFade, Navigation, Pagination} from "swiper";
import Breadcrumb from "../../components/__simple/breadcrumb";
import axios from "axios";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router";
import { CompaignPart } from "./components/campaignPart";
import Popup from "../../User/popup";
import PopupHeader from "../../User/components/popupHeader";
import {PhoneInput} from "../../components/_input/_phoneInput";
import {FormButton} from "../../components/_input/_button";
import Joi from "joi";
import {GetMessages , formValidate} from '../../utils/validation';
import config from '../../configuration/config.json';
import {  User_Data_Key, getData } from "../../utils/localStorageManager";

SwiperCore.use([Pagination, Navigation, Autoplay, EffectFade]);

export function CompaignDetails() 
{
    const GetUrl = config.baseURL + 'details';
    let {compaignId}=useParams();
    let [DiscountId,setDiscountId]=useState("0");
    const [lang,setlang]=useState( localStorage.getItem('i18nextLng'));
    const [Brand,setBrand]=useState("");
    const [pageTitel,setpageTitel]=useState("");
    const {t: trans} = useTranslation("eparts");
    const [isPopUpOpened, setIsPopUpOpened] = useState(false);
    const [selectedCompaign,setSelectedCompagin] = useState('');
    const [selectedPackage,setSelectedPackage] = useState('');
    const [userForm , setUserForm] = useState({
        userPhoneNumber:''
    });


//start_abbas_alburaee
    const history = useHistory();
    const schema = Joi.object({
        userPhoneNumber:Joi.string().length(13).required().messages(GetMessages()),
    });

    const [error,setError] = useState({});

    async function handleCompaignRequest(){
        try{
            const result = formValidate(schema, userForm)??{};
            setError(result);
            console.log(result);
            if (Object.keys(result).length === 0) {
                const response = await axios.post(
                    config.baseURL + 'details',
                    {
                        "object":"Web_Users",
                        "ObjectSettings":{
                            "MetaData":false
                        },
                        "Option":"column",
                        "Fields":"GUID,Web_UserID,DescriptionAr,DescriptionEn,Phone,Email,IdentityNumber",
                        "Filters":`where phone = '${userForm.userPhoneNumber}'`
                    }
                );

                if(response.status === 200){
                    let  urlParms = `?compaign=${selectedCompaign}&fetr=${selectedPackage}`;

                    if(response.data.ApiObjectData.length > 0){

                        const {GUID,Web_UserID,DescriptionAr,DescriptionEn,Phone,Email,IdentityNumber} = response.data.ApiObjectData[0];

                        urlParms+=`&ur_gid=${GUID+'_'+String(Web_UserID)}&ur_id=${Web_UserID}&ur_en=${DescriptionEn} 
                     &ur_ar=${DescriptionAr}&ur_phone=${Phone}&ur_email=${Email}&ur_iden=${IdentityNumber} 
                     `;
                    }
                    history.push({
                        pathname :'/eparts/campaignorder',
                        search:urlParms
                    });
                    setUserForm({});
                    setSelectedCompagin(0);
                    setError({});
                }
            }

        }catch(err){
            console.log('Error in eparts compaign' , err)
        }
    }
    
    function handlePopupStatus(status,compaignID,packageID) {
        setIsPopUpOpened(status);
        setSelectedCompagin(compaignID);
        setSelectedPackage(packageID);
        setError({});
    };

    const [DisWD, setDisWD] = useState([]);
    function RequsetDetailsDis(ID) {
        const  Body=  {
            "name":"site_FeatchCompaignDetails",
            "values":{
                "DiscountID":ID
            }}
        const request =  axios.request({
            url: config.baseURL + 'func',
            data: Body, method:"POST",responseType:"json"
        })
            .then(function (response) {
                // console.log(response.data.ApiObjectData);
                setTimeout(setDisWD(response.data), 1000);

            })
            .catch(function (error) {
                setDisWD([]);
                console.log(error);
            });


    }
   //Compaign details
    const [CWD, setCWD] = useState([]);
    function RequsetDetails(ID) {
        const Body = {
            "object": "WebSparePackagesDatails",
            "ObjectSettings": {
                "MetaData": false
                
            },
            "Filters":"where offerId='"+ID+"'"
        };
        axios.request({
            url: GetUrl,
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                // console.log(response.data.ApiObjectData);
                setTimeout(setCWD(response.data.ApiObjectData), 1000);

            })
            .catch(function (error) {
                console.log(error);
            });


    }
   //Compaign info
    const [Compaign,setCompaign]=useState([]);
    function  RequsetCompaignId(){
        const BodyCompaignId={
            "object":"SalesDiscountCustomers_sp",
            "ObjectSettings":{
                "MetaData":false
            },
            "Filters":"where GUID='"+compaignId+"'"
        };
        axios.request({
            url: GetUrl,
            data: BodyCompaignId, method:"POST",responseType:"json"
        })
            .then(function (response) {
                // console.log(response.data.ApiObjectData);
                setTimeout( setCompaign(response.data.ApiObjectData),1000);
              
              if (response.data.ApiObjectData[0].CampaignType===1)
              {
                  RequsetDetails(response.data.ApiObjectData[0].DiscountID)
                  setDiscountId(response.data.ApiObjectData[0].DiscountID);
                  setpageTitel("الباقات");
              }
              else 
              {
                  RequsetDetailsDis(response.data.ApiObjectData[0].DiscountID)
                  setpageTitel("التخفيظات");
              }
               
                if (response.data.ApiObjectData[0].BrandID==="1")
                {
                    setIcon("/assets/images/eparts/Toyota.png");
                    setBrand("toyota");
                    
                }else
                {
                    setIcon("/assets/images/eparts/Lexus.png");
                    setBrand("lexus");
                }

            })
            .catch(function (error) {
                console.log(error);
            });


    }
  
    const [Icon,setIcon]=useState("");
    useEffect(() => {
        RequsetCompaignId();
       // console.log("setPWD", CWD);
    }, []);

    //--------------------------------------------------
    const [favoriteProducts,setFavoriteProducts] = useState([]);

    useEffect(async () => {
        const results = await fetchUserFavorites();
        setFavoriteProducts(results);
     }, []);
     // to fetch the user favorites
     const fetchUserFavorites = async () => {
         let _favorites = [];
         try {
             const isLogin = getData('isAuthenticated');
             if (isLogin) {
                 const { id } = JSON.parse(getData(User_Data_Key, true));
                 const userID = Number(id.toString().split('_')[1]);
                 const response = await axios.post(
                     config.baseURL + 'func',
                     {
                         "Name": "SiteFetchUserFavorites",
                         "values": {
                             "userID": userID
                         }
                     }
                 );
                 if(response.status == 200){
                     const favorites = response.data;
                     _favorites =  _favorites.concat(favorites);
                 }
             }
         } catch (err) {
             console.log(err);
         }
         return _favorites;
     };
    return (
        <Fragment>
            {Compaign.map(H=>{
             
                if(lang==="en"){
                    return(<Banner
                        background={"data:image/png;base64,"+H.Photo}
                        title={(H.CampaignType===1)?"الباقات":"التخفيظات"}
                        subtitle={H.DescriptionEnTitle}
                        desc={H.DescriptionEn}
                        backOpasity={H?.backOpasity}
                    />);
                }else {
                    return(<Banner
                        background={"data:image/png;base64,"+H.Photo}
                        title={(H.CampaignType===1)?"الباقات":"التخفيظات"}
                        subtitle={H.DescriptionArTitle}
                        desc={H.DescriptionAr}
                        backOpasity={H?.backOpasity}
                    />);
                }
            })}
            
            <div className="container">
                <Breadcrumb className={"mt-16 "}
                            links={[
                                {
                                    name: trans("eparts:EpartTitel"),
                                    to: "/eparts"
                                },
                                {
                                    name:pageTitel
                                }
                            ]}
                />
            </div>




            <div className=" container mb-36">


                <PageHeading
                    title={pageTitel}
                />

                <div className=' epart-campaign-grid-3'>

                    {CWD.map(d=>{
                       if (lang==="en")
                       {
                           return(<CompaignPart id={d.packageId} compaignId={DiscountId} brand={Brand} link={"/eparts/featuredetails"} GUID={d.GUID} brandIcon={Icon} salseprice={d.price} showDetails={d.descEn} img={d.image} name={d.nameEn} onChooseCompaign={(status,compaignID,packageID) => { handlePopupStatus(true,compaignID , packageID) }}/>);
                       }else
                       {
                           return(<CompaignPart id={d.packageId} compaignId={DiscountId}  brand={Brand}  link={"/eparts/featuredetails"} GUID={d.GUID} brandIcon={Icon} salseprice={d.price} showDetails={d.descAr} img={d.image} name={d.nameAr} onChooseCompaign={(status,compaignID,packageID) => { handlePopupStatus(true,compaignID , packageID) }} />);
                       }
                    })}
                </div>


                <div className="grid-4-cols mb-16 news-gap">
                    {DisWD.map(P => (
                        <SwiperSlide className={"px-3"} key={P.ProductID}>
                         
                            <Part favorites = {favoriteProducts} ProductNo={P.ProductNo} Engine_Groups={P.CategiorAr} Discount={P.DiscountAmount} Tax={P.Percentage} GUID={P.GUID} Weight={P.Weight} height={P.height} width={P.width} length={P.length} ProductImage={P.ProductImage} DescriptionEn={P.DescriptionEn} DescriptionAr={P.DescriptionAr} SalesPriceBefor={P.SalesPriceBefor} NetSalePrice={P.SalesPriceDiscount} ProductID={P.ProductID} />

                        </SwiperSlide>
                    ))}
                </div>
            </div>

            <Popup show={isPopUpOpened}>
                <div className='popup-model'>
                    <PopupHeader welcomeTitle={trans("eparts:order-now")} subTitle={trans("eparts:fill-phone")} closePopUp={() => handlePopupStatus(false,'','')} />
                    <div className='popup-content'>
                        <PhoneInput name='userPhoneNumber' title={trans("form:phone")}
                                    onChangeValue={
                                        (value) => {
                                            const val = {...userForm};
                                            val.userPhoneNumber = value;
                                            setUserForm(val);
                                        }
                                    }
                                    errorLabel = {error.userPhoneNumber}
                        />
                        <FormButton name={trans("form:next")} type='button'
                        isPrimary={true} classes={"px-5 py-2"}
                                    onClick={
                                        handleCompaignRequest
                    }/>
                    </div>
                </div>
            </Popup>
        </Fragment>
    );
}