import PageHeading from "../components/__simple/_pageheading";
import { Swiper, SwiperSlide } from "swiper/react";
import Testimonial from "../components/testimonial";
import { useEffect, useState } from "react";
import SwiperCore, { Autoplay, EffectFade, Navigation, Pagination } from "swiper";
import { useTranslation } from "react-i18next";
import { saveData, User_Data_Key, getData } from "../utils/localStorageManager";
import axios from "axios";
import config from "../configuration/config.json";
SwiperCore.use([Pagination, Navigation, Autoplay, EffectFade]);

export function TestimonialSection({ title, subtitle }) {
    const { t: trans,i18n } = useTranslation("heading");

    let lang =  (i18n.language || 'ar');
    const [testimoinals, setTestimoinals] = useState([]);
    useEffect(async () => {

        var testimonials;
        try {
            let data = [];
            data = await JSON.parse(getData("testimonials-" + lang, true));
            if (data && data.length > 0) {
                setTestimoinals(data);
                testimonials = data;
            }
        } catch (error) {


        }


        try {
            const response = await axios.request({
                baseURL: config.controllURL,
                url: lang + "/api/sections/testimonials",
                method: "get",
            });

            if (JSON.stringify(testimonials) != JSON.stringify(response.data)) {
                saveData("testimonials-" + lang, response.data, true);
                setTestimoinals(response.data)
            }

        } catch (error) {
            console.log(error);
        }
    }, []);


    return (

        testimoinals?.length > 0 ?
            <div className=" container mb-36 " id='customersSaysElement'>
                <PageHeading
                    fixed={true}
                    subtitle={title ?? trans("testimonial")}
                    title={subtitle ?? trans("testimonial-desc")}
                />
                <Swiper
                    spaceBetween={0}
                    slidesPerView={2}
                    pagination
                    breakpoints={{
                        // when window width is >= 640px
                        576: {
                            slidesPerView: 4,
                        },

                    }}
                    resizeObserver={"true"}
                    navigation
                >
                    {
                        testimoinals?.map((testimonial) => {
                            return (

                                <SwiperSlide className={"px-3"} key={testimonial.id}>
                                    <Testimonial name={testimonial.name}
                                        content={testimonial.content}
                                        image={config.FilePathURL + testimonial.imagePath} />
                                </SwiperSlide>
                            );
                        })
                    }

                </Swiper>
            </div>
            : null
    );
}