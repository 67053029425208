import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import config from '../configuration/config.json'
function Info({ hjData=null }) {
    const [hjGallery, setHjGallery] = useState(hjData);
    const { t: trans ,i18n} = useTranslation("info");
    const lang =i18n.language;
    const languageDir = 'language-' + lang;

    useEffect(async () => {

        if ("caches" in window) {
            let url = "siteHomePage";

                // Opening that particular cache
                const cacheStorage = await caches.open("HJWeb");
                // Fetching that particular cache data
                const cachedResponse = await cacheStorage.match(
                    url
                );
                let data = await cachedResponse?.json();
                if(data)
                setHjGallery({
                    title: data[`Title`],
                        description: data[`Description`],
                        image: data.Image
                });
           
        }
            if (hjData == null) {
        
                axios.request({
                    baseURL: config.controllURL,
                    url: lang + "/api/home/HomeInfo",
                    method: "get",
                }).then(response => {
                    const responseData = response.data;
                    if (responseData.length > 0) {
                        const firstResult = responseData[0];
                        setHjGallery({
                            title: data[`Title`],
                            description: data[`Description`],
                            image: data.Image
                        });

                        const data = new Response(JSON.stringify(firstResult));

                        if ("caches" in window) {
                            // Opening given cache and putting our data into it
                            caches.open("HJWeb").then((cache) => {
                                cache.put("siteHomePage", data );
                              
                            });
                        }
                    }
                }).catch(error => {
                    console.log(error);
                });
            }else{
                var newhjData = {...hjData};
                setHjGallery(newhjData);
            }
        }, [hjData]);

    function showImg() {
        if (hjGallery.image != null && hjGallery.image != "null") {
            return (
                <div className="info-section-img border-radius">
                    <img data-src={config.FilePathURL + hjGallery.image} alt={hjGallery.title} className={"lazyload border-radius"} />
                </div>
            )
        }
    }

    return (
        hjGallery && <div className={`info-section ${languageDir}`}>
            <div className={"info-section-text my-16"}>
                <h2>{hjGallery.title}</h2>
                <p>{hjGallery.description}</p>
            </div>
            {
                showImg()
            }
        </div>
    );
}

export default Info;
