import { DropDownInput } from "../../components/_input/_dropdown";
import { DropDownInput2 } from "../../components/_input/_dropdown2";
import { SwiperSlide, Swiper } from 'swiper/react';
import { useCallback, useState, useEffect, useRef } from "react";
import axios from "axios";
import PageHeading from "../../components/__simple/_pageheading";
import { useParams } from "react-router-dom";
import { slugCombine, slugSplitter } from '../../utils/slugManager';
import { useTranslation } from "react-i18next";
import config from '../../configuration/config.json';
import { trimEnd } from "lodash";
import { useHistory } from "react-router";
import { RadioInput } from "../../components/_input/_radioInput";
import { CheckInput } from "../../components/_input/_checkInput";
import _ from 'lodash';
import { Price } from "../../components/__simple/_price";

export function CarCheckoutPage({ vehicle, isToyota, isLexus, selectedColor, finance, financeSettings, filterCarImages = [] }) {
    const history = useHistory();
    const { slug, sn } = useParams();
    const { t: trans, i18n } = useTranslation(["new", "finance", "inputs", "info", "new"]);
    const [scrollSnackUp, setScrollSnackUp] = useState({
        show: false,
        height: '16rem'
    });
    const [propsData, setPropsData] = useState({});
    const [checkoutType, setCheckoutType] = useState(null);
    const [checkoutAgreemnt, setChecAgreemnt] = useState(false);
    useEffect(() => {
        ////console.log("props:", propsData);
        var mm = {
            ...propsData,
            vehicle, isToyota, isLexus, selectedColor, finance, financeSettings, filterCarImages
        }
        setPropsData(mm);
    }, [vehicle, isToyota, isLexus, selectedColor, finance, financeSettings, filterCarImages]);
    let lang = (i18n.language || 'ar');

    return (
        <>
            <section className="carOverview-page flex flex-col   ">

                <div className="nice-sticky mb-40 show-xs">

                    <div className=" py-10 px-10 nice-fixed flex-item-center nice-shadow-3 bg-white w-100">

                        <img className="nice-absolute rotate-180" src="/assets/icons/arrow-right.svg" width="7" height="7"
                            alt="arrow" onClick={() => {
                                history.goBack();
                            }} />
                        <span className="text-20 text-info">كاش ام تمويل</span>

                    </div>
                </div>
                <div className="carOverview-page-content grid grid-cols-12 gap-3
                          justify-items-auto mx-30 mt-10">



                    <div className="carOverview-page-main col-span-12 md:col-span-8 sm:col-span-8 lg:col-span-7 xl:col-span-7">


                        <div className=" py-10 px-20 nice-shadow-2 bg-white h-vh border-radius ">

                            <h3 className="text-30 text-bold  ">{" اختر الطريقة المناسبة لشراء هذه السيارة؟"}</h3>


                            {/* // item */}
                            <div className={" p-10 my-10 " + (checkoutType == 1 ? " nice-border-checkout-primary " : " nice-border-checkout-gray ")}>
                                <label className="nice-flex nice-flex-row nice-pointer">
                                    <RadioInput
                                        value1={1}
                                        name='recieveMethod'
                                        // text1={list().filter(a => a.Type === "car").length > 0 ? trans("new:delivery") : null}
                                        selectValue={checkoutType}
                                        onChangeValue={(value) => {
                                            setCheckoutType(1);
                                        }}
                                    />
                                    <div className="nice-flex nice-flex-row nice-flex-space-between w-100 mb-10">

                                        <div className="nice-flex flex-col ">
                                            <h4 className="text-24 text-bold">{trans("new:كاش")}</h4>
                                            <span className="text-20 text-gray max-w-80 mx-1">{trans("اضمن حجز السيارة من خلال دفع عربون مخصوم من قيمة السيارة")}</span>
                                        </div>

                                        <div className="nice-flex flex-col ">
                                            {/* <span className="text-24 font-bold text-primary">{(selectedColor?.NetSalePrice) && <Price price={parseFloat(selectedColor?.NetSalePrice)} />}</span> */}
                                            <span className="text-24 font-bold text-primary text-inline-block">{(selectedColor?.MiniDownPayment) && <Price price={parseFloat(selectedColor?.MiniDownPayment)}/>}</span>

                                            <span className="text-20 text-gray mx-1">{trans("مستردة ")}</span>
                                        </div>
                                    </div>

                                </label>
                            </div>

                            {/* // item */}
                            <div className={"p-10 my-10" + (checkoutType == 2 ? " nice-border-checkout-primary " : " nice-border-checkout-gray ")}>
                                <label className="nice-flex nice-flex-row nice-pointer">
                                    <RadioInput
                                        value1={2}
                                        name='recieveMethod'
                                        // text1={list().filter(a => a.Type === "car").length > 0 ? trans("new:delivery") : null}
                                        selectValue={checkoutType}
                                        onChangeValue={(value) => {
                                            setCheckoutType(2);
                                        }}
                                    />

                                    <div className="nice-flex nice-flex-row nice-flex-space-between w-100 mb-10">

                                        <div className="nice-flex flex-col ">
                                            <h4 className="text-24 text-bold">{trans("new:قدم طلب تمويل")}</h4>
                                            <span className="text-20 text-gray max-w-80 mx-1">{trans("حمل الوثائق المطلوبة (هوية، رخصة قيادة واثبات راتب) وابدأ اجراءات طلب التمويل.")}</span>
                                        </div>

                                        <div className="nice-flex flex-col ">
                                            <span className="text-24 font-bold text-primary">{"مجانآ"}</span>

                                            <span className="text-14 text-gray mx-1">{trans(" ")}</span>
                                        </div>
                                    </div>

                                </label>
                            </div>
                            {/* // item */}
                            <div className={" p-10 nice-border-sm  my-10  " + (checkoutType == 3 ? " nice-border-checkout-primary " : " nice-border-checkout-gray ")}>
                                <label className="nice-flex nice-flex-row nice-pointer">
                                    <RadioInput
                                        value1={3}
                                        name='recieveMethod'
                                        // text1={list().filter(a => a.Type === "car").length > 0 ? trans("new:delivery") : null}
                                        selectValue={checkoutType}
                                        onChangeValue={(value) => {
                                            setCheckoutType(3);
                                        }}
                                    />

                                    <div className="nice-flex nice-flex-row nice-flex-space-between w-100 mb-10">

                                        <div className="nice-flex flex-col ">
                                            <h4 className="text-24 text-bold">{trans("new:طلب تواصل")}</h4>
                                            <span className="text-20 text-gray max-w-80 mx-1">{trans(" اطلب مواصفات سيارتك ليتم توفيرها ")}</span>
                                        </div>

                                        <div className="nice-flex flex-col ">
                                            <span className="text-24 font-bold text-primary">{"مجانآ"}</span>

                                            <span className="text-14 text-gray mx-1">{trans(" ")}</span>
                                        </div>
                                    </div>

                                </label>
                            </div>

                            <div className=" grid nice-align-item-center grid-cols-12 gap-5 justify-items-auto p-10   my-10 ">

                                <div className="flex flex-row border-box-periodic-checkInput col-span-12 md:col-span-8 sm:col-span-8 lg:col-span-8 xl:col-span-8">

                                    <CheckInput
                                        className="text-primary cursor-pointer"
                                        checked={checkoutAgreemnt}
                                        oncheng={async (target) => {
                                            setChecAgreemnt(!checkoutAgreemnt);

                                        }}
                                        Name={"check-agremnt"}
                                    >
                                        <span className='text-20 nice-pointer text-inline-block'>{trans("info: لقد قرات الشروط والاحكام ")}
                                        </span>
                                    </CheckInput>

                                    <a href={`/${lang}/websitePolicy`} target="_blank" className='text-20 nice-pointer mx-5 text-info text-inline-block'>{trans("info:   وسياسة الموقع واوافق ")}</a>

                                </div>

                                <div className="col-span-12 md:col-span-4 sm:col-span-4 lg:col-span-4 xl:col-span-4">
                                    <div className={"btn nice-flex-row  py-5 text-white text-24 " + ((checkoutType != null && checkoutAgreemnt == true) ? " bg-primary " : " bg-gray ")}
                                        onClick={() => {
                                            try {


                                                if (vehicle && checkoutType && checkoutAgreemnt == true) {

                                                    if (checkoutType == 1) {
                                                        history.push({
                                                            pathname: `/car/checkoutCash/${sn}/${slug}`,
                                                            search: ""
                                                        });
                                                    } else if (checkoutType == 2) {

                                                        history.push({
                                                            pathname: `/car/checkoutFinance/${sn}/${slug}`,
                                                            search: ""
                                                        });
                                                    } else if (checkoutType == 3) {

                                                        history.push(`/order-car?vehicle=${vehicle?.productID}&model=${vehicle.year}&type=${vehicle.type}&color=${selectedColor?.ColorID}`);

                                                    }

                                                }

                                            } catch (error) {
                                                //////console.log(error);
                                            }
                                        }}




                                    >{trans("info:متابعة")} </div>
                                </div>
                            </div>


                        </div>




                        {/* //------------------------ */}

                        <div id="parent-footer"
                            className="eparts-page-footer bg-theme-up show-xs"
                            style={{ height: scrollSnackUp.height }}
                            onTouchEnd={(event) => {

                                if (!event?.target?.id.includes("footer-actions")) {
                                    const currentValue = { ...scrollSnackUp };
                                    currentValue.show = !currentValue.show;
                                    if (currentValue.show) {
                                        currentValue.height = '113rem';
                                    } else {
                                        currentValue.height = '16rem';
                                    }
                                    setScrollSnackUp(currentValue);

                                }

                            }}>

                            {

                                (


                                    ((selectedColor?.buyOnline == true)) ? (
                                        <>


                                            <div className="flex flex-row justify-between">



                                                <div className="" >

                                                    <div className="carOverview-page-body-desc">
                                                        <div className="carOverview-page-body-name">
                                                            <div className="carOverview-page-body-type">
                                                                <div className="carOverview-page-body-type-content nice-gap-1rem">
                                                                    <h2 className="text-20 text-bold">{propsData?.vehicle?.brand && propsData?.vehicle?.brand}</h2>
                                                                    {/* <img src={vehicle?.brandIconPath && vehicle?.brandIconPath} /> */}
                                                                    <img className="" src={`/assets/icons/brands/${(isToyota ? "toyota" : "") + (isLexus ? "lexus" : "")}.webp`} />
                                                                </div>
                                                            </div>

                                                            <h3 className="text-30 text-bold">{vehicle?.name && vehicle?.name}</h3>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="flex flex-col justify-center">
                                                    {<>
                                                        <div className="flex flex-col gap-1">
                                                            <div className="flex flex-row gap-1">
                                                                <span className="text-30 font-bold text-primary">{(selectedColor?.NetSalePrice) && <Price price={parseFloat(selectedColor?.NetSalePrice)} />}</span>

                                                            </div>

                                                            {
                                                                finance?.totalPrice ?
                                                                    <span className="text-20 text-gray">
                                                                        {"("}
                                                                        {(finance?.price) && <Price displayCurrencyLiter={false}
                                                                            price={parseFloat(finance?.price.toFixed(2))} />}

                                                                        {" " + trans("new:monthly") + ")"}

                                                                    </span>
                                                                    : null
                                                            }
                                                        </div>
                                                    </>}
                                                </div>


                                            </div >
                                            {
                                              
                                                <div className="flex flex-col nice-gap-1rem py-5">
                                                    <>

                                                        <div className="carOverview-page-body-desc">

                                                            <div className="nice-container carOverview-page-body-number flex flex-row nice-gap-1rem my-10">
                                                                <span className="text-20 text-gray"> {trans("new:item_no")}</span>
                                                                <span className="text-20 text-gray">{vehicle?.productNo ? vehicle?.productNo : "########"}</span>
                                                            </div>
                                                        </div>

                                                        <div className="carOverview-page-body-img">
                                                            <img className="carOverview-page-header-img"
                                                                // src={"data:image;base64," + image.DATA}
                                                                src={config.FilePathURL + filterCarImages[0]?.Image_URL}
                                                            />

                                                        </div>
                                                    </>

                                                    {
                                                        finance?.totalPrice ?
                                                            <>
                                                                <p className="text-center text-24 mt-10">
                                                                    {trans("new:installment_price")}
                                                                </p>
                                                                <div className="nice-ruler-base bg-secondary"></div>

                                                                <div className="nice-flex nice-flex-column nice-gap-1rem">

                                                                    <div className="nice-flex nice-flex-row nice-flex-space-between my-4">
                                                                        <h3 className="text-gray-dark text-16">{trans("finance:period")}</h3>
                                                                        <p className={"text-primary-dark text-16"}>{finance?.period}</p>
                                                                    </div>
                                                                    <div className="nice-flex nice-flex-row nice-flex-space-between my-4">
                                                                        <h3 className="text-gray-dark text-16">{trans("finance:management_fees")}</h3>
                                                                        <p className={"text-primary-dark text-16"}><Price price={finance?.mangFees} showZero={true} /></p>
                                                                    </div>
                                                                    <div className="nice-flex nice-flex-row nice-flex-space-between my-4">
                                                                        <h3 className="text-gray-dark text-16">{trans("finance:monthPayment")}</h3>
                                                                        <p className={"text-primary-dark text-16"}><Price price={finance?.price} showZero={true} /></p>
                                                                    </div>
                                                                    <div className="nice-flex nice-flex-row nice-flex-space-between my-4">
                                                                        <h3 className="text-gray-dark text-16">{trans("finance:advance_payment")}</h3>
                                                                        <p className={"text-primary-dark text-16"}> <Price price={finance?.first} showZero={true} /></p>
                                                                    </div>
                                                                    <div className="nice-flex nice-flex-row nice-flex-space-between my-4">
                                                                        <h3 className="text-gray-dark text-16">{trans("finance:lastPayment")}</h3>
                                                                        <p className={"text-primary-dark text-16"}> <Price price={finance?.last} showZero={true} /></p>
                                                                    </div>

                                                                    <div class="mx-2 my-10 nice-flex nice-flex-row">
                                                                        <img src="/assets/icons/info.svg" class="mx-3 text-inline-block nice-max-icon" />
                                                                        <p class="text-12 text-bold text-gray text-inline-block">{trans("finance:This_calculation_is_estimate")} </p>
                                                                    </div>
                                                                </div>

                                                                <div className="nice-ruler-base bg-secondary"></div>

                                                            </>
                                                            : null

                                                    }


                                                </div>
                                            }
                                        </>
                                    )

                                        :
                                        (

                                            <div className="flex flex-row justify-between">
                                                <div className="flex flex-col justify-center">


                                                </div>


                                                <div id="footer-actions-buy" className="flex flex-col btn-notfound bg-gray-light p-5">
                                                    <div id="footer-actions-buy" className="flex flex-row items-center justify-center btn-notfound-available">
                                                        <p id="footer-actions-buy" className="text-center  text-24  text-danger ">
                                                            {trans("new:not_available")}
                                                        </p>
                                                    </div>

                                                    <div className="border-l-5 my-5 mx-5 text-gray"></div>


                                                    <div id="footer-actions-buy" className="flex flex-row basis-2/4 items-center btn-notfound-alert"

                                                        onClick={() => {

                                                        }}
                                                    >
                                                        <p id="footer-actions-buy" className="text-center text-alert text-24  text-line-clamp   ">
                                                            {trans("new:alert_when_available")}
                                                        </p>
                                                        <img className="  " src="/assets/icons/alert-icon.svg" />
                                                    </div>
                                                </div>


                                            </div>
                                        )




                                )

                            }


                        </div >

                        {/* //----------------- */}
                    </div >

                    <div className=" hide-xs col-span-0  md:col-span-4 sm:col-span-4 lg:col-span-5 xl:col-span-5 ">
                        <div className="carOverview-page-sub pt-20 nice-shadow-2 border-radius-sm bg-white">
                            <div className=" py-10 px-5 ">
                                <div className="py-5">
                                    <h3 className="text-30 text-bold ">{"تفاصيل الطلب"}</h3>
                                </div>

                                <div class="ruler-2 mb-5"></div>
                                <div className="carOverview-page-body">
                                    {

                                        (
                                            propsData?.vehicle ?
                                                <>

                                                    <div className="carOverview-page-body-desc">
                                                        <div className="carOverview-page-body-name">
                                                            <div className="carOverview-page-body-type">
                                                                <div className="carOverview-page-body-type-content nice-gap-1rem">
                                                                    <h2 className="text-20 text-bold">{propsData?.vehicle?.brand && propsData?.vehicle?.brand}</h2>
                                                                    {/* <img src={vehicle?.brandIconPath && vehicle?.brandIconPath} /> */}
                                                                    <img className="" src={`/assets/icons/brands/${(isToyota ? "toyota" : "") + (isLexus ? "lexus" : "")}.webp`} />
                                                                </div>
                                                            </div>

                                                            <h3 className="text-20 text-gray">{vehicle?.name && vehicle?.name}</h3>
                                                        </div>
                                                        <div className="nice-container carOverview-page-body-number flex flex-row nice-gap-1rem my-10">
                                                            <span className="text-20 text-gray"> {trans("new:item_no")}</span>
                                                            <span className="text-20 text-gray">{vehicle?.productNo ? vehicle?.productNo : "########"}</span>
                                                        </div>
                                                    </div>

                                                    <div className="carOverview-page-body-img">
                                                        <img className="carOverview-page-header-img"
                                                            // src={"data:image;base64," + image.DATA}
                                                            src={config.FilePathURL + (filterCarImages && filterCarImages.length > 0 ? filterCarImages[0]?.Image_URL : "")}
                                                        />

                                                    </div>
                                                </>
                                                :
                                                <div className="container search-line my-40"><h4 className="text-gray text-20 py-2">{trans("new:no_data")}</h4></div>
                                        )


                                    }
                                </div>
                                <div class="ruler-2 mb-5"></div>
                                <div className="nice-container flex flex-col gap-3 justify-between">
                                    <div className="flex flex-col justify-center">
                                        {<>

                                            <div className="nice-flex nice-flex-row nice-flex-space-between mb-10">
                                                <h4 className="text-20">{trans("new:cash_price")}&nbsp;<span className="text-14 text-gray mx-1">{trans("new:total_include_tax")}</span> </h4>
                                                <span className="text-20 font-bold text-primary">{(selectedColor?.NetSalePrice) && <Price price={parseFloat(selectedColor?.NetSalePrice)} />}</span>
                                            </div>

                                            <div className="nice-flex nice-flex-row nice-flex-space-between mb-10">
                                                <h4 className="text-20">{trans("يبدأ القسط من")} </h4>
                                                <span className="text-20 font-bold text-primary">
                                                    {(finance?.price) && <Price price={parseFloat(finance?.price.toFixed(2))} />}{" " + trans("new:monthly")}</span>
                                            </div>




                                        </>}
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>


            </section >

        </>
    );
}