
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SectionHeading from "../../../components/sectionHeading";
import { FormButton } from "../../../components/_input/_button";
import { DropDownInput } from "../../../components/_input/_dropdown";
import { DateInput } from "../../../components/_input/_dateInput";
import moment from 'moment';
import { Price } from "../../../components/__simple/_price";
import { useContext } from "react";
import OwnOrderContext from "./ownOrderContext";
import axios from "axios";
import config from "../../../configuration/config.json";
import { getData, User_Data_Key } from "../../../utils/localStorageManager";
import { toast } from "react-toastify";
export const OwnOrderHistory = ({
    historyTitle = '',
    historySubTitle = '',
    statusOptions = [],
    values = [],
    onClick
}) => {
    const { t: trans,i18n } = useTranslation(["ownOrder", "new"]);
    let lang = (i18n.language || 'ar');
    const [isActive, setIsActive] = useState(false);
    const [filterHistory, setFilterHistory] = useState({
        filterStatusOption: '',
        filterDate: ''
    });
    const [isLoading, setIsLoading] = useState(false);
    //-----------------------------------------------------
    const [activeItem, setActiveItem] = useState({
        key: null,
        status: false
    });
    //------------------------------------------------------
    const context = useContext(OwnOrderContext);
    //-----------------------------------------------------
    const handleOnFilter = () => {
        try {
            setIsLoading(true);
            if (onClick) {
                onClick(filterHistory);
            }
        } catch (error) {
            console.log('error fired once click on filter btn', error);
        }
        setIsLoading(false);
    };
    const handleOnItemChangeStatus = (id, status, CartID, Description, Total, CreatedAt, Delivery, TStatus, deliveryType) => {
        const val = { ...activeItem };
        val.key = id;
        val.status = status;


        setActiveItem(val);
        if (window.innerWidth < 577) {
            context.handleChangeParentState({ showDetailsBox: status, itemActive: status, showHistoryBox: !status, cartID: id, CID: CartID, Description: Description, Total: Total, CreatedAt: CreatedAt, Delivery: Delivery, TStatus: TStatus, deliveryMethod: deliveryType });
        } else {
            context.handleChangeParentState({ showDetailsBox: status, itemActive: status, showHistoryBox: true, cartID: id, CID: CartID, Description: Description, Total: Total, CreatedAt: CreatedAt, Delivery: Delivery, TStatus: TStatus, deliveryMethod: deliveryType });
        }
    };

    useEffect(() => {


        if (values?.length > 0) {

            const item = values[0];
            const status= true;
            handleOnItemChangeStatus(item.CartID, status, item.CartID, lang === "ar" ? item.DescriptionAr : item.DescriptionEn, item.Total, item.CreatedAt, item.SiteDilveryID ? (lang === "ar" ? item.DilveryAr : item.DilveryEn) : (lang === "ar" ? item.siteAr : item.siteEn), lang === "ar" ? item.statusAr : item.statusAr, (item.SiteDilveryID ? trans('new:by_site') : trans('new:by_branch')));

        }

    }, [values])
    return (
        <div style={{ display: context.parentBox.showHistoryBox ? 'block' : 'none' }} className={`own-order-history nice-flex-center px-6 border-gray border-gray-${lang == 'ar' ? 'left' : 'right'} `}>
            <SectionHeading
                hasRuler={false}
                title={historyTitle}
                infoText={historySubTitle}
            >
                <div className="nice-container">
                    <div className="nice-row">
                        <div className="nice-col-sm-6 ">
                            <DropDownInput
                                title={trans("ownOrder:status")}
                                options={[...statusOptions.map((p) => {
                                    return { text: (lang === 'ar' ? p.DescriptionAr : p.DescriptionEn), value: p.statusID.toString() };
                                })]}
                                onSelect={(option) => {
                                    const val = { ...filterHistory };
                                    val.filterStatusOption = option;
                                    setFilterHistory(val);
                                }}
                            />
                        </div>
                        <div className="nice-col-sm-6 ">
                            <DateInput
                                title={trans("ownOrder:date")}
                                onSelect={(e) => {
                                    const val = { ...filterHistory };
                                    val.filterDate = e.target.value;
                                    setFilterHistory(val);
                                }} />
                        </div>
                        <div className="nice-col-md-4 mb-5">
                            <FormButton
                                isLoading={isLoading}
                                isPrimary={true} classes={"px-5 py-2"}
                                name={trans("ownOrder:history_filter")}
                                onClick={handleOnFilter}
                            />
                        </div>
                    </div>
                    <div className="amazing-ruller mb-5"></div>
                </div>

            </SectionHeading>
            {
                values && values.map((item, index) => {
                    return <OwnHistoryItem
                        key={index}
                        id={item.CartID}
                        isActive={item.CartID == activeItem.key ? activeItem.status : false}
                        total={item.Total}
                        orderCode={lang === "ar" ? item.DescriptionAr : item.DescriptionEn}
                        orderStatusText={lang === "ar" ? item.statusAr : item.statusAr}
                        orderDate={item.CreatedAt}
                        orderStatusCode={1}
                        maxVisualElements={5}
                        url_payment={item.url_payment}
                        locationText={item.SiteDilveryID ? (lang === "ar" ? item.DilveryAr : item.DilveryEn) : (lang === "ar" ? item.siteAr : item.siteEn)}
                        onItemClick={(id, status) => {
                            handleOnItemChangeStatus(id, status, item.CartID, lang === "ar" ? item.DescriptionAr : item.DescriptionEn, item.Total, item.CreatedAt, item.SiteDilveryID ? (lang === "ar" ? item.DilveryAr : item.DilveryEn) : (lang === "ar" ? item.siteAr : item.siteEn), lang === "ar" ? item.statusAr : item.statusAr, (item.SiteDilveryID ? trans('new:by_site') : trans('new:by_branch')));
                        }}
                    />
                })
            }
        </div>
    );
}
// component that is used to show the history item
export const OwnHistoryItem = (
    { key,
        id,
        orderCode = '#',
        orderDate,
        orderStatusText = '',
        orderStatusCode,
        total = 0.0,
        locationText = '',
        visualElements = 4,
        maxVisualElements = 4,
        isActive = false,
        onItemClick,
        url_payment
    }
) => {
    const { t: trans,i18n } = useTranslation(["ownOrder"]);
    const lang = (i18n.language || 'ar');
    const [file, setFile] = useState(null);
    const [step, setSteps] = useState(0);
    const handleOnItemClick = () => {
        if (onItemClick) {
            const activeStatus = !isActive;
            onItemClick(id, activeStatus);
        }
    };
    const [CartsImages, setCartImages] = useState([]);
  
 
    const fileRef = useRef();
    const handleOnCompleteBuy = (event) => {
        window.open(url_payment)

    };
    const handleOnUploadFile = (event) => {
        event.stopPropagation();
        if (step === 0) {
            fileRef.current.click();
            if (file == null) {
                setSteps(1);
            }
        } else if (step === 1) {
            if (file) {
                if (window.confirm(trans("new:send_transfer") + " " + "?")) {
                    try {
                        const userData = JSON.parse(getData(User_Data_Key, true));
                        const userID = userData.id.toString().split('_')[1];
                        axios.post(
                            config.baseURL + 'add',
                            {
                                "Object": "SiteCartTransforms",
                                "values": {
                                    "UserID": Number(userID),
                                    "CartID": Number(id),
                                    "TransformImage": file
                                }
                            }
                        ).then(response => {
                            toast.success(trans("global:success_operation"));
                        }).catch(error => {
                            console.log(error);
                        });
                    } catch (err) {
                        console.log(err);
                    }
                }
                setFile(null);
            }
            setSteps(0);
        }
    };
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    const handleChangeImage = async (event) => {
        const files = event.target.files;
        if (files.length > 0) {
            const fileBase64 = await toBase64(files[0]);
            const finalFile = fileBase64.substr(fileBase64.indexOf(',') + 1, fileBase64.length);
            setFile(finalFile);
            return;
        }
        setFile(null);
    };
    return (
        <div
            key={key}
            className={`own-order-history-item p-4 nice-pointer mb-3 mx-4 ${isActive ? 'item-active' : ''}`}
            onClick={handleOnItemClick}>
            <div className=" nice-flex nice-flex-row nice-flex-speace-around nice-flex-baseline">
                <div className="nice-pointer own-order-history-item-editable  nice-flex nice-flex-row" >
                    <div onClick={handleOnCompleteBuy} className="own-order-history-item-editable-btn text-white nice-flex nice-flex-center bg-black">
                        {trans("info:complete_buy")}
                    </div>
                    <div onClick={handleOnUploadFile} className=" nice-pointer own-order-history-item-editable-btn text-white nice-flex nice-flex-center bg-primary">
                        {step == 0 ? trans("info:select_file") : trans("info:upload_file")}
                        <input type="file" onClick={async (event) => {
                            await handleChangeImage(event);
                        }} accept="image/*" style={{ display: 'none' }} ref={fileRef} />
                    </div>
                </div>
                <div className="text-16 p-2 text-bold own-order-history-item-header-status bg-info box-circle">
                    {orderStatusText}
                </div>
            </div>
            <div className="own-order-history-item-header ">
                <div className="item-active-indicator"
                    style={lang == 'ar' ? (isActive ? { left: '-2.5rem', opacity: 1 } : null)
                        : lang == 'en' ? (isActive ? { transform: 'rotate(-180deg)', right: '-2.5rem', opacity: 1, } : null) : null
                    }>
                    <img src="/assets/icons/arrow-right.svg" />
                </div>
                <div className="flex nice-flex-column">
                    <h1 className="text-size-18r text-bold">{orderCode}</h1>
                    <div className="text-14 text-gray">{(orderDate != null && moment(orderDate).isValid()) ? moment(orderDate).format('YYYY-MM-DD') : ''}</div>
                </div>

            </div>
            <div className="own-order-history-item-products flex nice-flex-row my-2">
                {
                    CartsImages.map((item, index) => {
                        if (index < maxVisualElements)
                            return (
                                <div key={index} className="own-order-history-item-products-item flex nice-flex-center">
                                    <img src={item.ProductImage ? "data:image/png;base64," + item.ProductImage : '/assets/images/eparts/default.png'} alt={id} />
                                </div>)
                    })
                }
                {
                    (visualElements.length > maxVisualElements) &&
                    <div className="own-order-history-item-products-item flex nice-flex-center">
                        <div className="text-bold text-16">2+</div>
                    </div>
                }
            </div>
            <div className="own-order-history-item-summary flex nice-flex-row">
                <article className="flex nice-flex-column text-center">
                    <div className="text-14 mb-2">
                        {trans("ownOrder:total_amount")}
                    </div>
                    <div className="text-16 text-bold">
                        <Price price={total} />
                    </div>
                </article>
                <div className="nice-vertical-border mx-2"></div>
                <article className="flex nice-flex-column ">
                    <div className="text-14 mb-2">
                        {trans("ownOrder:deleviry_place")}
                    </div>
                    <div className="text-16 text-bold">
                        {locationText}
                    </div>
                </article>
            </div>
        </div>

    );
}