import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import config from "../configuration/config.json";

function SimpleCardCar(
    {
        brandID = "1",
        titleAr = "Title 1", title2Ar = "Title 2", subTitleListAr = [],
        titleEn = "Title 1", title2En = "Title 2", subTitleListEn = [],
        imageFull = "/assets/images/default.png"
    }) {

    const {t: trans} = useTranslation(["common", "eparts"]);
    const [lang, setLang] = useState(localStorage.getItem('i18nextLng'));

    return (
        <div className="car-card border-radius language-ar">
            <div className="car-card-top">
                <div className={`car-card-type ${brandID == "1" ? " " : " lexus"} `}>
                    <div className={`car-card-type-brand ${brandID == "1" ? " " : " bg-lexus"} `}>
                        <img
                            className=""
                            src={`/assets/icons/brands/${brandID == '1' ? 'toyota.webp' : 'lexus.webp'}`}
                            alt="toyota"
                            onError={e => e.target.style.display = 'none'}
                            loading={"lazy"}
                        />
                    </div>
                </div>

                <a className="nice-pointer">
                    <div className="car-img cursor-default">
                        <img
                            src={imageFull == null ? "/assets/images/default.png" : config.mainURL + imageFull }
                            alt={lang.toLowerCase() == 'ar' ? titleAr ?? "" : titleEn ?? ""}
                            className="object-contain object-full p-6"
                            onError={(e) => {
                                if (!imageFull || imageFull == '' ) {
                                    e.target.style.display = 'none';
                                }
                            }}
                            loading={"lazy"}
                        />
                        <div className="car-img-bg">
                            <img
                                className="object-full"
                                src={`/assets/icons/brands/${brandID == "1" ? "toyota-light.webp" : "lexus.webp"}`}
                                alt="toyota"
                                onError={e => e.target.style.display = 'none'}
                                loading={"lazy"}
                            />
                        </div>
                    </div>
                </a>
            </div>

            <div className="car-card-info border-radius box-shadow">
                <div className="car-card-info-name">
                    <h3
                        className=" text-3xl sm:text-4xl md:text-4xl lg:text-2xl  font-bold text-line-clamp text-line-clamp-2 h-24 sm:h-28 md:h-36 lg:h-24"
                        title={lang.toLowerCase() == 'ar' ? titleAr ?? "" : titleEn ?? ""}>
                        {lang.toLowerCase() == 'ar' ? titleAr ?? "" : titleEn ?? ""}
                    </h3>
                </div>

                <div className="car-options inline-block max-w-full overflow-hidden text-ellipsis">
                    <div className="flex flex-col nice-align-item-start  w-full h-20">

                        {
                            lang.toLowerCase() == 'ar' && (
                                subTitleListAr.length > 0 && (
                                    subTitleListAr.map((titleValue, titleIndex) => {
                                        return (
                                            titleValue && (
                                                <div
                                                    className={`options-price flex-row nice-flex-center text-3xl sm:text-4xl md:text-4xl lg:text-2xl  h-12 sm:h-16  md:h-20 lg:h-12 ${(Number(subTitleListAr.length) - 1) == titleIndex ? "underline font-bold" : ""}`}
                                                    key={titleIndex}
                                                >
                                                    {titleValue ?? ""}
                                                </div>
                                            )
                                        )
                                    })
                                )
                            )
                        }

                        {
                            lang.toLowerCase() == 'en' && (
                                subTitleListEn.length > 0 && (
                                    subTitleListEn.map((titleValue, titleIndex) => {
                                        return (
                                            titleValue && (
                                                <div
                                                    className={`options-price flex-row nice-flex-center text-3xl sm:text-4xl md:text-4xl lg:text-2xl  h-12 sm:h-16  md:h-20 lg:h-12 ${(Number(subTitleListAr.length) - 1) == titleIndex ? "underline font-bold" : ""}`}
                                                    key={titleIndex}
                                                >
                                                    {titleValue ?? ""}
                                                </div>
                                            )
                                        )
                                    })
                                )
                            )
                        }

                    </div>
                </div>
            </div>
        </div>
    );
}

export default SimpleCardCar;